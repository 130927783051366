.service {
    .kv_main {
        h1 {
            @include sp {
                font-size: 2.5rem;
            }
        }
    }
    &-list {
        margin-top: 40px;
        .lead {
            margin-bottom: 40px;
            text-align: center;
            font-size: 1.8rem;
            line-height: 38px;
            @include sp {
                margin-bottom: 15px;
                line-height: 28px;
            }
            .text_color {
                color: #ff4141;
                font-weight: 700;
                background-color: #fff9c2;
            }
            @include sp {
                text-align: left;
                font-size: 1.6rem;
            }
        }
        .nav_06 {
            &__text {
                padding: 9px 10px;
                font-size: 1.8rem;
                @include sp {
                    padding: 5px 10px;
                    font-size: 1.25rem;
                }
            }
        }
    }
    .c-tt01 {
        margin-bottom: 50px;
        @include sp {
            margin-bottom: 30px;
        }
    }
    &_sec01,
    &_sec02,
    &_sec03,
    &_sec04,
    &_sec06,
    &_sec07,
    &_sec08,
    &_sec09,
    &_sec10,
    &_sec11 {
        margin-top: 100px;
        @include sp {
            margin-top: 40px;
        }
        p {
            margin-top: 29px;
            text-align: left;
            line-height: 38px;
            font-size: 1.8rem;
            @include sp {
                font-size: 1.5rem;
                line-height: 23px;
            }
        }
        .imgtext {
            display: flex;
            justify-content: space-between;
            align-items: start;
            @include sp {
                display: block;
                text-align: center;
            }
            img {
                width: 520px;
                @include sp {
                    width: 100%;
                }
            }
            p {
                margin-top: 0;
                width: calc(100% - 520px - 40px);
                line-height: 38px;
                @include sp {
                    margin-top: 30px;
                    width: auto;
                    font-size: 1.6rem;
                    line-height: 24px;
                }
            }
        }
    }
    &_sec01 {
        margin-top: 80px;
        @include sp {
            margin-top: 40px;
        }
    }
    &_sec02,
    &_sec04,
    &_sec06,
    &_sec07,
    &_sec08 {
        &__box {
            margin: 40px auto;
            padding-top: 70px;
            padding-left: 55px;
            padding-right: 55px;
            padding-bottom: 29px;
            position: relative;
            width: 960px;
            background-image: url(images/ihinseiri/s2_img01.png);
            background-size: 100% 100%;
            @include sp {
                margin: 29px auto;
                padding: 15px 15px 8px;
                width: 100%;
                background-image: url(images/ihinseiri/s2_img01_sp.png);
            }
            &__tt {
                margin-bottom: 15px;
                font-size: 2.4rem;
                font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", sans-serif;
                color: #30b2f1;
                font-weight: 700;
                @include sp {
                    font-size: 1.8rem;
                    line-height: 28px;
                    margin-bottom: 10px;
                }
            }
            &__text {
                font-size: 3.4rem;
                color: rgb(34, 34, 34);
                font-weight: 700;
                font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", sans-serif;
                margin-bottom: 35px;
                @include sp {
                    font-size: 1.6rem;
                    margin-bottom: 28px;
                }
            }
        }
        .list_01 {
            padding: 0;
            background: transparent;
            border: 0;
            max-width: 100%;
            font-family: 游明朝, "Yu Mincho", 游明朝体, YuMincho, serif;
            color: rgb(2, 34, 34);
            @include sp {
                padding-left: 0px;
                font-size: 1.6rem;
                margin-bottom: 9px;
            }
            &::before {
                content: '';
                border-left: 46px solid transparent;
                border-right: 46px solid transparent;
                border-top: 37px solid #30b2f1;
                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%);
                @include sp {
                    bottom: -35px;
                    border-left: 23px solid transparent;
                    border-right: 23px solid transparent;
                    border-top: 18px solid #30b2f1;
                }
            }
            li {
                margin-bottom: 15px;
                padding-left: 30px;
                position: relative;
                font-size: 2.2rem;
                @include sp {
                    padding-left: 20px;
                    font-size: 1.6rem;
                    margin-bottom: 9px;
                }
            }
        }
        &__bot {
            &__tt {
                margin-top: 60px;
                text-align: center;
                line-height: 50px;
                font-size: 3.0rem;
                @include sp {
                    margin-top: 40px;
                    font-size: 1.8rem;
                    line-height: 25px;
                }
                p {
                    margin-top: 10px;
                    text-align: center;
                    font-weight: normal;
                    @include sp {
                        text-align: left;
                    }
                }
                span {
                    font-size: 3.0rem;
                    color: #FF3E32;
                    background-color: #FFF9C2;
                    @include sp {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
    &_sec02,
    &_sec08,
    &_sec09,
    &_sec10 {
        .c-tt05 {
            margin-top: 70px;
            @include sp {
                margin-top: 50px;
                margin-bottom: 10px;
                font-size: 1.8rem;
            }
        }
        .nav_02 {
            li {
                @include sp {
                    margin-top: 30px;
                }
                &:first-child {
                    margin-bottom: 0;
                }
                &:last-child {
                    border-bottom: none;
                }
            }
            &__top {
                display: block;
            }
            &__right {
                padding-left: 0;
                width: 100%;
            }
            &__text02 {
                margin-top: 0;
                font-size: 1.5rem;
                @include sp {
                    font-size: 1.4rem;
                }
            }
            &__text03 {
                margin-top: 0;
                line-height: 36px;
                @include sp {
                    margin-top: 0;
                    font-size: 1.5rem;
                    line-height: 23px;
                }
            }
        }
    }
    &_sec04 {
        .imgtext {
            p {
                padding-top: 0;
                padding-bottom: 0;
                span {
                    font-weight: bold;
                    color: #FF3E32;
                    background-color: #FFF9C2;
                }
            }
        }
    }
    &_sec05 {
        margin-top: 100px;
        padding: 40px 0;
        background: #F5EFDF;
        @include sp {
            margin-top: 30px;
            padding: 20px 0;
        }
    }
    &_sec06 {
        .imgtext {
            align-items: center;
        }
        .c-tt07 {
            margin-top: 120px;
            margin-bottom: 35px;
            font-size: 3.6rem;
            @include sp {
                margin-top: 70px;
                margin-bottom: 15px;
                font-size: 2.0rem;
            }
        }
        dt p {
            margin-top: 0;
            line-height: 34px;
            @include sp {
                font-size: 1.6rem;
                line-height: 23px;
            }
        }
    }
    &_sec07 {
        &__bot__tt {
            font-size: 2.6rem;
            @include sp {
                text-align: left;
                font-weight: normal;
                font-size: 1.6rem;
            }
            span {
                font-size: 2.6rem;
                @include sp {
                    font-weight: bold;
                    font-size: 1.6rem;
                }
            }
        }
    }
    &_sec09 {
        .imgtext {
            align-items: center;
        }
        .nav_02 li {
            margin-top: 30px;
            width: 100%;
        }
    }
    &_sec10 {
        .nav_02 li {
            width: 100%;
        }
    }
    &_sec11 {
        padding-bottom: 100px;
    }
}
