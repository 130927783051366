.cta_01{
    &__contents {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include sp{
            flex-direction: column;
        }
    }
    &__copy {
        margin-bottom:28px;
        text-align:center;
        font-size:28px;
        font-weight:bold;
        @include sp {
            padding-top: 20px;
            font-size: 20px;
        }
    }
    &__img{
        @include sp{
            margin-right: 0;
            margin-bottom: 17px;
            text-align: center;
        }
        img{
            max-width: 100%;
            width: 90%;
        }
        a{
            display: block;
        }
    }
    &--bg{
        background-color: #F5EFDF;
        padding: 20px 10px 25px;
        @include sp{
            padding: 20px 7.5px 25px;
        }
    }
    &__item01{
        // margin-right: 30px;
        @include sp{
            margin-right: 0;
            margin-bottom: 17px;
        }
    }
    &__top{
        display: flex;
        align-items: center;
        @include sp{
            justify-content: center;
        }
        &__left{
            display: table;
            font-size: 1.6rem;
            font-family: $go;
            font-weight: bold;
            color: #fff;
            background-color:#ff6c00 ;
            text-align: center;
            width: 108px;
            line-height: 20px; 
            padding: 8px;
            border-radius: 5px;
            position: relative;
            height: fit-content;
            margin-right: 16px;
            @include sp{
                font-size:1.2rem ;
                line-height: 15px;
                width: 79px;
                padding: 5px;
            }
            &::before{
                content: '';
                border-left: 13px solid #ff6c00;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                position: absolute;
                right: -12px;
                top: 50%;
                transform: translateY(-50%);
                @include sp{
                    border-left: 14px solid #ff6c00;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                }
            }
        }
        &__right{
            font-size: 5.8rem;
            color: #ff6c00;
            font-weight: 900;
            line-height: 1;
            @include sp{
                font-size: 4.2rem;

            }
            span{
                font-size: 103px;
                font-family: $ar;
                color: #ff6c00;
                font-weight: 700;
                line-height: 70px;
                @include sp{
                    font-size: 7.5rem;
                }
            }
        }
    }
    &__bottom{
        font-size: 1.6rem;
        font-weight: 700;
        font-family: $go;
        line-height: 26px;
        color: $text;
        letter-spacing: 0.05rem;
        margin-top: 16px;
        @include sp{
            font-size: 1.4rem;
            line-height: 21px;
            margin-top: 5px;
        }
        span{
            color:#FF3434 ;
        }

    }
    &__item02{
        @include sp{
            display: none;
        }
        &__top{
            font-size: 2rem;
            font-weight: bold;
            letter-spacing: 0.05rem;
            line-height: 26px;
            color: $text;
            span{
                color: #FF2121;
                background-color: #FFDE00;
                padding-left: 10px;
                margin-right: 10px;
                padding-bottom: 1px;
                letter-spacing: 0.1rem;
            }
        }
        &__phone{
            font-size: 6rem;
            font-family: $ba;
            font-weight: bold;
            color: $text;
            line-height: 62px;
            padding-left: 77px;
            position: relative;
            display: table;
            margin: 0 auto;
            margin-top: 15px;
            img{
                position: absolute;
                left: 7px;
                top: -5px;
            }
        }
    }
}
.btn-cta{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 324px;
    border-radius: 5px;
    height: 118px;
    background-image: linear-gradient( to top, rgb(19,126,113) 0%, rgb(16,182,151) 100%);
    box-shadow: 0 5px 0 #034135;
    font-weight: 700;
    color: #fff;
    text-align: center;
    @include sp{
        width: 90%;
        height: 55px;
        box-shadow: 0 3px 0 #034135;
    }
    
    &__top{
        font-size: 1.5rem;
        margin-bottom: 10px;
        @include sp{
            font-size: 1.4rem;
            color: $blue;
            font-weight: 700;
        }
        img{
            margin-right: 10px;
        }
    }
    &__bottom{
        font-size: 2rem;
        letter-spacing: 0.2rem;
        line-height: 25px;
        padding-left: 60px;
        background-image: url('images/common/icon_email.png');
        background-repeat: no-repeat;
        background-size: 49px 37px;
        background-position: left 40px center;
        position: relative;
        @include sp{
            font-size: 1.6rem;
            background-size: 35px 28px;
            padding-left: 30px;
        }
        span{
            font-size: 1.4rem;
            display: block;
            line-height: 20px;
            @include sp{
                font-size: 1.2rem;
            }
        }
    }
}
.cta_b{
    background-color: #F5EFDF;
    padding-bottom: 35px;
    padding-top: 45px;
    @include sp{
        padding-top: 20px;
        padding-bottom: 25px;
    }
    .container{
        position: relative;
    }
    img{
        max-width: 100%;
        position: relative;
    }
    &__inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include sp{
            flex-direction: column;
        }
    }
    &__item{
        // width: 250px;
        @include sp{
            margin-bottom: 13px;
        }
        .cta_01__top__left{
            width: 89px;
        }
        .cta_01__top{
            justify-content: center;
        }
        .cta_01__top__right{
            text-shadow: 0 0px 8px #fff;
        }
        .cta_01__bottom{
            @include sp{
                text-shadow: 0 0px 8px #fff; 
                text-align: center;
            }
        }
    }
    &__text{
        font-size: 1.9rem;
        line-height: 26px;
        color: $text;
        font-weight: bold;
        margin-bottom: 18px;
        font-family: $go;
    }
    &__item02{
        width: 510px;
        position: relative;
        @include sp{
            width: 100%;
            text-align: center;
        }
        &::after{
            content: '';
            width: 256px;
            height: 258px;
            background: url('images/common/cta_b_img.png') no-repeat center;
            position: absolute;
            top: -120px;
            right: 0;
            z-index: 1;
            @include sp{
                display: none;
            }
        }
        &__top{
            font-size: 1.7rem;
            line-height: 20px;
            color: $text;
            font-weight: 700;
            text-shadow: 1.5px 0 0 #f5efdf, -1.5px 0 0 #f5efdf, 0 1.5px 0 #f5efdf, 0 -1.5px 0 #f5efdf, 1.5px 1.5px #f5efdf, -1.5px -1.5px 0 #f5efdf, 1.5px -1.5px 0 #f5efdf, -1.5px 1.5px 0 #f5efdf;
            position: relative;
            z-index: 2;
            @include sp{
                display: none;
            }
            span{
                padding: 1px 0px 4px 8px;
                letter-spacing: 0.16rem;
                font-size: 1.8rem;
                color: #fff;
                background: $text;
                padding-left: 7px;
                margin-right: 7px;
                text-shadow: none;
            }
        }
        &__phone{
            font-size: 5.3rem;
            font-family: $ba;
            font-weight: 700;
            color: $text;
            text-shadow: 4px 0 0 #F5EFDF, -4px 0 0 #F5EFDF, 0 4px 0 #F5EFDF, 0 -4px 0 #F5EFDF, 1px 2px #F5EFDF, -2px -2px 0 #F5EFDF, 2px -2px 0 #F5EFDF, -2px 2px 0 #F5EFDF;
            padding-left: 59px;
            position: relative;
            display: table;
            margin-top: 15px;
            line-height: 1;
            position: relative;
            z-index: 2;
            @include sp{
                display: none;
            }
            img{
                position: absolute;
                left: 0px;
                top: -2px;
                width: 49px;
            }
        }
        .btn-cta{
            @media (min-width: 768px){
                width: 100%;
                height: 97px;
                margin-top: 20px;
                position: relative;
                z-index: 2;
                &__bottom{
                    font-size: 2.2rem;
                    background-size: 36px 27px;
                    background-position: left 70px center;
                }
            }
            @include sp{
                margin: 0 auto;
            }
        }
        
    }
}
.cta_02{
    @include bor(2px, #DFBB5B);
    background-color: #F5EFDF;
    border-radius: 5px;
    padding: 35px 50px;
    position: relative;
    @include sp{
        padding: 20px 10px;
        padding-bottom: 165px;
        @include bor(1px, #DFBB5B);
    }
    &::before{
        content: '';
        width: 274px;
        height: 225px;
        background-image: url('images/common/cta_2_img.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 50px;
        top: -46px;
        @include sp{
            // display: none;
            width: 155px;
            height: 126px;
            top: auto;
            bottom: 20px;
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__text{
        font-size: 4.6rem;
        letter-spacing: 0.07rem;
        font-family: $go;
        color: $text;
        font-weight: 700;
        @include sp{
            font-size: 2.1rem;
            line-height: 37px;
            text-align: center;
        }
    }
    &__text02{
        font-size: 2.8rem;
        font-weight: bold;
        color: $text;
        font-family: $go;
        letter-spacing: 1px;
        padding-bottom: 20px;
        border-bottom: 1px solid #222;
        max-width: 676px;
        margin-bottom: 40px;
        line-height: 40px;
        margin-top: 5px;
        @include sp{
            font-size: 1.6rem;
            line-height: 22px;
            text-align: center;
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 15px;
            margin-top: 0;
        }
    }
    .cta_01__item02{
        display: table;
    }
    .cta_01__item02__top{
        // text-align: center;
        @include sp{
            text-align: left;
        }
        &.st01{
            font-size: 2.2rem;
            margin-left: 8px;
            span{
                font-size: 2rem;
            }
        }
        &.pd5{
            span{
                padding: 0 10px;
            }
        }
    }
    .cta_01__item02__phone{
        font-size: 6.8rem;
        margin-top: 20px;
    }
    .btn-cta{
        width: 456px;
        height: 66px;
        margin-top: 15px;
        &__bottom{
            font-size: 2.2rem;
            background-size: 36px 25px;
            background-position: left 30px center;
        }
    }
    &__flex{
        display: flex;
        justify-content: space-between;
        @include sp{
            display: none;
        }
    }
    &__tel{
        display: none;
        @include sp{
            display: block;
            max-width: 305px;
            margin: 0 auto;
        }
        
        img{
            max-width: 100%;
        }
    }
}
.cta_b.bg{
    @media(min-width: 768px){
        background-image: url('images/reason/bg.png');
        background-size: cover;
    }
}
.cta_03{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sp{
        flex-direction: column;
        justify-content: center;
        .cta_01__item01{
            margin-bottom: 11px;
        }
    }
    &__copy {
        font-size: 18px;
    }
    &__item02{
        background-color: #ff6c00;
        padding: 15px 30px;
        border-radius: 5px;
        @include sp{
            display: none;
        }
        &__top{
            font-size: 2rem;
            line-height: 1;
            color: #fff;
            font-weight: 700;
            margin-bottom: 8px;
        }
        &__phone{
            font-size: 5.3rem;
            font-family: $ba;
            font-weight: 700;
            color: #fff;
            line-height: 1;
            img{
                margin-right: 10px;
                margin-top: -7px;
            }
        }
        &__text{
            font-size: 2rem;
            color: #fff;
            margin-top: 7px;
            font-weight: 700;
        }
    }
    &__item03{
        @media(min-width: 768px){
            .btn-cta__top{
                margin-right: 6px;
                color: $text;
                font-weight: 700;
                text-align: center;
            }
            .btn-cta{
                height: 93px;
            }
        }
        @include sp{
            width: 100%;
            text-align: center;
            .btn-cta{
                margin: 0 auto;
            }
        }
    }
}

.cta_00 {
    padding-top: 20px;
    padding-bottom: 30px;
    background: $brand-color-green;
    text-align: center;
    font-weight: bold;
    @include mq(lg) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    &__copy {
        color: #fff;
        font-size: 1.6rem;
    }
    &__top {
        @include mq(lg) {
            margin-bottom: 12px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
        }
    }
    &__promotion {
        margin-bottom: 14px;
        font-size: 1.8rem;
        line-height: 1.4;
        color: #fff;
        @include mq(lg) {
            margin-right: 12px;
            margin-bottom: 0;
            font-size: 2.5rem;
            line-height: 1.8;
        }
    }
    &__free {
        display: flex;
        justify-content: center;
        align-items: center;
        &__left {
            position: relative;
            padding: 20px;
            margin-right: 10px;
            border-radius: 50%;
            color: $brand-color-green;
            background: #fff;
            li {
                font-size: 1.4rem;
                line-height: 1.5;
            }
        }
        &__right {
            font-size: 4rem;
            color: #fff;
            span {
                font-size: 12rem;
            }
        }
    }
    &__bottom {
        padding: 20px;
        border-radius: 8px;
        background: #fff;
        &__container {
            @include mq(lg) {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    &__tel {
        display: block;
        padding: 10px;
        border: 2px solid $brand-color-orange;
        border-radius: 5px;
        @include mq(lg) {
            width: 380px;
            border-radius: 60px;
        }
        &__top {
            font-size: 1.4rem;
            span {
                font-size: 1.6rem;
                color: $brand-color-orange;
            }
        }
        &__middle {
            position: relative;
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 3.3rem;
            font-family: "Arial", sans-serif;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 14px;
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
                background-image: url(images/common/cta_tel.png);
                background-size: contain;
                @include mq(lg) {
                    left: 40px;
                }
            }
        }
        &__bottom {
            display: inline-block;
            padding: 5px 30px;
            color: #fff;
            border-radius: 10px;
            background: $brand-color-orange;
        }
    }
    &__button {
        display: block;
        position: relative;
        margin-top: 16px;
        padding: 20px;
        font-size: 2.0rem;
        border-radius: 10px;
        color: #fff;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), -2px 2px 5px rgba(0, 0, 0, 0.1), 0px 3px 5px rgba(0, 0, 0, 0.1);
        @include mq(lg) {
            margin-top: 0;
            padding: 30px 20px;
            width: 30%;
            border-radius: 40px;
            font-size: 1.6rem;
        }
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            background-size: contain;
            @include mq(lg) {
                left: 30px;
            }
        }
    }
    &__mail {
        background: $brand-color-green;
        &::before {
            width: 20px;
            height: 20px;
            background-image: url(images/common/cta_mail.png);
        }
    }
    &__line {
        background: #06c755;
        &::before {
            width: 24px;
            height: 24px;
            background-image: url(images/common/cta_line.png);
        }
    }
    &__note {
        margin-top: 18px;
        font-size: 1.4rem;
        @include mq(lg) {
            margin-top: 10px;
            font-size: 1.6rem;
        }
    }
}
