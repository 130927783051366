.text_bg{
    color: #FF1A1A;
    background-color: #FFFED4;
}

.u_arrow{
	border-left: 53px solid transparent;
    border-right: 53px solid transparent;
    border-top: 42px solid #CFA944;
	display: table;
	margin: 50px auto 40px;
	@include sp{
		border-left: 26px solid transparent;
		border-right: 26px solid transparent;
		border-top: 20px solid #CFA944;
		margin: 15px auto;
	}
}
.color_black{
	color: $text!important;
}
.u_bold{
	font-weight: 700;
}
