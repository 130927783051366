@charset "UTF-8";
@mixin clear{
	*zoom: 1;
	&:after {
		content: "";
		display: block;
		clear: both;
		visibility: hidden;
	}
}

// breakpoint
@mixin sp{
	@media screen and (max-width: #{$breakpoint1}){
		@content;
	}
}

@mixin tab{
	@media screen and (min-width: 768px) and (max-width: 1200px){
		@content;
	}
}

@mixin break{
	@media screen and (max-width: 900px){
		@content;
	}
}


//border-top
//ex: @include bor-top(1px, #000);
@mixin bor-top($width, $color){
	border-top: $width solid $color;
}
//border-bottom
@mixin bor-bottom($width, $color){
	border-bottom: $width solid $color;
}
//border
@mixin bor($width, $color){
	border: $width solid $color;
}
//box-sizing
//ex: @include bor-siz;
@mixin box-siz{
	box-sizing: border-box;
}
@mixin tranX($per){
	transform: translateX($per);
}

@mixin tranY($per){
	transform: translateY($per);
}

@mixin tranfo($perX, $perY){
	transform: translate($perX,$perY);
}

@mixin ani($min,$type){
	transition: $min $type;
}


// ブレークポイント指定
$mq-breakpoints: (
    "xs": 321px,
    "sm": 576px,
    "md": 768px,
    "lg": 1024px,
    "xl": 1280px,
) !default;
@mixin mq($breakpoint: md, $rule: min) {
    $breakpoint: map-get($mq-breakpoints, $breakpoint);
    @if $rule == max {
        $breakpoint: $breakpoint - 1px;
    }
    @media screen and (#{$rule}-width: $breakpoint) {
        @content;
    }
}

// vw
@function vw($minScreenSize, $px) {
    @return calc(#{$px / $minScreenSize * 100} * 1vw);
}

// font size
@mixin fontsizeVw($minScreenSize, $px) {
    font-size: vw($minScreenSize, $px);
}

// width and height
@mixin w_size($minScreenSize, $px) {
    width: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin w_calc($minScreenSize, $px, $div, $set) {
    width: calc(($set - #{$px / $minScreenSize * 100} * 1vw) / $div);
}
@mixin h_size($minScreenSize, $px) {
    height: calc(#{$px / $minScreenSize * 100} * 1vw);
}

// padding
@mixin pt($minScreenSize, $px) {
    padding-top: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin pb($minScreenSize, $px) {
    padding-bottom: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin pr($minScreenSize, $px) {
    padding-right: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin pl($minScreenSize, $px) {
    padding-left: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin p($minScreenSize, $px) {
    padding: calc(#{$px / $minScreenSize * 100} * 1vw);
}

// margin
@mixin mt($minScreenSize, $px) {
    margin-top: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin mb($minScreenSize, $px) {
    margin-bottom: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin mr($minScreenSize, $px) {
    margin-right: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin ml($minScreenSize, $px) {
    margin-left: calc(#{$px / $minScreenSize * 100} * 1vw);
}

// position
@mixin top($minScreenSize, $px) {
    top: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin bottom($minScreenSize, $px) {
    bottom: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin right($minScreenSize, $px) {
    right: calc(#{$px / $minScreenSize * 100} * 1vw);
}
@mixin left($minScreenSize, $px) {
    left: calc(#{$px / $minScreenSize * 100} * 1vw);
}

// gap
@mixin gap($minScreenSize, $px) {
    gap: calc(#{$px / $minScreenSize * 100} * 1vw);
}
