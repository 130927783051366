// LP
.header_area {
    background: #f4ece3;
  
    .logo {
        float: left;
        max-width: 48%;
        
        img {
            max-height: 60px;
        }
    }
  
    .tel {
        float: right;
        margin-right: 6px;
        max-width: 48%;
  
        img {
            max-height: 60px;
        }
    }
  
    .header_in {
        padding-top: 10px;
        padding-bottom: 12px;
    }
}
  
.l_inner {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;

    &::after {
        content: " ";
        display: block;
        clear: both;
    }
}
  
@media screen and (max-width: 959px) {
    img {
        max-width: 100%;
    }
}
  
.l-wrapper {
    max-width: 750px;
    background-color: #fff;
    margin: 0 auto;
}

.header {
    position: relative;

    &__cta {
        position: absolute;
        top: 0;

        a:hover {
            filter: brightness(110%);
        }
    }
}
  
.cta {
    position: relative;
    margin-bottom: 40px;

    a:hover {
        filter: brightness(110%);
    }

    .cta__tel {
        a {
        position: absolute;
        top: 38%;
        height: auto;
        }
    }

    @media screen and (max-width: 959px) {
        .cta__line {
            a {
                position: absolute;
                top: 60%;
                height: auto;
            }
        }
    }

    @media screen and (min-width: 960px) {
        .cta__line {
            display: none;
        }
    }

    @media screen and (max-width: 959px) {
        .cta__mail {
            a {
                height: auto;
            }
        }
    }

    .cta__tel {
        a {
        position: relative;
        margin-top: 10px;
        margin-bottom: 10px;
        top: 0;
        }
    }

    .text {
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        width: 80%;
        margin: 3% auto;
    }

    p {
        text-align: center;
    }

    a {
        display: inline;
    }

    .cta__line-btn img,
    .cta__mail img {
        width: 95% !important;
    }
}
  
.footer_area {
    padding: 30px 0 100px;
    background: #ff6c00;
    color: #fff;
    text-align: center;
  
    .footer_nav {
        width: auto;
        margin: 0 auto;
        text-align: center;
  
      a {
        color: #fff;
      }
    }
  
    .footer_info {
        width: auto;
        margin: 20px auto;
        text-align: center;
    }
}
  
.floating-banner_sp {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
  
    a:first-child {
        width: 21%;
    }
  
    a:last-child {
        width: 80%;
    }
}
  
.fade_off {
    opacity: 0;
    transition: all 1s;
}
  
.fade_on {
    opacity: 1;
}
  
  /* パソコンで見たときは"pc"のclassがついた画像が表示される */
.pc {
    display: block !important;
}
  
.sp {
    display: none !important;
}
  
/* スマートフォンで見たときは"sp"のclassがついた画像が表示される */
@media only screen and (max-width: 750px) {
    .pc {
      display: none !important;
    }
  
    .sp {
      display: block !important;
    }
}
  
.fee {
    padding: 15px 0;
    background: #f3f3f3;
}
  
.flow {
    padding-top: 3vw;
    margin-bottom: -4.666666666666667vw;
}
  
.faq_accordion {
    .faq_lst {
      padding-bottom: 10px;
  
        .question {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 0;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.425;
            padding: 10px 30px 7px 0;
            position: relative;
            background-color: #fff;
            cursor: pointer;
  
            &::before {
                content: "";
                position: absolute;
                right: 10px;
                top: calc(50% - 5px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 13px 7.5px 0 7.5px;
                border-color: #fb5e29 transparent transparent transparent;
                transition: 0.2s;
            }
  
            .question_list {
                font-size: 1.45em;
                color: #df0d1b;
                font-weight: bold;
                font-style: italic;
                display: inline-block;
                width: 11%;
                min-width: 50px;
                vertical-align: bottom;
                margin-left: 10px;
                text-align: center;
            }
  
            &.active::before,
            .voice .question.active span::before {
                transform: rotate(180deg);
                transition: 0.2s;
            }
        }
  
        .answer {
            font-size: 18px;
            letter-spacing: 0.5px;
            margin: 10px 6% 10px 11%;
            line-height: 1.425;
            padding: 10px;
            background: #fdfdfd;
            border-radius: 10px;
            display: none;
        }
    }
  
    .txt_bot_yellow {
        background: url(https://ouchiseirishi.com/wp-content/uploads/2022/11/ihs2211-txt_bot_yellow.jpg) repeat-x left bottom/1px 6px;
    }
  
    .faq_block {
        background: url(https://ouchiseirishi.com/wp-content/uploads/2022/11/ihs2211-bg_pager_yellow.png) repeat;
    }
}
  
.voice {
    padding-bottom: 17px;
  
    .txt_bot_yellow {
        cursor: pointer;
        position: relative;
        font-size: 16px;
        color: #fd8a25;
        padding: 10px;
        display: inline-flex;
        text-align: center;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        border: 3px solid #fd8a25;
        border-radius: 30px;
        width: 89%;
    }
  
    .answer {
        padding: 20px;
        line-height: 1.6;
        font-size: 16px;
        display: none;
    }
  
    .qa_txt {
        text-align: center;
    }
  
    .content {
        margin: 4px 8px 13px 7px;
        background: #ffffff;
        box-shadow: 0px 0px 2px 2px #dad4d4;
    
        img {
            width: 100%;
        }
    }
  
    .question {
        padding: 12px 0 10px 0;
  
        span {
            position: relative;
    
            &::before {
            content: "";
            position: absolute;
            right: 26px;
            top: calc(50% - 5px);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 8.5px 0 9.5px;
            border-color: #fb5e29 transparent transparent transparent;
            transition: 0.2s;
            }
        }
  
        &.active span::before {
            transform: rotate(180deg);
            transition: 0.2s;
        }
    }
}
  
.kv_inner {
    position: relative;
    width: 100%;
  
    a {
        position: absolute;
        bottom: 35%;
        right: 5%;
        width: 30%;
        &.yahoo {
            bottom: 15%;
            width: 40%;
        }
    }
  
    img {
        width: 100%;
    }
}
  