// コラム全ページ共通
.article {
  .kv_main__title.st02 {
    top: 30%;
    @include sp {
      top: 33%;
    }
  }
  @media screen and (max-width: 1380px) and (min-width: 768px) {
    .kv_main__title.st02 {
      font-size: 4.6vw;
    }
    .kv_main__title.st02 span {
      font-size: 3.3vw;
      line-height: 52px;
    }
    .kv_main__text02 {
      font-size: 1.8vw;
      line-height: 33px;
    }
  }
  .list_column {
    margin-bottom: 30px;
    @include sp {
      margin-bottom: 15px;
      margin-top: 20px;
    }
    li {
      display: flex;
      position: relative;
      border-radius: 5px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      background-color: rgb(255, 251, 242);
      border: 1px solid rgb(225, 225, 225);
      padding: 40px;
      padding-right: 50px;
      background-image: url("images/common/icon_arrow04.png");
      background-size: 16px 24px;
      background-position: right 16px center;
      background-repeat: no-repeat;
      margin-bottom: 10px;
      @include sp {
        padding: 8px;
        background-size: 8px 12px;
        background-position: right 3px center;
        padding-right: 17px;
        border-radius: 3px;
        margin-bottom: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      }
      &::before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 50px;
        background-color: rgb(225, 225, 225);
        @include sp {
          right: 17px;
        }
      }
    }
    &__img {
      width: 165px;
      height: 165px;
      overflow: hidden;
      border: 2px solid #cfa944;
      border-radius: 5px;
      @include sp {
        width: 100px;
        height: 100px;
      }
      img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__cont {
      width: calc(100% - 165px);
      padding-left: 25px;
      padding-right: 20px;
      @include sp {
        width: calc(100% - 100px);
        padding-left: 8px;
        padding-right: 10px;
      }
    }
    &__cate {
      font-size: 1.6rem;
      letter-spacing: 0.075rem;
      font-weight: 700;
      color: #fff;
      width: 115px;
      line-height: 30px;
      text-align: center;
      display: inline-block;
      position: relative;
      z-index: 3;
      @include sp {
        font-size: 1.2rem;
        line-height: 16px;
        width: 80px;
      }
      &.bg_orange {
        background-color: #e57c00;
      }
      &.bg_yellow {
        background-color: #ca9e2a;
      }
      &.bg_green {
        background-color: #688f0b;
      }
      &.bg_blue {
        background-color: #59a3db;
      }
      &.bg_purple {
        background-color: #662aca;
      }
      &.bg_black {
        background-color: #252525;
      }
    }
    &__date {
      font-size: 1.6rem;
      line-height: 30px;
      letter-spacing: 0.05rem;
      color: $text;
      margin-left: 20px;
      @include sp {
        font-size: 1.1rem;
        line-height: 16px;
        margin-left: 3px;
      }
    }
    &__tt {
      font-size: 2.4rem;
      line-height: 38px;
      font-weight: 700;
      color: $text;
      margin-top: 25px;
      @include sp {
        font-size: 1.4rem;
        line-height: 20px;
        margin-top: 8px;
      }
    }
    &__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: block;
    }
  }
}

// コラム単体ページ
.single-column {
  .article {
    margin-bottom: 120px;
    @include sp {
      margin-bottom: 30px;
    }
    &__content {
      padding: 60px;
      margin-top: 80px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(#000, 0.13);
      margin-bottom: 80px;
      @include sp {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        margin-top: 25px;
        margin-bottom: 30px;
      }
    }
    &__title {
      font-size: 3.2rem;
      color: $text;
      line-height: 46px;
      letter-spacing: 0.05rem;
      font-weight: 700;
      font-family: $go;
      margin-bottom: 30px;
      @include sp {
        font-size: 2rem;
        line-height: 29px;
        margin-bottom: 10px;
      }
    }
    &__date {
      font-size: 1.8rem;
      letter-spacing: 0.05rem;
      line-height: 27px;
      color: $text;
      @include sp {
        font-size: 1.2rem;
        line-height: 23px;
      }
      img {
        margin-right: 10px;
        @include sp {
          width: 10px;
          margin-right: 3px;
        }
      }
    }
    &__cate {
      margin-top: 15px;
      @include sp {
        margin-right: 7px;
      }
      a {
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        padding: 5px 17px;
        display: inline-block;
        background-color: #dddddd;
        color: $text;
        margin-right: 5px;
        @include sp {
          font-size: 1.2rem;
          padding: 4px 10px;
          margin-right: 3px;
          min-width: 70px;
          margin-bottom: 3px;
          text-align: center;
        }
      }
    }
    &__top {
      padding-bottom: 40px;
      border-bottom: 1px solid #c3c3c3;
      margin-bottom: 40px;
      @include sp {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    &__bottom {
      p {
        font-size: 1.8rem;
        line-height: 38px;
        color: $text;
        margin-bottom: 20px;
        @include sp {
          font-size: 1.6rem;
          line-height: 28px;
          margin-bottom: 10px;
          letter-spacing: 0.25px;
        }
      }
      ul,
      ol {
        margin-bottom: 20px;
        li {
          font-size: 1.8rem;
          line-height: 38px;
          color: #222222;
          @include sp {
            font-size: 1.6rem;
            line-height: 28px;
            letter-spacing: 0.25px;
          }
        }
      }
      // 強調用リスト
      .unordered_list,
      .ordered_list {
        padding: 12px;
        margin-top: 16px;
        border: 2px solid #d0c699;
        border-radius: 5px;
      }
      .unordered_list li {
        text-indent: -2.6em;
        padding-left: 2.6em;
        padding-right: 0.5em;
        @include sp {
          text-indent: -1.6rem;
          padding-left: 1.6rem;
          padding-right: 0;
        }
      }
      .unordered_list li::before {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: 1em;
        margin-right: 1em;
        background-color: #d0c699;
        border-radius: 50%;
        @include sp {
          margin-left: 0rem;
          margin-right: 0.7rem;
        }
      }
      .ordered_list {
        counter-reset: my-counter;
      }
      .ordered_list li {
        position: relative;
        padding-left: 30px;
      }
      .ordered_list li:not(:last-child) {
        margin-bottom: 16px;
      }
      .ordered_list > li::before {
        content: counter(my-counter);
        counter-increment: my-counter;
        width: 22px;
        height: 22px;
        display: flex;
        position: absolute;
        top: 3px;
        left: 0;
        justify-content: center;
        align-items: center;
        line-height: 1;
        border-radius: 50%;
        background-color: #bea265;
        color: #fff;
        font-size: 85%;
        font-weight: bold;
        @include mq(lg) {
          top: 8px;
        }
      }
      // コラム用画像
      .column_img {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        figure {
          @include sp {
            margin: 20px 0;
          }
        }
        img,
        figcaption {
          display: block;
          margin: 0 auto 6px;
          width: 500px;
          @include sp {
            width: 100%;
          }
        }
        figcaption {
          font-size: 16px;
          line-height: 29px;
        }
        &__text {
          // figcaptionの文字数が多い場合に使用
          &--left {
            text-align-last: left;
          }
        }
      }
      // 画像2枚横並び
      .column_img.double {
        display: flex;
        justify-content: space-around;
        @include sp {
          display: block;
        }
        img,
        figcaption {
          width: 360px;
          @include sp {
            width: 100%;
          }
        }
      }
      // 画像3枚横並び
      .column_img.triple {
        display: flex;
        justify-content: space-around;
        @include sp {
          display: block;
        }
        img,
        figcaption {
          width: 225px;
          @include sp {
            width: 100%;
          }
        }
      }
      h1 {
        font-size: 3.2rem;
        color: #222222;
        line-height: 46px;
        letter-spacing: 0.05rem;
        font-weight: 700;
        font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic",
          sans-serif;
        margin-bottom: 30px;
        @include sp {
          font-size: 2rem;
          line-height: 29px;
          margin-bottom: 10px;
        }
      }
      h2 {
        font-size: 2.6rem;
        letter-spacing: 0.05rem;
        line-height: 38px;
        color: #fff;
        background-color: #cfa944;
        padding: 11px 20px;
        color: #fff;
        position: relative;
        margin-bottom: 40px;
        @include sp {
          font-size: 2rem;
          line-height: 25px;
          padding: 7px 10px;
          margin-bottom: 15px;
          margin-top: 30px;
        }
        &::before {
          content: "";
          width: calc(100% - 6px);
          height: calc(100% - 6px);
          @include bor(1px, #fbf1b5);
          position: absolute;
          left: 2px;
          top: 2px;
          @include sp {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            left: 1px;
            top: 1px;
          }
        }
      }
      h3 {
        font-size: 2.2rem;
        font-weight: 700;
        color: #cfa944;
        line-height: 38px;
        letter-spacing: 0.05rem;
        padding-left: 12px;
        position: relative;
        border-left: 6px solid #cfa944;
        margin: 30px 0;
        margin-bottom: 35px;
        @include sp {
          font-size: 2rem;
          line-height: 25px;
          padding-left: 7px;
          border-left: 3px solid #cfa944;
          margin: 30px 0 20px 0;
        }
        &::before {
          content: "";
          width: 100%;
          height: calc(100% + 20px);
          position: absolute;
          left: -6px;
          top: -11px;
          border-top: 2px solid #cfa944;
          border-bottom: 2px solid #cfa944;
          @include sp {
            height: calc(100% + 8px);
            border-top: 1px solid #cfa944;
            border-bottom: 1px solid #cfa944;
            top: -4px;
            left: -3px;
          }
        }
      }
      h4 {
        font-size: 2rem;
        line-height: 38px;
        color: #cfa944;
        background-color: #f9eed2;
        border: 2px solid #cfa944;
        padding: 2px 10px;
        margin-bottom: 25px;
        @include sp {
          border: 1px solid #cfa944;
          line-height: 30px;
          padding: 5px 7px;
          margin-bottom: 15px;
          margin-top: 30px;
        }
      }
      h5 {
        font-size: 2rem;
        font-weight: 700;
        color: #cfa944;
        line-height: 38px;
        letter-spacing: 0.05rem;
        padding-left: 12px;
        border-left: 6px solid #cfa944;
        margin-bottom: 25px;
        position: relative;
        @include sp {
          font-size: 1.8rem;
          line-height: 19px;
          padding-left: 7px;
          border-left: 3px solid #cfa944;
          margin-bottom: 15px;
          margin-top: 30px;
        }
        &::before {
          content: "";
          width: 100%;
          height: 2px;
          background-color: #cfa944;
          position: absolute;
          left: -6px;
          bottom: -10px;
          @include sp {
            height: 1px;
            bottom: -7px;
          }
        }
      }
      h6 {
        font-size: 2rem;
        font-weight: 700;
        color: #cfa944;
        line-height: 38px;
        letter-spacing: 0.05rem;
        padding-left: 12px;
        border-left: 6px solid #cfa944;
        margin-bottom: 25px;
        @include sp {
          font-size: 1.8rem;
          line-height: 19px;
          border-left: 3px solid #cfa944;
          margin-bottom: 10px;
          margin-top: 30px;
          padding-left: 7px;
        }
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
      img.aligncenter {
        margin: 0 auto;
      }
      // 社名などを強調するときに使用
      .orange_font {
        font-weight: 500;
        color: #d77114;
      }
      .yellow_u_line {
        font-weight: bold;
        background: linear-gradient(transparent 80%, #fff081 80%);
      }
      .brown_u_line {
        font-weight: bold;
        border-bottom: 2px solid #bea265;
      }
      .column_link_text {
        border-bottom: 1px solid #0000ee;
        color: #0000ee;
      }
    }
    &__kv {
      padding: 0 40px;
      margin-bottom: 35px;
      @include sp {
        padding: 0;
        margin-bottom: 25px;
      }
      img {
        width: 100%;
      }
    }
    // コラム中の目次
    #toc_container {
      padding: 35px 45px;
      background: #ebebeb;
      margin-top: 35px;
      margin-bottom: 40px;
      display: block !important;
      border: 2px solid #cccccc;
      @include sp {
        padding: 20px 20px;
        margin: 15px 0 30px;
      }
      .toc_title {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 38px;
        color: $text;
        margin-bottom: 35px;
        text-align: center;
        @include sp {
          margin-bottom: 20px;
          line-height: 1;
          font-size: 1.4rem;
        }
      }
      .toc_list {
        margin-top: 0 !important;
        > li {
          color: $text;
          line-height: 27px;
          margin-bottom: 5px;
          @include sp {
            margin-bottom: 1px;
          }
          &:last-child {
            margin-bottom: 0;
          }
          a {
            color: #0747a1;
          }
        }
        ol {
          li {
            span {
              color: $text;
              margin-right: 4px;
            }
          }
        }
      }
    }
    // 吹き出し
    &__imgtext {
      display: flex;
      margin-bottom: 55px;
      @include sp {
        margin-bottom: 30px;
        margin-top: 30px;
      }
      &__img {
        width: 93px;
        @include sp {
          width: 80px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        span {
          width: 93px;
          height: 93px;
          border-radius: 50%;
          overflow: hidden;
          padding: 0;
          display: block;
          line-height: 1;
          border: 3px solid #d0c699;
          @include sp {
            width: 80px;
            height: 80px;
            border: 1px solid #d0c699;
          }
        }
        figcaption {
          font-size: 1.6rem;
          line-height: 25px;
          font-weight: bold;
          color: #222222;
          text-align: center;
          @include sp {
            font-size: 1.4rem;
            line-height: 21px;
          }
        }
      }
      &__content {
        width: calc(100% - 133px);
        padding: 15px 15px 0 15px;
        border-radius: 5px;
        position: relative;
        margin-left: 40px;
        min-height: 119px;
        background-color: #faf8ee;
        border: 2px solid #d0c699;
        @include sp {
          width: calc(100% - 85px);
          padding: 15px;
          margin-left: 20px;
          border-radius: 3px;
          border: 1px solid #d0c699;
          min-height: auto;
        }
        &::before {
          content: "";
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-right: 29px solid #d0c699;
          position: absolute;
          left: -29px;
          top: 30px;
          @include sp {
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-right: 14px solid #d0c699;
            left: -14px;
            top: 20px;
          }
        }
        p {
          @include sp {
            font-size: 1.6rem;
            line-height: 28px;
            margin-bottom: 0;
          }
        }
      }
    }
    &__box {
      @include bor(2px, #cecece);
      padding: 40px;
      margin-top: 30px;
      @include sp {
        padding: 15px;
        flex-direction: column;
        margin-bottom: 30px;
        @include bor(1px, #cecece);
        margin-top: 40px;
      }
      &__img {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include sp {
          margin-bottom: 10px;
        }
        p {
          @include bor(1px, #dddddd);
          border-radius: 50%;
          overflow: hidden;
          width: 95px;
          height: 95px;
          padding: 0;
          margin: 0;
          @include sp {
            width: 100px;
            height: 100px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        figcaption {
          display: block;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 38px;
          color: $text;
          margin-left: 20px;

          span {
            font-weight: normal;
          }
        }
      }
      &__text {
        padding: 0;
        margin-bottom: 15px;
        font-size: 1.8rem;
        line-height: 32px;
        color: $text;
      }
      &__link {
        display: flex;
        justify-content: flex-end;
        a {
          font-size: 1.4rem;
          padding: 10px;
          display: inline-block;
          font-weight: bold;
          color: #fff;
          background: $brand-color-ocher;
          @include mq(lg) {
            font-size: 1.6rem;
            padding: 16px;
          }
        }
      }
    }
    // コラム下部の見積もりフォームのキャッチコピー
    &__estimate-form {
      margin-top: 50px;
      text-align: center;
      p {
        font-size: 1.8rem;
        line-height: 24px;
        @include sp {
          font-size: 1.6rem;
        }
      }
      p:first-child {
        margin-bottom: 20px;
        font-size: 2rem;
        font-weight: bold;
      }
      strong {
        color: #ff6c00;
      }
    }
  }

  .article__imgtext.st01 .article__imgtext__content {
    margin-right: 40px;
    background-color: #fff;
    margin-left: 0;
  }

  @media screen and (max-width: 767px) {
    .article__imgtext.st01 .article__imgtext__content {
      margin-right: 20px;
    }
  }

  .article__imgtext.st01 .article__imgtext__content:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    left: auto;
    right: -29px;
  }

  @media screen and (max-width: 767px) {
    .article__imgtext.st01 .article__imgtext__content:before {
      right: -14px;
      top: 20px;
    }
  }

  .text_show_result {
    font-size: 1.8rem;
    line-height: 32px;
    color: #222222;
    font-weight: 400;
    display: none;
  }

  .fa_sec02.show .fa_sec02__content {
    margin-bottom: 10px;
  }

  .js-search-show {
    display: none;
    margin-top: 40px;
  }

  // コラム中のテーブル
  .article__bottom table {
    margin-bottom: 30px;
    width: 100%;
    border: 2px solid #bea265;
    border-collapse: collapse;
    th,
    td {
      text-align: left;
      padding: 10px;
      font-size: 1.8rem;
      line-height: 26px;
      border-collapse: collapse;
    }
    th {
      font-weight: bold;
      color: #fff;
      background-color: #cfa944;
      border-bottom: 1px solid #fff;
    }
    td {
      color: #222222;
      border-bottom: 1px solid #bea265;
      p {
        margin-bottom: 0;
      }
    }
    // 中央寄せタイプ
    &.type1 {
      th,
      td {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .article__bottom .cta_01__img {
      margin-bottom: 0;
    }
    .article__bottom table th {
      font-size: 1.6rem;
      line-height: 28px;
    }

    .article__bottom table td {
      font-size: 1.6rem;
      line-height: 28px;
    }
  }

  @media (min-width: 768px) {
    .article__bottom .cta_01__img img.sp-only {
      display: none;
    }
  }

  .article__bottom .cta_01__top__left {
    line-height: 20px;
    font-size: 1.6rem;
    color: #fff;
    margin-bottom: 0;
  }

  .article__bottom .cta_01__top__right {
    font-size: 3.7rem;
    color: #ff6c00;
    font-weight: 900;
    line-height: 1;
    margin-bottom: 0;
  }

  .article__bottom .cta_01__top__right span {
    font-size: 85px;
  }

  .article__bottom .cta_01__bottom {
    font-size: 1.6rem;
    line-height: 26px;
    color: #222222;
  }

  .article__bottom .cta_01__item02__top {
    font-size: 1.6rem;
    line-height: 26px;
    margin-bottom: 0;
  }

  .article__bottom .cta_01__item02__phone {
    font-size: 5rem;
    line-height: 62px;
    margin-bottom: 0;
    padding-left: 62px;
  }

  .article__bottom .cta_01__item02__phone img {
    left: 0px;
    top: 0px;
    width: 56px;
  }

  .article__bottom .btn-cta__top {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #fff;
    line-height: 1;
  }

  .article__bottom .btn-cta__bottom {
    font-size: 2rem;
    line-height: 25px;
    color: #fff;
    margin-bottom: 0;
  }

  .article__bottom .btn-cta__top img {
    display: unset;
  }

  .article__bottom .cta_01__item02 {
    margin-left: 10px;
  }

  .article__bottom .cta_01 {
    margin: 40px 0 40px 0;
  }

  @media screen and (max-width: 767px) {
    .article__bottom .btn-cta,
    .article__bottom .cta_01__item01 {
      display: none;
    }
    .article__bottom iframe.wp-embedded-content {
      width: 100% !important;
    }
  }

  .article__bottom dl {
    padding: 12px;
    margin-top: 16px;
    margin-bottom: 20px;
    border: 2px solid #d0c699;
    border-radius: 5px;
    font-size: 1.8rem;
    @include sp {
      font-size: 1.6rem;
    }
    dt, dd {
      line-height: 38px;
    }
    dt {
      padding-left: 2.6em;
      padding-right: 0.5em;
      text-indent: -2.6em;
      &:not(:first-child) {
        margin-top: 1.6rem;
      }
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: 1em;
        margin-right: 1em;
        background-color: #d0c699;
        border-radius: 50%;
      }
      @include sp {
        padding-right: 0;
        padding-left: 1.6rem;
        text-indent: -1.6rem;
        &::before {
          margin-left: 0rem;
          margin-right: 0.7rem;
        }
      }
    }
    dd {
      padding-left: 2.6em;
      @include sp {
        padding-left: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .article__bottom dl dt,
    .article__bottom dl dd {
      font-size: 1.6rem;
      line-height: 28px;
      letter-spacing: 0.25px;
    }
  }

  // コラム「大阪でおすすめの遺品整理業者」用のデザイン
  .option_info {
    max-width: 100%;
    text-align: center;
  }
  .option_info label {
    display: block;
    padding: 15px;
    cursor: pointer;
    font-size: 1.8rem;
  }
  @media screen and (max-width: 480px) {
    .option_info label {
      font-size: 1.6rem;
    }
  }
  .option_info input {
    display: none;
  }
  .option_info_content {
    height: 0 !important;
    overflow: hidden !important;
  }
  #option_info1:checked ~ #option_info_content1 .option_info_content,
  #option_info2:checked ~ #option_info_content2 .option_info_content,
  #option_info3:checked ~ #option_info_content3 .option_info_content,
  #option_info4:checked ~ #option_info_content4 .option_info_content,
  #option_info5:checked ~ #option_info_content5 .option_info_content,
  #option_info6:checked ~ #option_info_content6 .option_info_content,
  #option_info7:checked ~ #option_info_content7 .option_info_content,
  #option_info8:checked ~ #option_info_content8 .option_info_content,
  #option_info9:checked ~ #option_info_content9 .option_info_content {
    height: auto !important;
  }
}

// コラムアーカイブページ（カテゴリーごとのページも含む）
.post-type-archive-column,
.tax-column_category {
  .c_sec01 {
    background-color: #f5f5f5;
    padding-top: 65px;
    padding-bottom: 80px;
    @include sp {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .price_top__nav {
      @media (min-width: 769px) {
        li {
          width: 32%;
          margin-right: 2%;
          margin-bottom: 20px;
          a {
            line-height: 72px;
            height: 78px;
            background-image: linear-gradient(
              90deg,
              rgb(6, 131, 127) 0%,
              rgb(40, 178, 174) 100%
            );
          }
          &:nth-child(2n) {
            margin-right: 2%;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      @include sp {
        li {
          width: 48.5%;
          margin-right: 3%;
          margin-bottom: 7px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        &__btn {
          font-size: 1.4rem;
          height: 50px;
          line-height: 45px;
          &::before {
            bottom: 5px;
          }
        }
      }
    }
  }
  .c_sec02 {
    margin-bottom: 120px;
    margin-top: 80px;
    @include sp {
      margin-top: 30px;
      margin-bottom: 35px;
    }
  }
}
