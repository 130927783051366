.ihinseiri{
    .s_sec03__top{
        @include sp{
            font-size: 1.4rem;
            padding: 5px 30px;
        }
        &::after{
            display: none;
        }
    }
    iframe {
        width: 100%;
        @include mq(lg) {
            width: 560px;
        }
    }
}
.i_sec01{
    background-color: #f5efdf;
    padding: 60px 0 80px;
    @include sp{
        padding: 21px 0;
    }
    &__content{
        margin-top: 55px;
        border-top: 1px solid rgb(192, 192, 192);
        padding-top: 50px;
        @include sp{
            margin-top: 20px;
            padding-top: 20px;
        }
        
    }
    &__tt{
        font-size: 2.4rem;
        color: $text;
        line-height: 42px;
        font-weight: 700;
        text-align: center;
        letter-spacing: 1px;
        font-family: $go;
        @include sp{
            font-size: 1.2rem;
            line-height: 21px;
        }
        span{
            font-size: 3rem;
            color: #ff1d1d;
            @include sp{
                font-size: 1.5rem;
            }
        }
    }
}
.i_sec02{
    margin-top: 90px;
    margin-bottom: 80px;
    @include sp{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .c-tt01{
        margin-bottom: 50px;
        @include sp{
            margin-bottom: 19px;
        }
    }
    &__box{
        width: 960px;
        background-image: url(images/ihinseiri/s2_img01.png);
        padding-top: 70px;
        padding-left: 55px;
        padding-right: 55px;
        padding-bottom: 29px;
        background-size: 100% 100%;
        margin: 0 auto;
        position: relative;
        @include sp{
            padding: 15px 15px 8px;
            width: 100%;
            background-image: url(images/ihinseiri/s2_img01_sp.png);
        }
        &::after{
            content: '';
            width: 390px;
            height: 335px;
            background-image: url('images/ihinseiri/s2_img.png');
            background-size: contain;
            position: absolute;
            right:5px;
            bottom: 0;
            @include sp{
                display: none;
            }
        }
        &__tt{
            font-size: 2.4rem;
            font-family: $go;
            color: #30b2f1;
            font-weight: 700;
            margin-bottom: 15px;
            @include sp{
                font-size: 1.8rem;
                line-height: 28px;
                margin-bottom: 10px;
            }
        }
        &__text{
            font-size: 3.4rem;
            color: $text;
            font-weight: 700;
            font-family: $go;
            margin-bottom: 35px;
            @include sp{
                font-size: 1.6rem;
                margin-bottom: 28px;
            }
        }
    }
    .list_01{
        background: transparent;
        border: 0;
        padding: 0;
        max-width: 100%;
        li{
            font-family: $yu;
            color: #022222;
            font-size: 2.2rem;
            margin-bottom: 19px;
            @include sp{
                font-size: 1.6rem;
                padding-left: 20px;
                margin-bottom: 9px;
            }
        }
        &::before{
            border-top: 37px solid #2fb2f1;
            bottom: -85px;
            @include sp{
                border-top: 18px solid #2fb2f1;
                bottom: -34px;
            }
        }
    }
    &__bot{
        margin-bottom: 65px;
        margin-top: 100px!important;
        @include sp{
            margin-top: 40px!important;
            margin-bottom: 17px;
        }
        &__item{
            width: 525px;
            @include sp{
                width: 100%;
            }
        }
        .s_sec02__img{
            width: 560px;
            text-align: center;
            @include sp{
                width: 193px;
                margin-top: 3px;
            }
        }
        &__top{
            font-size: 2.4rem;
            letter-spacing: 1px;
            color: #fff;
            background-color: #ff6c00;
            border-radius: 20px;
            line-height: 1;
            padding: 7px 29px;
            display: table;
            font-family: $go;
            font-weight: 700;
            @include sp{
                font-size: 1.4rem;
                border-radius: 10px;
                padding: 4px 15px;
                margin: 0 auto;
            }
        }
        &__tt{
            font-size: 3rem;
            line-height: 48px;
            font-weight: 700;
            color: $text;
            letter-spacing: 0.05rem;
            margin-bottom: 30px;
            margin-top: 15px;
            @include sp{
                font-size: 1.8rem;
                text-align: center;
                line-height: 28px;
                margin-top: 7px;
                margin-bottom: 20px;
            }
            span{
                font-size: 3.6rem;
                color: #ff0404;
                background-color: #fff9c2;
                @include sp{
                    font-size: 1.8rem;
                }
            }
        }
        &__text{
            font-size: 1.8rem;
            color: $text;
            line-height: 36px;
            letter-spacing: 0.05rem;
            @include sp{
                font-size: 1.6rem;
                line-height: 28px;
            }
            span{
                font-weight: 700;
                color: #ff2d17;
                background-color: #fff9c2;
            }
        }
    }
    &__gr{
        background-color: #fffce2;
        padding: 60px 60px 45px;
        border-radius: 5px;
        border: 1px solid rgb(179, 135, 76);   
        @include sp{
            padding: 11px 15px;
            border-radius: 3px;
        }
        .sec07__dl__nav{
            padding-bottom: 0;
            padding-top: 40px;
            @include sp{
                padding: 15px 0 0;
            }
        }
        .c-tt07{
            font-family: $go;
            @include sp{
                font-size: 1.8rem;
                background: none;
                color:#cfa944 ;
                padding: 0;
                span{
                    color: $text;
                    font-size: 1.4rem;
                }
            }
        }
        .sec07__dl__nav__img{
            @include sp{
                margin-bottom: 5px;
            }
        }
        .sec07__dl__nav__text {
            @include sp{
                font-size: 1.4rem;
                line-height: 19px;
                span{
                    font-size: 1.2rem;
                }
            }
        }
    }
}
.i_sec03{
    margin-bottom: 80px;
    @include sp{
        margin-bottom: 30px;
    }
    .c-tt01{
        margin-bottom: 50px;
        @include sp{
            margin-bottom:15px;
        }
        span{
            color: #ff2b2b;
        }
    }
    
    &__text{
        font-size: 1.8rem;
        line-height: 38px;
        color: $text;
        text-align: center;
        margin-bottom: 50px;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
            text-align: left;
            margin-bottom: 15px;
        }
        .text_color{
            color: #ff4141;
            font-weight: 700;
            background-color: #fff9c2;
        }
    }
    .nav_06--col_5{
        li{
            @include sp{
                border-radius: 3px 3px 0 0;
            }
        }
        .nav_06__img{
            @include sp{
                height: 83px;
            }
        }
        .nav_06__text{
            font-size: 1.8rem;
            padding: 9px 10px;
            @include sp{
                font-size: 1.25rem;
                padding: 5px 10px;
            }
        }
    }
    .r_sec02__box{
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }
    .c-tt04{
        text-align: center;
        @include sp{
            font-size: 1.8rem;
            line-height: 19px;
            margin-bottom: 20px;
        }
        span{
            color: #ff2626;
        }
    }
    &__gr{
        background-color: #F2F2F2;
        // @include bor(1px,#B3874C);
        padding: 40px 50px;
        @include sp{
            border: 0;
            padding: 20px 15px 5px;
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include sp{
            justify-content: flex-start;
        }
        li{
            font-size: 2.2rem;
            color: #022222;
            font-weight: 500;
            position: relative;
            padding-left: 30px;
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 30px;
            @include sp{
                font-size: 1.4rem;
                line-height: 16px;
                margin-bottom: 10px;
                margin-right: 7px;
                padding-left: 20px;
            }
            &:last-child{
                margin-right: 0;
            }
            &:before {
                content: '';
                width: 22px;
                height: 20px;
                background: url(images/common/icon_checkmark.png);
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                top: 3px;
                @include sp{
                    width: 11px;
                    height: 10px;
                }
            }
        }
    }
}
.i_sec04{
    background-color: #f5efdf;
    padding: 55px 0;
    @include sp{
        padding: 30px 0;
    }
}
.nav_ihin_01{
    display: flex;
    justify-content: center;
    margin-top: 33px;
    @include sp{
        margin-top: 10px;
    }
    li{
        width: 150px;
        text-align: center;
        margin-right: 25px;
        @include sp{
            width: 70px;
            margin-right: 10px;
        }
        &:last-child{
            margin-right: 0;
        }
    }
    &__img{
        img{
            max-width: 100%;
        }
    }
    &__text{
        color: #5bb6d5;
        font-size: 2rem;
        font-weight: 700;
        margin-top: 15px;
        @include sp{
            font-size: 1.2rem;
            margin-top: 10px;
        }
    }
}
.i_sec05{
    margin-top: 100px;
    margin-bottom: 100px;
    @include sp{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .c-tt01{
        margin-bottom: 30px;
        @include sp{
            margin-bottom: 15px;
        }
    }
    .c-tt04{
        text-align: center;
        margin-bottom: 50px;
        @include sp{
            font-size: 1.8rem;
            line-height: 28px;
            margin-bottom: 12px;
        }
    }
    &__dl{
        background-color: #F5EFDF;
        padding: 40px 50px 30px;
        margin-bottom: 80px;
        @include sp{
            padding: 20px;
            text-align: center;
            margin-bottom: 20px;
        }
        dt{
            font-size: 2.4rem;
            text-align: center;
            font-weight: 700;
            font-family: $go;
            letter-spacing: 0.05rem;
            color: #FF2C2C;
            display: table;
            margin: 0 auto 25px;
            background-color: #fff9c2;
            @include sp{
                font-size: 1.8rem;
                line-height: 28px;
                display: inline;
                text-align: center;
            }
        }
        dd{
           font-size: 1.8rem;
           color: $text;
           line-height: 38px; 
           @include sp{
                font-size: 1.6rem;
                line-height: 28px;
                text-align: left;
                margin-top: 5px;
           }
        }
    }
    &__top{
        display: table;
        margin:  0 auto;
        font-size: 2.4rem;
        letter-spacing: 0.05rem;
        font-weight: 700;
        line-height: 35px;
        padding: 0 28px;
        background-color: #FF6C00;
        border-radius: 17px;
        color: #fff;
        @include sp{
            font-size: 1.2rem;
            padding: 0 15px;
            line-height: 18px;
        }
    }
    .c-tt07{
        line-height: 48px;
        margin-top: 15px;
        margin-bottom: 40px;
        @include sp{
            font-size: 1.8rem;
            line-height: 30px;
            margin-top: 7px;
            margin-bottom: 4px;
        }
        span{
            color: #FF3E32;
            background-color: #FFF9C2;
            font-size: 3.6rem;
            @include sp{
                font-size: 1.8rem;
            }
        }
    }
    &__text{
        font-size: 2rem;
        color: $text;
        text-align: center;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
            margin-top: 8px;
        }
    }
    &__text02{
        font-size: 1.8rem;
        color: $text;
        text-align: center;
        margin-bottom: 40px;
        @include sp{
            font-size: 1.4rem;
            line-height: 23px;
            margin-bottom: 15px;
        }
    }
    &__img{
        text-align: center;
        margin: 50px 0 80px;
        @include sp{
            margin: 15px 0 30px;
        }
        img{
            max-width: 100%;
        }
    }
    .btn_01{
        @media(min-width: 768px){
            max-width: 495px;
            line-height: 90px;
            background-image: linear-gradient( to top, rgb(6,131,127) 0%, rgb(40,178,174) 100%);
            font-size: 2.4rem;
        }

    }
}
.nav_ihin_02{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    @include sp{
        margin-bottom: 10px;
    }
    >li{
        border-radius: 5px;
        @include bor(1px,#CFA944);
        background-color: #fff;
        width: 525px;
        margin-right: 30px;
        padding-bottom: 25px;
        margin-bottom: 30px;
        @include sp{
            width: 100%;
            margin-right: 0;
            border-radius: 3px;
            padding-bottom: 15px;
            margin-bottom: 10px;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
    }
    &__top{
        font-size: 2.4rem;
        color: #fff;
        text-align: center;
        font-weight: 700;
        letter-spacing: 0.1rem;
        line-height: 38px;
        padding: 10px;
        background-color: #CFA944;
        @include sp{
            font-size: 1.8rem;
            line-height: 23px;
            padding: 5px;
        }
    }
    &__img{
        width: 410px;
        height: 185px;
        margin: 30px auto 25px;
        border-radius:10px;
        overflow: hidden;
        @include sp{
            width: 205px;
            height: 92px;
            margin: 15px auto;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__gr{
        display: flex;
        justify-content: space-between;
        padding:0 30px;
        @include sp{
            padding: 0 12px;
        }
        dl{
            width: 50%;
            dt{
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 25px;
                padding: 0 17px;
                border-radius: 12px;
                margin-bottom: 10px;
                display: table;
                @include sp{
                    font-size: 1.2rem;
                    line-height: 16px;
                    border-radius: 6px;
                    padding: 0 10px;
                }
            }
            dd{
                font-size: 2.6rem;
                font-weight: 700;
                line-height: 38px;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 25px;
                }
                span{
                    font-family: $ar;
                    font-size: 4.4rem;
                    @include sp{
                        font-size: 2.7rem;
                    }
                }
            }
        }
        &__left{
            position: relative;
            &::before{
                content: '';
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 17px solid #666666;
                position: absolute;
                right: -8px;
                bottom: 9px;
                @include sp{
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left:11px solid #666666;
                    right: -5px;
                }
            }
            dt{
                color: #666666;
                @include bor(1px, #666666);
            }
            dd{
                color: #666666;
            }
        }
        &__right{
            padding-left: 20px;
            @include sp{
                padding-left: 12px;
            }
            dt{
                color: #FF0A0A;
                @include bor(1px, #FF0A0A);
            }
            dd{
                color: #FF0A0A;
            }
        }
    }
    &__nav{
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        margin-top: 25px;
        @include sp{
            padding: 0 15px;
            margin-top: 15px;
        }
        li{
            text-align: center;
            background-color: #EEEEEE;
            padding: 15px;
            width: 220px;
            @include sp{
                width: 48%;
                padding: 5px;
            }
            h4{
                font-size: 2rem;
                font-weight: 700;
                line-height: 29px;
                color: $text;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 22px;
                }
            }
            figure{
                line-height: 82px;
                @include sp{
                    line-height: 50px;
                }
            }
            img{
                max-width: 100%;
                @include sp{
                    max-height: 37px;
                }
            }
            p{
                font-size: 1.6rem;
                line-height: 22px;
                color: $text;
                @include sp{
                    font-size: 1.2rem;
                    line-height: 16px;
                }
            }
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 38px;
        color: $text;
        letter-spacing: 0.05rem;
        padding: 0 30px;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
            padding: 0 15px;
        }
        span{
            font-size: 1.4rem;
        }
    }
}
.i_sec06{
    margin-top: 95px;
    margin-bottom: 95px;
    @include sp{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .c-tt01{
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 15px;
        }
    }
    .c-tt04{
        text-align: center;
        @include sp{
            font-size: 1.8rem;
            line-height: 28px;
            margin-bottom: 15px;
        }
    }
    .c-tt05{
        @include sp{
            margin-top: 30px;
        }
    }
    .i_sec03__text.left{
        text-align: left;
        .no_sp{
            @include sp{
                font-weight: normal;
            }
        }
    }
    .r_sec01__box__tt{
        margin-bottom: 55px;
        @include sp{
            margin-bottom: 15px;
        }
        span{
            background-color: #FFFEC0;
        }
    }

    .r_sec01__box{
        margin-bottom: 90px;
        @include sp{
            margin-bottom: 30px;
        }
    }
    &__flex{
        display: flex;
        @include sp{
            flex-direction: column-reverse;
        }
        &__img{
            width: 424px;
            @include sp{
                width: 100%;
                margin-bottom: 15px;
            }
            img{
                max-width: 100%;
                @include sp{
                    width: 100%;
                }
            }
        }
        .r_sec01__box__text{
            width: calc(100% - 424px);
            padding-right: 30px;
            @include sp{
                width: 100%;
                padding-right: 0;

            }
        }
    }
    &__text{
        font-size: 2.4rem;
        line-height: 38px;
        color: $text;
        text-align: center;
        font-weight: 700;
        margin-bottom: 75px;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
            font-weight: normal;
            margin-bottom: 30px;
        }
    }
    .nav_09{
        &__tt{
            font-size: 3rem;
            line-height: 48px;
            @include sp{
                font-size: 1.5rem;
                line-height: 28px;
                text-align: center;
            }
            span{
                font-size: 3.6rem;
                color: #FF0404;
                background-color: #FFF9C2;
                @include sp{
                    font-size: 1.8rem;
                }
            }
        }
        &__text{
            span{
                color:#FF2D17 ;
                background-color: #FFF9C2;
                text-decoration: none;
                font-weight: 700;
            }
        }
    }
}
.i_sec07{
    margin-bottom: 80px;
    margin-top: 80px;
    @include sp{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .nav_ihin_02{
        margin-bottom: 50px;
        @include sp{
            margin-bottom: 20px;
        }
        li{
            min-height: 667px;
            @include sp{
               min-height: auto;
            }
        }
    }
    .sec07__box{
        margin-bottom: 75px;
        @include sp{
            margin-bottom: 30px;
        }
    }
    .sec07__box__tt{
        font-size: 3.8rem;
        color: #9A8251;
        @include sp{
            font-size: 1.8rem;
            line-height: 23px;
        }
    }
    .nav_02{
        margin-bottom: 30px;
        @include sp{
            margin-bottom: 0;
        }
    }
}
.i_sec08{
    padding-top: 60px;
    padding-bottom: 80px;
    background-color: rgb(245, 239, 223);
    @include sp{
        padding: 30px 0;
    }
    .nav_03{
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 10px;
            .nav_03__tt{
                font-weight: normal;
            }
        }
    }
}

.i_slider{
    width: 860px;
    margin: 0 auto;
    margin-bottom: 45px;
    margin-top: -20px;
    @include sp{
        width: auto;
        margin: 0 45px 18px;
    }
    &__item{
        background-color: #FFFFFF;
        @include bor(1px,#CFA944);
        padding: 30px;
        border-radius: 5px;
        @include sp{
            padding:  15px;
            border-radius: 3px;
        }
    }
    &__top{
        font-size: 1.8rem;
        color: #fff;
        background-color: #FF6C00;
        border-radius: 15px;
        line-height: 29px;
        display: table;
        margin: 0 auto;
        padding: 0 30px;
        margin-bottom: 10px;
        @include sp{
            font-size: 1.4rem;
            line-height: 23px;
            padding: 0 8px;
            margin-bottom: 5px;
        }
    }
    &__tt{
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 28px;
        color: $text;
        margin-bottom: 30px;
        text-align: center;
        @include sp{
            line-height: 23px;
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
        span{
            color: #FF2929;
        }
    }
    &__img{
        text-align: center;
        width: 530px;
        margin: 0 auto;
        margin-bottom: 20px;
        @include sp{
            width: 100%;
            margin-bottom: 10px;
        }
        img{
            width: 100%;
        }
    }
    &__dl{
        
        padding-bottom: 10px;
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        @include sp{
            padding-bottom: 5px;
            margin-bottom: 5px;
        }
        dt{
            font-size: 1.8rem;
            color: $text;
            letter-spacing: 2px;
            font-weight: 700;
            line-height: 28px;
            @include sp{
                font-size: 1.4rem;
                line-height: 14px;
            }
        }
        dd{
            color: #FF6C00;
            font-size: 1.8rem;
            font-weight: 700;
            @include sp{
                font-size: 1.8rem;
            }
            span{
                font-family: $ar;
                font-size: 3rem;
                font-weight: 700;
                line-height: 28px;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 18px;
                }
                
            }
        }
        &.color_02{
            dd{
                color: #CFA944;
                span{
                    color: #CFA944;
                }
            }
        }
    }
    &__content{
        width: 530px;
        margin: 0 auto;
        @include sp{
            width: 100%;
        }
    }
    &__bottom{
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 15px;
        &__text01{
            font-size: 1.6rem;
            line-height: 22px;
            letter-spacing: 0.05rem;
            font-weight: 700;
            color: #fff;
            background-color: #FF2929;
            border-radius: 11px;
            padding: 0 14px;
            margin-right: 10px;
            @include sp{
                font-size: 1.1rem;
                line-height: 15px;
                padding: 0 6px;
                margin-right: 8px;
            }
        }
        &__text02{
            font-family: $ar;
            font-size: 4rem;
            line-height: 28px;
            color:#FF2929 ;
            font-weight: 700;
            @include sp{
                font-size: 2.9rem;
                line-height: 19px;
            }
        }
        &__text03{
            font-size: 2.2rem;
            font-weight: 700;
            color: #FF2929;
            @include sp{
                font-size: 1.6rem;
            }
        }
    }
    .slick-prev, .slick-next{
        width: 40px;
        height: 40px;
        z-index: 3;
        background-size: contain;
        @include sp{
            width: 30px;
            height: 30px;
        }
        &::before{
            display: none;
        }
    }
    .slick-prev{
        background-image: url('images/ihinseiri/prev.png');
        left: -65px;
        @include sp{
            left: -40px;
        }
    }
    .slick-next{
        background-image: url('images/ihinseiri/next.png');
        right: -65px;
        @include sp{
            right: -40px;
        }
    }
}
.nav_ihin_03{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @include sp{
        margin-bottom: 15px;
    }
    li{
        @include bor(1px,#BEA265);
        border-radius: 4px;
        width: 350px;
        margin-right:15px ;
        padding-bottom: 10px;
        margin-bottom: 10px;
        @include sp{
            width: 32%;
            margin-right: 2%;
            padding-top: 5px;
            border-radius: 2px;
            margin-bottom: 6px;
            padding-bottom: 5px;
        }
        &:nth-child(3n){
            margin-right: 0;
        }
        a{
            display: block;
        }
    }
    &__img{
        line-height: 129px;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        @include sp{
            height: 55px;
            line-height: 55px;
            margin-bottom: 2px;
        }
        img{
            max-width: 100%;
            @include sp{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    &__text{
        font-size: 2rem;
        // line-height: 27px;
        color: $text;
        text-align: center;
        font-weight: 700;
        @include sp{
            font-size: 1.4rem;
            line-height: 28px;
            margin-top: 5px;
        }
    }
}

.support-box {
    padding: 30px 10%;
    border-bottom: 1px solid $brand-color-green;
    &:last-child {
        border-bottom: none;
    }
    @include mq(lg) {
        padding: 60px 100px;
    }
    &__title {
        margin-bottom: 20px;
        font-size: 18px;
        color: $brand-color-green;
        @include mq(lg) {
            margin-bottom: 60px;
            font-size: 30px;
        }
    }
    &__inner {
        @include mq(lg) {
            display: flex;
        }
        img {
            display: block;
            margin: 0 auto 36px;
            width: 80%;
            border-radius: 6px;
            @include mq(lg) {
                margin: initial;
                margin-right: 10%;
                width: 360px;
                height: 210px;
            }
        }
    }
    &__item {
        margin-bottom: 36px;
        @include mq(lg) {
            margin-bottom: 48px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__name {
        margin-bottom: 10px;
        font-size: 16px;
        @include mq(lg) {
            font-size: 20px;
            margin-bottom: 14px;
        }
    }
    &__link {
        padding-bottom: 2px;
        border-bottom: 1px solid $brand-color-black;
        @include mq(lg) {
            padding-bottom: 3px;
            border-bottom: 2px solid $brand-color-black;
        }
    }
    p {
        font-size: 14px;
        line-height: 21px;
        @include mq(lg) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
