.c-header{
    overflow: hidden;
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::before{
            content: '';
            width: 5000px;
            height: 100%;
            background-color: #F5EFDF;
            position: absolute;
            left: 50%;
            top: 0;
            @include tranX(-50%);
            z-index: -1;
            @include sp {
                display: none;
            }
        }
    }
    .container{
        @include sp{
            padding: 0;
        }
    }
    &__texthead{
        font-size: 1.3rem;
        line-height: 18px;
        color: $text;
        letter-spacing: 0.075rem;
        font-weight: normal;
        @include sp {
            padding: 5px;
            width: 100%;
            background: #F5EFDF;
        }
    }
    &__nav01{
        background-color: $orange;
        padding-left: 5px;
        padding-right: 5px;
        @include sp {
            display: none;
        }
        li{
            display: inline-block;
            a{
                font-size: 1.4rem;
                color: #fff;
                line-height: 18px;
                letter-spacing: 0.05rem;
                padding: 7px 8px;
                display: inline-block;
                &:hover{
                    opacity: 0.85;
                }
            }
        }
        li+li::before{
            content: '|';
            padding: 0 5px;
            display: inline-block;
            color: #fff;
            font-size: 1.4rem;
            line-height: 18px;
        }
    }
    &__center{
        display: flex;
        padding: 20px 0;
        position: relative;
        @include sp{
            padding: 8px 8px 12px;
            align-items: center;
        }
        
        &::before{
            content: '';
            width: 5000px;
            height: 1px;
            background-color: #DCDCDC;
            position: absolute;
            left: 50%;
            bottom: 0;
            @include tranX(-50%);
            z-index: -1;
        }
    }
    &__rightsp{
        display: none;
        @include sp{
            width: calc(100% - 140px);
            display: flex;
            justify-content: flex-end;
        }
    }
    &__sp {
        display: none;
        @include sp {
            display: block;
        }
        &--btn-menu{
            display: block;
            position: fixed;
            top: 7px;
            right: 2%;
            padding-top: 38px;
            width: 64px;
            height: 60px;
            text-align: center;
            z-index: 9999;
            color: #fff;
            border-radius: 5px;
            font-size: 1.4rem;
            background: url('images/common/icon_menu.png') no-repeat;
            background-size: 20px 18px;
            background-position: top 10px center;
            background-color: #EC871D;
            box-shadow: 0 3px 0 #854605;
            transition: .3s all;
            &.is-open{
                background-image: url('images/common/icon_menu_open.png');
            }
        }
    }
    &__btn01{
        width: 82px;
        height: 43px;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        font-size: 1.4rem;
        padding-top: 22px;
        box-shadow: 0 3px 0 #A70000;
        background: url('images/common/icon_02.png') no-repeat;
        background-size: 24px 17px;
        background-position: top 5px center;
        background-color: #FF2727;
    }
    &__logo{
        width: 262px;
        @include sp{
            width: 140px;
        }
        a{
            display: block;
            &:hover{
                opacity: 0.85;
            }
        }
        img{
            max-width: 100%;
        }
    }
    &__right{
        display: flex;
        align-items: center;
        width: calc(100% - 262px);
        justify-content: flex-end;
        @include sp{
            display: none;
        }
        &__text{
            display: table;
            width: 91px;
            .top{
                font-size: 1.6rem;
                color: #fdff32;
                letter-spacing: 2px;
                line-height: 20px;
                font-weight: 700;
                display: block;
                line-height: 26px;
                background-color: $orange;
                text-align: center;
            }
            .bottom{
                font-size: 1.6rem;
                color: #fff;
                letter-spacing: 2px;
                line-height: 20px;
                font-weight: 700;
                display: block;
                line-height: 26px;
                background-color: $text;
                text-align: center;
            }
        }
    }
    &__phone{
        padding-left: 62px;
        background-image: url('images/common/icon_phone.png');
        background-repeat: no-repeat;
        background-size: 55px 55px;
        background-position: left center;
        margin-left: 10px;
        margin-right: 6px;
        span{
            font-size: 3.7rem;
            color: $text;
            font-family: $ba;
            font-weight: 700;
        }
        p{
            font-size: 1.4rem;
            color: $text;
            font-weight: 700;
            line-height: 20px;
        }
    }
    &__email{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 33px;
        background-color: $blue;
        max-width: 252px;
        width: 100%;
        height: 66px;
        box-shadow: 0px 5px 0 #004240;
        font-size: 1.8rem;
        letter-spacing: 0.2rem;
        line-height: 20px;
        color: #fff;
        font-weight: 700;
        padding-left: 100px;
        background-image: url('images/common/icon_email.png');
        background-repeat: no-repeat;
        background-size: 44px 33px;
        background-position: left 40px center;
        position: relative;
        &:hover{
            opacity: 0.85;
        }
        span{
            font-size: 1.6rem;
            letter-spacing: 0.1rem;
            display: block;
        }
        &::after{
            content: '';
            width: 9px;
            height: 12px;
            background-image: url('images/common/icon_arrow.png');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 50%;
            right: 15px;
            @include tranY(-50%);
        }
    }
}
.navigation{
    position: relative;
    &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #DCDCDC;
        position: absolute;
        left: 50%;
        bottom: 0;
        @include tranX(-50%);
        z-index: -1;
    }
    @include sp{
        display: none;
    }
    &__main{
        display: flex;
        justify-content: center;

        >li{
            width: calc(100%/7);
            &:hover{
                .sub-menu{
                    max-height: 9999px;
                    opacity: 1;
                    padding: 30px 0;
                }
            }
            &:last-child{
                >a{
                    color: #fff;
                    background-color: #FF2727;
                    &::before{
                        display: none;
                    }
                }
            }
            >a{
                font-size: 1.5rem;
                font-weight: 700;
                line-height: 22px;
                color: $text;
                display: block;
                text-align: center;
                position: relative;
                padding: 20px;
                &::before{
                    content: '';
                    width: 1px;
                    height: 22px;
                    background-color: #C6C6C6;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include tranY(-50%);
                }
            }
        }
    }
    
}
.sub-menu{
    background-color: rgba(41, 17, 0, 0.92);
    transition: all 0.2s ease-in;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    left: 0;
    text-align: center;
    position: absolute;
    z-index: 7;
    .menu_inner{
        max-width: 1080px;
        margin: 0 auto;
        display: flex;
        @include sp{
            flex-wrap: wrap;
            padding: 0 7px;
        }
        li{
            display: inline-block;
            margin-right: 20px;
            @include sp{
                width: 32%;
                margin-right: 2%;
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            a{
                font-size: 1.8rem;
                font-weight: 700;
                color: #fff;
                text-align: center;
                display: block;
                @include sp{
                    font-size: 1.3rem;
                    position: relative;
                    margin-bottom: 5px;
                }
                &:hover{
                    opacity: 0.85;
                }
                p{
                    margin-top: 10px;
                    @include sp{
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        color: $text;
                        line-height: 19px;
                        background-color: rgba(255,255,255,0.8);
                    }
                }
                img{
                    max-width: 100%;
                    @include sp{
                        width: 100%;
                    }
                }
            }
        }
    }
}
.navigation_sp{
    display: none;
    position: relative;
    &::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #DCDCDC;
        position: absolute;
        left: 50%;
        bottom: 0;
        @include tranX(-50%);
        z-index: -1;
    }
    @include sp{
        display: flex;
    }
    >li{
        width: 20%;
        &.active{
            .sub-menu{
                max-height: 9999px;
                opacity: 1;
                padding: 9px 0 5px;
            }
        }
        >a{
            font-size: 1.4rem;
            line-height: 18px;
            color: #391e02;
            display: block;
            font-weight: 700;
            text-align: center;
            padding: 10px 0;
            position: relative;
            padding-top: 33px;
            >img{
                max-width: 100%;
                display: block;
                margin: 0 auto;
                position: absolute;
                top: 8px;
                left: 50%;
                @include tranX(-50%);
            }
        }
    }
    >li+li{
        border-left: 1px solid #DCDCDC;
    }
}
.menu_sp{
    background-color: #f6f1e4;
    position: fixed;
    top: 62px;
    z-index: 2;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 100%;
    &.show{
        top: 62px;
    }
    .c_topsp__bottom{
        background: none;
    }
    .scroll_box {
        height: 100%;
        overflow-y: scroll;
        padding: 100px 15px 80px;
        box-sizing: border-box;
    }
}
.c_topsp{
    display: none;
    @include sp{
        display: block;
        background-color: #F4F4F4;
    }
    &__head{
        text-align: center;
        font-size: 1.4rem;
        letter-spacing: 0.05rem;
        color: $text;
        line-height: 20px;
        padding: 7px 0;
        border-bottom: 1px solid #BFB9A8;
        display: block;
        img{
            margin-right: 4px;
            vertical-align: inherit;
        }
    }
    &__bottom{
        font-size: 1.4rem;
        line-height: 16px;
        color: $text;
        letter-spacing: 0.05rem;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid #BFB9A8;
        background: url('images/common/icon_minus.png') no-repeat;
        background-position: right 10px center;
        background-size: 18px 18px;

    }
    &__list{
        li{
            width: 100%;
            border-bottom: 1px solid #BFB9A8;
            a {
                font-size: 1.4rem;
                line-height: 16px;
                color: $text;
                letter-spacing: 0.05rem;
                display: block;
                padding: 10px 20px;
                padding-left: 30px;
                background: url('images/common/icon_f.png') no-repeat;
                background-size: 15px 15px;
                background-position: left 7px center;
            }
        }
    }
    &__link{
        font-size: 1.4rem;
        line-height: 16px;
        color: #fff;
        padding: 10px;
        display: block;
        background-color: #ff2727;
        text-align: center;
        background-image: url('images/common/icon_arrow01.png');
        background-repeat: no-repeat;
        background-position: right 7px center;
        background-size: 18px 18px;
    }
}

@media screen and (max-width: 767px) {
    .menu_sp .c_topsp__head,.menu_sp .c_topsp__link, .menu_sp .c_topsp__list li a, .menu_sp .c_topsp__bottom {
      font-size: 1.8rem;
      padding: 20px;
    }
    .menu_sp .c_topsp__list li a{
      padding-left: 30px;
    }
}


.set_h1 {
    text-align: center;
    padding: 14px;
    h1 {
        font-size: 2rem;
        line-height: 26px;
        @include sp {
            font-size: 1.8rem;
        }
    }
}
