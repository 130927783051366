.price{
    .kv_main{
        @media(min-width: 768px){

        }
    }
    .sec08{
        &.bg{
            @include sp{
                padding-bottom: 30px;
            }
        }
    }
    &__bg{
        background-color: #F5EFDF;
        padding-top: 40px;
        padding-bottom: 50px;
        @include sp{
            padding-top: 15px;
            padding-bottom: 20px;
        }
    }
}
.price_top{
    background-color: #F4F3F1;
    padding-top: 75px;
    padding-bottom: 70px;
    @include sp{
        padding-top: 27px;
        padding-bottom: 15px;
    }
    &__tt{
        font-size: 4.8rem;
        font-weight: 700;
        line-height: 78px;
        letter-spacing: 0.25rem;
        color: $text;
        text-align: center;
        margin-bottom: 60px;
        font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", sans-serif;
        @include sp{
            font-size: 2rem;
            line-height: 31px;
            margin-bottom: 20px;
        }
        span{
            color: #FF2323;
            display: block;
            text-decoration: underline;
        }
    }
    &__nav{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 48%;
            margin-right: 3%;
            margin-bottom: 40px;
            @include sp{
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
            }
            &:nth-child(2n){
                margin-right: 0;
            }
        }
        &__text{
            font-size: 1.8rem;
            font-weight: 700;
            color: $text;
            text-align: center;
            margin-bottom: 7px;
            @include sp{
                font-size: 1.4rem;
                line-height: 19px;
            }
        }
        &__btn{
            font-size: 2rem;
            color: #fff;
            line-height: 32px;
            letter-spacing: 0.05rem;
            display: block;
            text-align: center;
            font-weight: bold;
            border-radius: 5px;
            background-image: linear-gradient( to top, rgb(6,131,127) 0%, rgb(40,178,174) 100%);
            border: 2px solid rgb(11, 137, 133);
            height: 94px;
            line-height: 86px;
            box-shadow: 0 5px 0 #024442;
            position: relative;
            @include sp{
                font-size: 1.8rem;
                height: 55px;
                line-height: 49px;
                box-shadow: 0 3px 0 #024442;
                border: 1px solid rgb(11, 137, 133);
            }
            span{
                font-size: 2.2rem;
                color: #FCFF00;
                @include sp{
                    font-size: 1.8rem;
                }
            }
            &::before{
                content: '';
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 6px solid #fff;
                position: absolute;
                left: 50%;
                bottom: 5px;
                transform: translateX(-50%);
                @include sp{
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 4px solid #fff;
                }
            }
        }
    }
}
.price_bottom{
    margin-top: 100px;
    @include sp{
        margin-top: 30px;
    }
    .tb_urgency.st_01{
        margin-bottom: 45px;
        @include sp{
            margin-bottom: 15px;
        }
        .text_color{
            color: #FF1D1D;
            font-weight: 700;
        }
        .fs22{
            font-size:3rem;
            @include sp{
                font-size: 1.4rem;
                color: $text;
            }
        }
        .text_top{
            font-size: 1.8rem;
            color: #fff;
            font-weight: bold;
            background-color: #FF1D1D;
            border-radius: 12px;
            padding: 4px 13px;
            display: block;
            line-height: 1;
            width: 116px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 6px;
            @include sp{
                font-size: 1.2rem;
                line-height: 17px;
                width: 80px;
                padding: 1px;
                margin-bottom: 6px;
            }
        }
        th{
            line-height: 28px;
            padding: 10px;
            @include sp{
                line-height: 19px;
                padding: 5px 0;
            }

        }
        td{
            line-height: 28px;
            padding: 25px 10px;
            width: 33%;
            font-size: 2.4rem;
            @include sp{
                line-height: 19px;
                padding: 10px 5px;
                font-size: 1.4rem;
                width: 35%;
            }
        }
    }
    .tb_urgency.st_01 td:nth-child(2){
        color: $text;
        .text_color{
            font-size: 2.6rem;
            @include sp{
                font-size: 1.2rem;
            }
            &.ff_a{
                font-size: 3.6rem;
                @include sp{
                    font-size: 1.6rem;
                }
            }
        }
    }
    &__text{
        font-size: 2.8rem;
        line-height: 46px;
        letter-spacing: 0.05rem;
        text-align: center;
        color: #222222;
        margin-bottom: 45px;
        font-weight: bold;
        font-family: "Yu Gothic", sans-serif;
        @include sp{
            font-size: 1.4rem;
            line-height: 21px;
            margin-bottom: 20px;
        }
    }
    .nav_06{
        margin-top: 35px;
        @include sp{
            margin-top: 17px;
        }
    }
    &__content{
        margin-bottom: 80px;
        @include sp{
            margin-bottom: 30px;
        }
    }
    .tb_urgency.st_02{
        margin-top: -33px;
        @include sp{
            margin-top: 0;
        }
        th{
            font-size: 2.4rem;
        }
        th, table{
            @include sp{
                border: 1px solid #b6b6b6;
                font-size: 1.4rem;
            }
        }
        td{
            padding: 20px 20px;
            font-size: 2.4rem;
            width: 50%;
            @include sp{
                padding: 5px;
                border: 1px solid #b6b6b6;
                font-size: 1.4rem;
                line-height: 23px;
            }
        }
        td:nth-child(2){
            color: #FF1D1D;
        }
        td:first-child{
            text-align: left;
        }
    }
    .c-tt07.st01{
        color: #C19B36;
        font-family: $go;
    }
    .s_sec04__box__text04{
        font-size: 1.8rem;
        display: table;
        background-color: #FF2727;
        border-radius: 13px;
        color: #fff;
        line-height: 27px;
        padding: 0 18px;
        margin-bottom: 10px;
        @include sp{
            text-shadow: none;
            font-size: 1.4rem;
            line-height: 13px;
            padding: 4px 8px;
            border-radius: 6px;
            margin: 0 auto;
            font-style: normal;
        }
    }
    .s_sec04__box__text03{
        color: #FF2727;
        @include sp{
            font-size: 1.6rem;
            line-height: 19px;
            margin-top: 8px;
        }
    }
    .s_sec04__box__text02{
        margin-top: 0;
        color: #06837F;
        @include sp{
            font-size: 2rem;
        }
    }
    .s_sec04__box__text05{
        @include sp{
            text-shadow: none;
            background: none;
            font-style: normal;
            &::before, &::after{
                display: none;
            }
        }
    }
    .list_02{
        margin-top: 15px;
        @include sp{
            margin-top: 5px;
        }
    }
    .s_sec04__box__nav{
        >li{
            &:first-child{
                width: calc(100% - 475px);
                @include sp{
                    width: 100%;
                }
            }
        }
    }
    .s_sec04__box{
        margin-bottom: 85px;
        @include sp{
            padding: 20px;
            margin-bottom: 15px;
        }
    }
    &__box{
        font-size: 2rem;
        color: #06837f;
        text-align: center;
        font-weight: 700;
        line-height: 27px;
        background-color: #EDFFFE;
        @include bor(1px,#06837F);
        padding: 25px 10px;
        margin-bottom: 60px;
        @include sp{
            font-size: 1.6rem;
            line-height: 23px;
            padding: 12px 10px;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }
    .tb_urgency.st_03{
        margin-top: 35px;
        @include sp{
            margin-top: 15px;
        }
        table{
            @include bor(1px,#06837F);
            @include sp{
                border: 1px solid #b6b6b6;
            }
        }
        th{
            font-size: 2.2rem;
            color: #FCFF00;
            background-color: #06837F;
            @include bor(1px,#06837F);
            @include sp{
                font-size: 1.6rem;
                border: 1px solid #b6b6b6;
                line-height: 28px;
            }
        }
        td{
            @include bor(1px,#06837F);
            padding: 20px 40px;
            width: 50%;
            font-size: 2.4rem;
            @include sp{
                padding: 8px 10px;
                border: 1px solid #b6b6b6;
                font-size: 1.4rem;
            }
            &:first-child{
                text-align: left;
            }
            &:nth-child(2){
                color: #FF1D1D;
                background-color: #EDFFFE;
                @include sp{
                    font-size: 1.1rem;
                }
            }
            .fs20{
                font-size: 2rem;
                @include sp{
                    font-size: 1.2rem;
                }
            }
        }
    }
    &__text02{
        font-size: 1.8rem;
        line-height: 27px;
        color: $text;
        margin-top: 25px;
        @include sp{
            font-size: 1.2rem;
            line-height: 19px;
            margin-top: 15px;
        }
    }
    .c-tt07.st01{
        span{
            @include sp{
                font-size: 1.6rem;
            }
        }
    }
}