.c-tt01{
    font-size: 4rem;
    line-height: 60px;
    letter-spacing: 0.05rem;
    color: $text;
    text-align: center;
    display: table;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 4px solid #D4D4D4;
    position: relative;
    margin-bottom: 70px;
    font-weight: 700;
    @include sp{
        font-size: 2rem;
        line-height: 28px;
        padding: 0;
        padding-bottom: 10px;
        border-bottom: 2px solid #D4D4D4;
        margin-bottom: 25px;
        letter-spacing: 0;
    }
    &::before{
        content: '';
        width: 69px;
        height: 4px;
        background-color: $orange;
        position: absolute;
        bottom: -4px;
        left: 50%;
        @include tranX(-50%);
        @include sp{
            height: 2px;
            bottom: -2px;
            width: 35px;
        }
    }
    span{
        color: $orange;
    }
    &--st01{
        border-bottom: 4px solid $blue;
        @include sp{
            border-bottom: 2px solid $blue;
        }
    }
}
.c-tt02{
    font-size: 4.2rem;
    color: $blue;
    text-align: center;
    @include sp{
        font-size: 1.8rem;
    }
}
.title_head{
    font-size: 2.2rem;
    color: #fff;
    letter-spacing: 1px;
    line-height: 39px;
    background-color: #9A8251;
    padding-left: 15px;
    @include sp{
        font-size: 1.8rem;
        line-height: 30px;
    }
    a {
        color: #fff;
    }
    span{
        font-size: 1.8rem;
        @include sp{
            font-size: 1.4rem;
        }
    }
}
.c-tt03{
    font-size: 2rem;
    line-height: 38px;
    color: $text;
    font-weight: 700;
    letter-spacing: 0.05rem;
    border-bottom: 2px solid #CFA944;
    margin-bottom: 25px;
    @include sp{
        font-size: 1.8rem;
        line-height: 20px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #CFA944;
    }
}
.c-tt04{
    font-size: 3.2rem;
    line-height: 46px;
    letter-spacing: 0.05rem;
    color: $text;
    font-family: $go;
    margin-bottom: 30px;
    font-weight: 700;
}
.c-tt05{
    font-size: 2.8rem;
    font-family:$go ;
    text-align: center;
    letter-spacing: 1px;
    font-weight: bold;
    background-color: #CFA944;
    border-radius: 32px;
    line-height: 37px;
    padding: 14px 10px;
    color: #fff;
    position: relative;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;
    @include sp{
        font-size: 1.6rem;
        line-height: 20px;
        padding: 10px;
        margin-bottom: 15px;
        box-shadow: none;
    }
    &::before{
        content: '';
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        @include bor(1px,#fbf1b5);
        position: absolute;
        left: 2px;
        top: 2px;
        border-radius: 32px;
        @include sp{
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            left: 1px;
            top: 1px;
        }
    }
    span{
        font-size: 2rem;
        font-weight: 500;
        display: block;
        line-height: 29px;
        @include sp{
            font-size: 1.6rem;
            line-height: 18px;
        }
    }
    &--st01{
        padding: 25px 10px;
        border-radius: 50px;
        @include sp{
            font-size: 2rem;
            line-height: 26px;
            padding: 10px;
            border-radius: 25px;
        }
        &::before{
            border-radius: 50px;
            @include sp{
                border-radius: 25px;
            }
        }
    }
      
}
.c-tt06{
    font-size: 3.6rem;
    text-align: center;
    letter-spacing: 0.05rem;
    color: #BC9327;
    font-weight: bold;
    line-height: 46px;
    position: relative;
    @include sp{
        font-size: 1.8rem;
        line-height: 23px;
    }
    span{
        background: #fff;
        position: relative;
        z-index: 2;
        padding: 0 20px;
        @include sp{
            padding: 0 10px;
        }
    }
    &--bg{
        span{
            background: url(images/top/s3_bg.png) no-repeat center;
        }
    }
    &--bg02{
        span{
            background: url(images/urgency/s4_background.png) center;
        }
    }
    &::before{
        content: '';
        width: 100%;
        height: 3px;
        background-color:#BC9327 ;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include sp{
            height: 1.5px;
        }
    }

}
.c-tt07{
    font-size: 3.6rem;
    font-weight: bold;
    color: $text;
    line-height: 38px;
    text-align: center;
    display: table;
    margin: 0 auto;
    background-image: url('images/urgency/icon_tt_left.png'), url('images/urgency/icon_tt_right.png');
    background-repeat: no-repeat;
    background-position: left bottom, right bottom;
    padding-left: 70px;
    padding-right: 70px;
    @include sp{
        font-size: 2.2rem;
        line-height: 25px;
        background-size: 24px 24px, 24px 24px;
        padding: 0 35px;
        font-family: $go;
    }
    span{
        font-size: 2.4rem;
        letter-spacing: 0.05rem;
        line-height: 38px;
        font-weight: bold;
        display: block;
        @include sp{
            font-size:1.25rem;
            line-height: 25px;
        }
    }
}
.c-tt08{
    font-size: 3.2rem;
    line-height: 45px;
    letter-spacing: 0.05rem;
    color: #05528b;
    padding-left: 25px;
    position: relative;
    @include sp{
        font-size: 1.8rem;
        line-height: 21px;
        padding-left: 15px;
    }
    &::before{
        content: '';
        width: 10px;
        height: 100%;
        background-color: #05528b;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        @include sp{
            width: 5px;
            border-radius: 3px;
        }
          
    }
}

// タイトル上部の吹き出し
p.title-balloon {
    padding: 5px 30px;
    margin: 0px auto 15px;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 108, 0);
    font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
    display: table;
    position: relative;
    border-radius: 22px;
    font-size: 1.4rem;
    @include mq(lg) {
        margin-bottom: 30px;
        padding: 10px 38px;
        font-size: 3rem;
    }
    &::before{
        content: '';
        position: absolute;
        left: 50%;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 6px solid #FF6C00;
        bottom: -6px;
        @include tranX(-50%);
        @include mq(lg) {
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 16px solid #FF6C00;
            bottom: -16px;
        }
    }
}
