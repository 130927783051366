.list_01{
    padding: 35px 60px 20px;
    background-color: #FFFEFA;
    background-image: url('images/top/bg_list.png');
    background-repeat: no-repeat;
    background-size: cover;
    @include bor(2px,$orange);
    border-radius: 5px;
    max-width: 840px;
    margin: 0 auto;
    position: relative;
    @include sp{
        padding: 15px;
        @include bor(1px,$orange);
    }
    &::before{
        content: '';
        border-left: 46px solid transparent;
        border-right: 46px solid transparent;
        border-top: 37px solid $orange;
        position: absolute;
        bottom: -37px;
        left: 50%;
        @include tranX(-50%);
        @include sp{
            border-left-width: 23px;
            border-right-width: 23px;
            border-top-width: 18px;
            bottom: -18px;
        }
    }
    li{
        font-size: 1.8rem;
        font-weight: 700;
        color: #022222;
        line-height: 26px;
        letter-spacing: 0.05rem;
        padding-left: 30px;
        position: relative;
        margin-bottom: 15px;
        @include sp{
            font-size: 1.6rem;
            line-height: 20px;
        }
        &::before{
            content: '';
            width: 22px;
            height: 20px;
            background: url('images/common/icon_checkmark.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 3px;
            @include sp{
                width: 16px;
                height: 15px;
            }
        }
    }
}
.list_faq{
    margin-bottom: 37px;
    @include sp{
        margin-bottom: 0;
    }
    &:last-child{
        margin-bottom: 0;
    }
    dt{
        position: relative;
        font-size: 2.2rem;
        font-weight: 700;
        color: $orange;
        letter-spacing: 0.05rem;
        line-height: 33px;
        border-bottom: 2px solid $orange;
        padding-bottom: 15px;
        margin-bottom: 7px;
        @include sp{
            font-size: 1.8rem;
            line-height: 22px;
            color: $text;
            border-bottom: 1px solid #D4D4D4;
            padding-bottom: 10px;
            background-image: url('images/common/icon_plus.png');
            background-size: 24px 24px;
            background-position: right 5px center;
            background-repeat: no-repeat;
            position: relative;
            padding: 16px 40px 16px 23px;
            margin-bottom: 0;
            font-weight: 500;
        }
        &::before{
            content: 'Q.';
            font-family: $ar;
            font-size: 3.2rem;
            color: $orange;
            font-weight: 900;
            margin-right: 10px;
            @include sp{
                margin-right: 3px;
                font-size: 1.8rem;
                position: absolute;
                left: 0;
                top: 18px;
            }
        }
    }
    dd{
        font-size: 1.8rem;
        color: $text;
        letter-spacing: 0.05rem;
        line-height: 32px;
        position: relative;
        padding-left: 40px;
        font-weight: 400;
        @include sp{
            font-size: 1.6rem;
            line-height: 22px;
            padding-left: 20px;
            display: none;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #D4D4D4;
        }
        &::before{
            content: 'A.';
            font-family: $ar;
            font-size: 3.2rem;
            color: #138676;
            font-weight: 900;
            margin-right: 6px;
            position: absolute;
            left: 0;
            top: 50%;
            @include tranY(-50%);
            @include sp{
                font-size: 1.6rem;
            }
        }
    }
}
.kv_main{
    width: 100%;
    position: relative;
    img{
        width: 100%;
    }
    &__title{
        position: absolute;
        top: 47%;
        left: 50%;
        @include tranfo(-50%, -50%);
        font-size: 7.4rem;
        color: $text;
        text-shadow: 0 0 8px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
        font-weight: 900;
        white-space: nowrap;
        @include sp{
            font-size: 3rem;
        }
        &.st01{
            font-size: 6rem;
            white-space: nowrap;
            @include sp{
                font-size: 2rem;
            }
        }
        span{
            font-size:4.2rem ;
            display: block;
            line-height: 62px;
            text-align: center;
            @include sp{
                font-size: 1.6rem;
                line-height: 30px;
            }
        }
    }
    &__title01{
        font-size: 8rem;
        position: absolute;
        top: 47%;
        left: 50%;
        @include tranfo(-50%, -50%);
        color: $text;
        text-shadow: 0 0 8px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
        font-weight: 900;
        background: linear-gradient(transparent 60%, #FF6C00 40%);
        background-position: bottom;
        padding-bottom: 5px;
        white-space: nowrap;
        @include sp{
            font-size: 3rem;
            letter-spacing: 3px;
        }
        span{
            font-size: 7.4rem;
            @include sp{
                font-size: 3rem;
            }
        }
    }
    &__text{
        font-size: 4.6rem;
        letter-spacing: 0.075rem;
        color: $text;
        text-shadow: 0 0 8px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
        font-weight: 900;
        text-align: center;
        position: absolute;
        top: 31%;
        left: 50%;
        transform: translateX(-50%);
        @media (min-width: 768px) and (max-width: 1380px){
            font-size: 3vw;
	        top: 30%;
        }
        @include sp{
            font-size: 2.2rem;
            line-height: 1;
            white-space: nowrap;
            top: 27%;
        }
    }
    &__text02{
        font-size: 2.6rem;
        line-height: 46px;
        letter-spacing: 1px;
        text-align: center;
        font-weight: 700;
        font-family: $go;
        text-shadow: 0 0 8px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
        position: absolute;
        left: 50%;
        bottom: 12%;
        transform: translateX(-50%);
        @include sp{
            font-size: 1.2rem;
            line-height: 17px;
            width: 100%;
            bottom: 15%;
        }

    }
}
.breadcrumbs{
    margin-top: 10px;
    padding-bottom: 10px;
    position: relative;
    &::before{
        content: '';
        width: 500%;
        height: 1px;
        background-color:#DCDCDC ;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
    li{
        font-size: 1.4rem;
        color: $text;
        display: inline-block;
        line-height: 20px;
        a{
            color: $text;
        }
    }
    li+li::before{
        content: '>';
        padding: 0 4px;
        display: inline-block;
    }
}
.c_link_top{
    font-size: 2rem;
    letter-spacing: 0.05rem;
    color: $text;
    font-weight: 700;
    display: table;
    @include sp{
        font-size: 1.8rem;
    }
    &:hover{
        opacity: 0.85;
    }
    img{
        vertical-align: top;
        margin-right: 9px;
        @include sp{
            width:18px ;
            margin-right: 7px;
        }
    }
}
.c_link_01{
    font-size: 1.8rem;
    letter-spacing: 1px;
    color: #222222;
    line-height: 27px;
    display: inline-block;
    margin-left: 23px;
    margin-bottom: 10px;
    position: relative;
    margin-right: 27px;
    padding-bottom: 5px;
    border-bottom: 1px solid #222222;
    @include sp{
        font-size: 1.4rem;
        line-height: 20px;
        margin-bottom: 15px;
        margin-top: 0;
        margin-right: 10px;
    }
    &:hover{
        border-bottom: 0;
    }
    &--noborder{
        border-bottom: 0;
    }
    &::before{
        content: '';
        width: 16px;
        height: 16px;
        background: url(images/common/icon_arrow03.png) no-repeat center;
        background-size: contain;
        position: absolute;
        left: -23px;
        top: 50%;
        transform: translateY(-50%);
        @include sp{
            width: 15px;
            height: 15px;
        }
    }
}
.list_02{
    display: flex;
    flex-wrap: wrap;
    li{
        color: #fff;
        text-align: center;
        width: 235px;
        background-color: #06837F;
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        line-height: 27px;
        margin-bottom: 5px;
        margin-right: 5px;
        @include sp{
            font-size: 1.4rem;
            line-height: 28px;
            width: 49%;
            margin-right: 2%;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
    }
}
.pagination{
    display: table;
    margin: 0 auto;
    a.pagination__next{
        width: 106px;
        background-color: #fff6df;
        @include sp{
            width: 53px;
        }
    }
    a.nextpostslink{
        width: 106px;
        background-color: #fff6df;
        @include sp{
            width: 53px;
        }
    }
    a.pagination__prev{
        width: 106px;
        background-color: #fff6df;
        @include sp{
            width: 53px;
        }
    }
    a.previouspostslink{
        width: 106px;
        background-color: #fff6df;
        @include sp{
            width: 53px;
        }
    }
    a.last {
        width: 80px;
        background-color: #fff6df;
        @include sp{
            width: 43px;
        }
    }
    a.first {
        width: 80px;
        background-color: #fff6df;
        @include sp{
            width: 43px;
        }
    }
    a{
        font-size: 2.2rem;
        font-family: $ar;
        text-align: center;
        color: #cfa944;
        display: inline-block;
        width: 46px;
        line-height: 46px;
        @include bor(1px,#cfa944);
        border-radius: 5px;
        margin: 0 2.5px;
        @media(min-width: 768px){
            &:hover{
                background-color: #cfa944;
                color: #fff;
            }
        }
        @include sp{
            font-size: 1.1rem;
            width: 23px;
            line-height: 23px;
        }
        &.active{
            background-color: #cfa944;
            color: #fff;
        }
    }
    
    span{
        font-size: 2.2rem;
        font-family: $ar;
        text-align: center;
        color: #cfa944;
        display: inline-block;
        letter-spacing: 2px;
        margin: 0 5px;
    }
    span.pages {
        display: none;
    }
    span.current {
        font-size: 2.2rem;
        font-family: "Arial", sans-serif;
        text-align: center;
        background-color: #cfa944;
        color: #fff;
        display: inline-block;
        width: 46px;
        line-height: 46px;
        border: 1px solid #cfa944;
        border-radius: 5px;
        margin: 0 2.5px;
        @include sp{
            font-size: 1.1rem;
            width: 23px;
            line-height: 23px;
        }
    }
}
