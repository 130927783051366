@charset "UTF-8";
@import "setting/vars";
@import "setting/functions";
@import "setting/mixin";
@import "setting/reset";
@import "setting/base";

//==========================================================================
// utility .u-
// ==========================================================================
@import "utility/_color";
@import "utility/_utility";
@import "utility/_misc";
@import "utility/_margin";
@import "utility/_padding";
@import "utility/_text";

//==========================================================================
// Layout .l-
// ==========================================================================
@import "layouts/_layout";
@import "layouts/_header";
@import "layouts/_footer";

//==========================================================================
// Compoment .c-
// ==========================================================================
@import "component/_title";
@import "component/_text";
@import "component/_btn";
@import "component/_other";
@import "component/_navi";
@import "component/_font";
@import "component/_mylightbox";
@import "component/_cta";
@import "component/_estimate";
@import "component/_nav_drop";
@import "component/_card";

//==========================================================================
// Project .p-
// ==========================================================================
@import "project/_top";
@import "project/_sitemap";
@import "project/_company";
@import "project/_urgency";
@import "project/_price";
@import "project/_area";
@import "project/_reason";
@import "project/_flow";
@import "project/_faq";
@import "project/_case";
@import "project/_voice";
@import "project/_ihinseiri";
@import "project/_gomiyashikikataduke";
@import "project/_tokushuseiso";
@import "project/_column";
@import "project/_contact_and_estimate";
@import "project/_experience";
@import "project/_service";
@import "project/_lp";
@import "project/_lp-ibarakishi";


@media(min-width: 768px){
	.sp-only{
		display: none;
	}
}

@include sp{
	.pc-only{
		display: none;
	}
}
