.tokushuseiso{
    .i_sec01{
        padding: 60px 0;
        @include sp{
            padding: 10px 0;
        }
    }
}
.t_sec02{
    margin-top: 60px;
    margin-bottom: 66px;
    @include sp{
        margin-top: 40px;
        margin-bottom: 7px;
    }
    .c-tt01{
        margin-bottom: 65px;
        @include sp{
            margin-bottom: 20px;
        }
    }
    .i_sec02__box::after{
        right: -60px;
    }
    .i_sec02__box{
        margin-bottom: 110px;
        @include sp{
            margin-bottom: 37px;
            padding-bottom: 7px;
        }
    }
    .c-tt07{
        font-size: 4rem;
        line-height: 50px;
        @include sp{
            font-size: 1.8rem;
            line-height: 24px;
        }
        span{
            font-size: 4rem;
            color: #FF0404;
            display: inline-block;
            @include sp{
                font-size: 1.8rem;
                line-height: 24px;
            }
        }
    }
    .i_sec02__bot{
        margin-top: 34px!important;
        margin-bottom: 70px;
        @include sp{
            margin-top:8px!important;
            margin-bottom: 15px;
        }
    }
    .list_01{
        @include sp{
            li{
                margin-bottom: 9px;
            }
            &::before{
                bottom: -44px;
                @include sp{
                    bottom: -33px;
                }
            }
        }
    }
    .i_sec02__bot__text{
        span{
            background-color: transparent;
            color: #FF0404;
            &.u_bold{
                color: $text;
            }
        }
    }
    .i_sec02__bot__item{
        width: 625px;
        padding-left: 25px;
        @include sp{
            width: 100%;
            padding-left: 0;
        }
    }
    .s_sec02__img{
        width: 455px;
        text-align: left;
        @include sp{
            width: 193px;
        }
    }
    .i_sec02__bot__tt{
        font-size: 2.6rem;
        line-height: 40px;
        margin-top: 0;
        margin-bottom: 45px;
        @include sp{
            font-size: 1.8rem;
            line-height: 28px;
            margin-bottom: 21px;
        }
        span{
            font-size: 2.6rem;
            @include sp{
                font-size: 1.6rem;
                line-height: 28px;
            }
        }
    }
    .nav_06__img{
        height: 146px;
        @include sp{
            height: 79px;
        }
    }
    .nav_06__text{
        padding: 8px 10px;
        @include sp{
            font-size: 1.25rem;
        }
    }
}
.t_sec03{
    .list_01{
        @include bor(2px,#D4D4D4);
        margin-bottom: 95px;
        max-width: 960px;
        padding: 62px 53px 48px;
        @include sp{
            padding: 20px 15px 15px;
            margin-bottom: 25px;
        }
        &::before {
            border-left: 46px solid transparent;
            border-right: 46px solid transparent;
            border-top: 37px solid #D4D4D4;
            @include sp{
                display: none;
            }
        }
        li{
            margin-bottom: 19px;
            font-size: 2.2rem;
            font-family: $yu;
            @include sp{
                margin-bottom: 9px;
                font-size: 1.6rem;
            }
        }
    }
    .c-tt01{
        margin-bottom: 60px;
        @include sp{
            margin-bottom: 20px;
        }
    }
    .c-tt07{
        font-size: 4rem;
        @include sp{
            font-size: 1.8rem;
        }
    }
    .s_sec04__box{
        margin-bottom: 85px;
        @include sp{
            margin-bottom: 30px;
        }
    }
    .s_sec04__box__text02{
        color: #06837f;
    }
    .s_sec04__box__text04{
        font-size: 1.8rem;
        display: table;
        background-color: #FF2727;
        border-radius: 13px;
        color: #fff;
        line-height: 27px;
        padding: 0 18px;
        margin-bottom: 10px;
        @include sp{
            text-shadow: none;
            font-size: 1.4rem;
            line-height: 13px;
            padding: 4px 8px;
            border-radius: 6px;
            margin: 0 auto;
            font-style: normal;
        }
    }
    .s_sec04__box__text05{
        @include sp{
            text-shadow: none;
            background: none;
            font-style: normal;
            &::before, &::after{
                display: none;
            }
        }
    }
    .s_sec04__box__text06{
        @include sp{
            margin-top: 6px;
        }
    }
    .s_sec04__box__nav > li:first-child {
        width: calc(100% - 475px);
        @include sp{
            width: 100%;
        }
    }
    .s_sec04__box__text03{
        color: #ff2727;
        @include sp{
            font-size: 1.6rem;
            line-height: 19px;
            margin-top: 8px;
        }
    }
    .list_02 {
        margin-top: 15px;
        @include sp{
            margin-top: 3px;
        }
    }
    &__tt{
        font-size: 2.4rem;
        text-align: center;
        font-family: $go;
        line-height: 38px;
        letter-spacing: 0.05rem;
        font-weight: 700;
        color: #06837F;
        padding-top: 50px;
        border-top: 1px solid #B6B6B6;
        margin-top: 41px;
        margin-bottom: 20px;
        @include sp{
            margin-top: 10px;
            padding-top: 10px;
            font-size: 1.2rem;
            line-height: 16px;
            margin-bottom: 5px;
        }
        span{
            font-size: 3.2rem;
            @include sp{
                font-size: 1.8rem;
                line-height: 29px;
            }
        }
    }
    .btn_01{
        margin-top: 40px;
        @include sp{
            margin-top: 15px;
        }
        @media (min-width: 769px){
            line-height: 85px;
            font-size: 2.4rem;
            max-width: 494px;
        }
    }
    .tb_urgency.st01{
        th{
            @include sp{
                padding: 2px;
            }
            &:nth-child(2){
                background-color: #f4da79;
            }
        }
        td{
            @include sp{
                padding: 2px 10px;
            }
            &:nth-child(2){
                background-color: #fffdeb;
            }
        }
    }
    .list_area__bot dd span{
        @include sp{
            font-size: 3rem;
        }
    }
}
.t_sec05{
    margin-top: 100px;
    margin-bottom: 110px;
    @include sp{
        margin-top: 40px;
        margin-bottom: 30px;
    }
    &__gr{
        margin-bottom: 110px;
        @include sp{
            margin-bottom: 35px;
        }
        &__top{
            display: flex;
            align-items: center;
            justify-content: center;
            @include sp{
                flex-direction: column;
            }
        }
        &__tt{
            font-size: 2.8rem;
            line-height: 46px;
            letter-spacing: 0.05rem;
            font-weight: 700;
            text-align: center;
            color: #138576;
            position: relative;
            @include sp{
                font-size: 1.6rem;
                line-height: 23px;
                margin-bottom: 15px;
            }
        }
        p{
            img{
                @include sp{
                    width: 132px;
                }
            }
        }
    }
    &__block{
        margin-bottom: 20px;
        @include sp{
            margin-bottom: 10px;
        }
        dt{
            background-color: #138576;
            padding: 14px 20px;
            display: flex;
            @include sp{
                padding: 12px 10px;
                align-items: center;
                
            }
            p{
                font-size: 2.8rem;
                color: #fff;
                font-weight: 700;
                letter-spacing: 0.05rem;
                line-height: 46px;
                font-family: $go;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 23px;
                    width: calc(100% - 70px);
                }
                &:first-child{
                    border-right: 2px solid #fff;
                    margin-right: 17px;
                    @include sp{
                        font-size: 1.4rem;
                        width: 60px;
                        margin-right: 10px;
                    }
                }
            }
            .num{
                width: 51px;
                line-height: 51px;
                background-color: #fff;
                border-radius: 50%;
                text-align: center;
                color: #138576;
                font-size: 2.8rem;
                display: inline-block;
                margin-right: 20px;
                margin-left: 10px;
                @include sp{
                    width: 25px;
                    line-height: 25px;
                    margin-left: 8px;
                    font-size: 1.4rem;
                    margin-right: 0;
                }
            }
        }
        dd{
            font-size: 1.8rem;
            line-height: 32px;
            letter-spacing: 0.05rem;
            padding: 30px;
            border: 2px solid #138576;
            border-radius: 0 0 5px 5px;
            @include sp{
                font-size: 1.4rem;
                line-height: 24px;
                border: 1px solid #138576;
                padding:7px 15px 13px;
            }
            span{
                font-weight: 700;
                background-color: #FFF9C2;
            }
        }
    }
    .sec07__box{
        margin-bottom: 80px;
        @include sp{
            margin-bottom: 40px;
            &::after{
                display: block;
                width: 92px;
                height: 80px;
                top: -14px;
                right: 0;
            }
        }
    }
    &__nav{
        max-width: 870px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        background-color: #F1F1F1;
        border-radius: 5px;
        padding: 50px;
        @include sp{
            padding: 15px;
        }
        &__img{
            width: 243px;
            border-radius: 10px;
            box-shadow: 0 0 19px rgba(#000,0.3);
            @include sp{
                width: 100px;
                height: fit-content;
            }
            img{
                max-width: 100%;
                border-radius: 10px;
            }
        }
        &__content{
            width: calc(100% - 234px);
            padding-left:30px;
            padding-top: 10px;
            @include sp{
                width: calc(100% - 100px);
                padding-left:10px;
                padding-top: 0;
            }
            h3{
                font-size: 2.6rem;
                line-height: 38px;
                color: $text;
                letter-spacing: 0.05rem;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 23px;
                }
            }
            p{
                font-size: 1.8rem;
                line-height: 32px;
                letter-spacing: 0.05rem;
                color: $text;
                margin-top: 20px;
                margin-bottom: 20px;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 19px;
                    margin-top: 10px;
                }
            }
            a{
                font-size: 1.8rem;
                letter-spacing: 1px;
                color: #128E7C;
                text-decoration: underline;
                display: table;
                margin-left: auto;
                @include sp{
                    font-size: 1.4rem;
                    position: relative;
                    padding-left: 15px;
                    line-height: 19px;
                }
                img{
                    margin-right: 7px;
                    @include sp{
                        position: absolute;
                        left: 0;
                        top: 5px;
                        width: 8px;
                    }
                }
            }
        }
    }
    .c-tt01{
        margin-bottom: 58px;
        @include sp{
            margin-bottom: 15px;
        }
    }
    .sec07__box__tt {
        @include sp{
            text-align: left;
            position: relative;
            padding-left: 45px;
            img{
                width: 35px;
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
    }
}
.list_toku{
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(#000,0.1);
    padding: 70px 60px;
    @include sp{
        padding: 20px 15px;
    }
    li{
        padding-bottom: 40px;
        border-bottom: 1px dashed #8893a4;
        margin-bottom: 40px;
        @include sp{
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        &:last-child{
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
    dl{
        display:flex;
        @include sp{
            flex-direction: column;
        }
        dt{
            width: calc(100% - 320px);
            padding-right: 40px;
            @include sp{
                width: 100%;
                padding-right: 0;
                margin-bottom: 15px;
            }
        }
        dd{
            width: 320px;
            @include sp{
                width: 100%;
                padding: 0 40px;
            }
            img{
                width: 100%;
            }
        }
    }
    &__step{
        font-size: 1.8rem;
        color: #fff;
        display: table;
        font-weight: 700;
        line-height: 36px;
        width: 135px;
        border-radius: 18px;
        background-color: #EC871D;
        text-align: center;
        margin-bottom: 15px;
        @include sp{
            font-size: 1.1rem;
            border-radius: 9px;
            line-height: 18px;
            width: 68px;
            margin-bottom: 8px;
        }
    }
    &__tt{
        font-size: 2.6rem;
        line-height: 42px;
        font-weight: 700;
        color: #EC871D;
        font-family: $go;
        margin-bottom: 10px;
        @include sp{
            font-size: 1.8rem;
            line-height: 21px;
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 34px;
        letter-spacing: .05rem;
        color: $text;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
        }
    }
}
.t_sec06{
    background-color: #fffaeb;
    padding-top: 80px;
    padding-bottom: 70px;
    @include sp{
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .list_toku{
        margin-bottom: 70px;
        @include sp{
            margin-bottom: 20px;
        }
    }
    .c-tt01{
        margin-bottom: 58px;
        @include sp{
            margin-bottom: 20px;
        }
    }
    .btn_01{
        
        @media (min-width: 769px){
            line-height: 85px;
            font-size: 2.4rem;
            max-width: 494px;
        }
    }
}
.t_sec09{
    margin-bottom: 120px;
    @include sp{
        margin-bottom: 20px;
    }
    .s_sec03__top{
        width: 352px;
        @include sp{
            width: 170px;
        }
        &::after{
            display: none;
        }
    }
    .nav_03{
        flex-wrap: wrap;
        li{
            margin-bottom: 25px;
            &:nth-child(4n){
                margin-right: 0;
            }
        }
        &__img{
            //box-shadow: none;
        }
        &__tt{
            @include sp{
                font-weight: normal;
                font-size: 1.4rem;
            }
        }
    }
}
  