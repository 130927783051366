.voice{
    
}
.v_sec01{
    padding-top: 80px;
    padding-bottom: 120px;
    @include sp{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__tt{
        font-size: 2.4rem;
        color: $text;
        line-height: 52px;
        letter-spacing: 1px;
        font-weight: 700;
        text-align: center;
        font-family: $go;
        margin-bottom: 70px;
        @include sp{
            font-size: 1.8rem;
            text-align: left;
            line-height: 30px;
            padding-bottom: 22px;
            border-bottom: 1px solid rgb(219, 219, 219);
            margin-bottom: 30px;
        }
    }
}
.lst_voice{
    @include sp{
        margin-bottom: 10px;
    }
    li{
        background-color: #f1f9ff;
        padding: 50px 60px;
        border-radius: 5px;
        @include bor(1px,#d8d8d8);
        margin-bottom: 40px;
        @include sp{
            padding: 20px 15px;
            margin-bottom: 10px;
        }
    }
    &__top{
        display: flex;
        margin-top: 40px;
        margin-bottom: 30px;
        @include sp{
            flex-direction: column;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        dt{
            width: 460px;
            @include sp{
                width: 100%;
            }
            img{
                width: 100%;
            }
        }
        dd{
            width: calc(100% - 460px);
            padding-left: 40px;
            @include sp{
                width: 100%;
                padding-left: 0;
                margin-top: 15px;
            }
        }
    }
    &__cat{
        font-size: 2rem;
        line-height: 38px;
        letter-spacing: 0.05rem;
        color: #fff;
        background-color: #cb9e27;
        display: inline-block;
        padding: 2px 25px;
        @include sp{
            font-size: 1.2rem;
            line-height: 19px;
            padding: 2px 12px;
              
        }
    }
    &__tt{
        font-size: 2rem;
        line-height: 38px;
        letter-spacing: 0.05rem;
        color: $text;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 25px;
        @include sp{
            font-size: 1.6rem;
            line-height: 19px;
            margin-top: 8px;
            margin-bottom: 10px;
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 38px;
        color: $text;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
        }
    }
    &__bottom{
        background-color: #fff;
        dt{
            font-size: 2.4rem;
            line-height: 28px;
            color: #05528b;
            font-weight: 700;
            padding: 20px;
            border-bottom: 1px solid rgb(216, 216, 216);   
            @include sp{
                font-size: 1.4rem;
                line-height: 14px;
                padding: 10px 15px;
            }
            img{
                margin-right: 15px;
                @include sp{
                    width: 19px;
                    margin-right: 8px;
                }
            }           
        }
        dd{
            display: flex;
            align-items: center;
            padding: 20px 20px 30px;
            @include sp{
                flex-direction: column;
                padding: 10px 15px;
            }
            p{
                font-size: 1.8rem;
                color: $text;
                line-height: 38px;
                width: calc(100% - 151px);
                padding-left: 25px;
                @include sp{
                    width: 100%;
                    padding-left: 0;
                    font-size: 1.6rem;
                    line-height: 28px;
                }
            }
        }
        &__img{
            width: 151px;
            @include sp{
                width: 75px;
                margin-bottom: 12px;
            }
            img{
                width: 100%;
            }
        }
    }
}

.v_c_blue {
    background-color: #59A3DB;
}
  
.v_c_black {
    background-color: #000;
}
  
.v_c_green {
    background-color: #688F0B;
}