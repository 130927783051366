.gomiyashiki {
    .i_sec07 {
        &.layout-01 {
            padding: 100px 0;
            background: #fffaeb;
            @media (max-width: 768px) {
                padding: 30px 0;
            }
            h3.sec07 {
                &__box {
                    &__tt {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 30px;

                        @media (max-width: 768px) {
                            text-align: left;
                            margin-bottom: 10px;
                            padding-left: 48px;
                            position: relative;
                        }
                        div {
                            width: calc(100% - 53px - 20px);
                        }
                    }
                }
            }
        }
        &.layout-03 {
            margin: 0;
            padding: 80px 0;
            background: #f9f9f9;
    
            @media (max-width: 768px) {
                padding: 30px 0;
            }
    
            h2.c-tt01 {
                @media (max-width: 768px) {
                    margin-bottom: 40px;
                }
            }
    
            ul.nav_seizen_02 {
                margin: auto;
                margin-top: -70px;
                display: flex;
                flex-wrap: wrap;
    
                @media (max-width: 768px) {
                    margin-top: -50px;
                }
    
                li {
                    width: 510px;
                    min-height: 532px;
                    margin-top: 70px;
                    margin-right: 60px;
                    margin-bottom: 0;
                    padding: 30px 0;
                    border: none;
                    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.15);
                    position: relative;
        
                    @media (max-width: 1140px) {
                        width: 48%;
                        margin-right: 4%;
                    }
        
                    @media (max-width: 768px) {
                        width: 100%;
                        min-height: initial;
                        margin-top: 50px;
                        margin-right: 0;
                        padding: 20px 0;
                        box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.1);
                    }
        
                    .nav_seizen_02_num {
                        width: calc(240px * 0.5);
                        height: calc(228px * 0.5);
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: center;
                        padding-bottom: 3px;
                        padding-left: 17px;
                        background: url(images/seizenseiri/s7_img91@2x.png) no-repeat center top / 100% auto;
                        position: absolute;
                        top: -34px;
                        left: 13px;
                        content: "";
            
                        @media (max-width: 768px) {
                            width: calc(240px * 0.3);
                            height: calc(228px * 0.3);
                            padding-left: 12px;
                            top: -20px;
                            left: 10px;
                        }
        
                        span {
                            color: #fff;
                            font-family: "Arial";
                            line-height: 100%;
                            display: block;
            
                            &:nth-of-type(1) {
                                font-size: 2rem;
                
                                @media (max-width: 768px) {
                                    font-size: 1.2rem;
                                }
                            }
            
                            &:nth-of-type(2) {
                                font-size: 6rem;
                                font-weight: bold;
                                margin-top: -3px;
            
                                @media (max-width: 768px) {
                                    font-size: 3.6rem;
                                }
                            }
                        }
                    }
    
                    &:nth-of-type(2n) {
                        margin-right: 0;
                    }
        
                    figure {
                        width: 450px;
                        height: 300px;
                        margin: auto;
                        border-radius: initial;
                        overflow: initial;
            
                        @media (max-width: 1140px) {
                            width: calc(100% - 30px * 2);
                            height: 26vw;
                        }
            
                        @media (max-width: 768px) {
                            width: 100%;
                            height: calc(466px * 0.5);
                            padding: 0 20px;
                        }
        
                        img {
                            width: 100%;
                        }
                    }
    
                    p.nav_seizen_02__title {
                        color: #ee771b;
                        font-size: 2.4rem;
                        font-weight: bold;
                        line-height: 150%;
                        padding: 30px 30px 0;
            
                        @media (max-width: 768px) {
                            font-size: 1.8rem;
                            padding: 20px 15px 0;
                        }
            
                        br.layout-01 {
                            display: none;
            
                            @media (max-width: 768px) {
                                display: block;
                            }
                        }
                    }
    
                    p.nav_seizen_02__text {
                        font-size: 1.8rem;
                        line-height: 150%;
                        padding: 20px 30px 0;
            
                        @media (max-width: 768px) {
                            font-size: 1.5rem;
                            line-height: 170%;
                            padding: 15px 15px 0;
                        }
                    }
                }
            }
        }
    }
    .i_sec03 {
        &.layout-03 {
            margin-bottom: 0;
            padding: 80px 0;
            background: #fff;
    
            @media (max-width: 768px) {
                padding: 30px 0;
            }
  
            .s_sec03__box {
                background-color: #fff;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
                padding: 50px;
                margin-top: 30px;
                border-radius: 10px;
        
                @media (max-width: 768px) {
                    margin-top: 20px;
                    padding: 15px;
                }
  
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row-reverse;
                    justify-content: space-between;
        
                    li:nth-of-type(1) {
                        width: calc(100% - 475px - 45px);
            
                        @media (max-width: 1024px) {
                            width: 100%;
                            max-width: 640px;
                            margin: auto;
                        }
            
                        @media (max-width: 768px) {
                            max-width: initial;
            
                            div {
                            max-width: 220px;
                            margin: auto;
                            }
                        }
                    }
        
                    li:nth-of-type(2) {
                        width: 475px;
            
                        @media (max-width: 1024px) {
                            width: 100%;
                            max-width: 640px;
                            display: flex;
                            align-items: center;
                            margin: 15px auto 0;
                        }
            
                        @media (max-width: 768px) {
                            max-width: initial;
                        }
        
                        img {
                            width: 100%;
                        }
                    }
                }
    
                p {
                    &.s_sec03__box__text02 {
                        height: 61px;
                        color: #fff;
                        font-size: 2.4rem;
                        font-weight: bold;
                        line-height: 57px;
                        text-align: center;
                        padding: 0 10px;
                        border-radius: 5px;
                        background: #06827e;
            
                        @media (max-width: 768px) {
                            height: 30px;
                            font-size: 1.2rem;
                            line-height: 29px;
                            border-radius: 3px;
                        }
                    }
    
                    &.s_sec03__box__text04 {
                        height: 32px;
                        color: #fff;
                        font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
                        font-size: 2rem;
                        font-weight: bold;
                        letter-spacing: 0.05rem;
                        line-height: 34px;
                        display: inline-block;
                        margin-top: 30px;
                        margin-bottom: 25px;
                        padding: 0 18px;
                        border-radius: 13px;
                        background: #ff2727;
        
                        @media (max-width: 768px) {
                            font-size: 1rem;
                            height: 16px;
                            line-height: 18px;
                            margin-top: 15px;
                            margin-bottom: 15px;
                            padding: 0 10px;
                            border-radius: 15px;
                        }
                    }
    
                    &.s_sec03__box__text05 {
                        color: #ff2727;
                        font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
                        font-size: 2rem;
                        font-weight: bold;
                        letter-spacing: 0.05rem;
                        line-height: 50px;
            
                        @media (max-width: 768px) {
                            font-size: 1rem;
                            line-height: 20px;
                        }
        
                        span {
                            &.ff_g {
                            font-family: "Arial", sans-serif;
                            font-size: 8rem;
            
                                @media (max-width: 768px) {
                                    font-size: 4rem;
                                }
                            }
            
                            &.fs_02 {
                                font-size: 4.6rem;
            
                                @media (max-width: 768px) {
                                    font-size: 2.3rem;
                                }
                            }
            
                            &.fs_03 {
                            font-size: 3.2rem;
            
                                @media (max-width: 768px) {
                                    font-size: 1.6rem;
                                }
                            }
                        }
                    }
    
                    &.s_sec03__box__text03 {
                        color: #ff2727;
                        font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
                        font-size: 2rem;
                        font-weight: bold;
                        letter-spacing: 0.05rem;
                        line-height: 28px;
                        margin-top: 3px;
            
                        @media (max-width: 768px) {
                            font-size: 1rem;
                            line-height: 20px;
                        }
                    }
                }
    
                .s_sec03__box_info {
                    margin-top: 50px;
        
                    @media (max-width: 768px) {
                        margin-top: 15px;
                    }
        
                    .s_sec03__box_info_title {
                        h3 {
                            font-size: 2.4rem;
            
                            @media (max-width: 768px) {
                                font-size: 1.5rem;
                                line-height: 170%;
                            }
            
                            br {
                                display: none;
                
                                @media (max-width: 768px) {
                                    display: block;
                                }
                            }
            
                            strong {
                                color: #ff2727;
                                font-size: 2.6rem;
                                position: relative;
            
                                @media (max-width: 768px) {
                                    font-size: 1.8rem;
                                }
            
                                &:before {
                                    width: 100%;
                                    height: 1px;
                                    background: #ff2727;
                                    position: absolute;
                                    bottom: -5px;
                                    left: 0;
                                    content: "";
                
                                    @media (max-width: 768px) {
                                        bottom: -1px;
                                    }
                                }
                            }
                        }
                    }
        
                    .s_sec03__box_info_message {
                        margin-top: 40px;
            
                        @media (max-width: 768px) {
                            margin-top: 15px;
                        }
            
                        p {
                            font-size: 1.8rem;
                            line-height: 180%;
            
                            @media (max-width: 768px) {
                                font-size: 1.5rem;
                                line-height: 170%;
                            }
            
                            strong {
                                background-color: #fff9c2;
                            }
                        }
                    }
                }
    
                .s_sec03__link {
                    margin-top: 30px;
        
                    @media (max-width: 768px) {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
