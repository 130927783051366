.c_nav_drop{
    display: flex;
    @include sp{
        margin-top: 5px;
    }
    &__button{
        width: 120px;
        height: 66px;
        border-radius: 5px;
        background-image: linear-gradient( to top, rgb(19,126,113) 0%, rgb(16,182,151) 100%);
        font-size: 1.8rem;
        color: #fff;
        text-align: center;
        line-height: 60px;
        position: relative;
        box-shadow: 0 6px 0 #014239;
        margin-right: 10px;
        font-weight: 700;
        @include sp{
            font-size: 1.6rem;
            height: 39px;
            line-height: 34px;
            box-shadow: 0 2px 0 #014239;
            width:22% ;
            margin-right: 4%;
            &:last-child{
                margin-right: 0;
            }
        }
        &::before{
            content: '';
            width: 11px;
            height: 6px;
            background-image: url('images/common/icon_arrow.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 50%;
            bottom: 5px;
            transform: translateX(-50%) rotate(90deg);
            @include sp{
                width: 4px;
                height: 7px;
                bottom: 3px;
            }
        }
    }
    &__gr{
        //display: none;
        margin-top: 30px;
        @include sp{
            margin-top: 12px;
        }
    }
    &__content{
        margin-bottom: 2px;
        @include sp{
            margin-bottom: 2px;
        }
        &.current{
            .c-tab__gr{
                display: block;
            }
        }
    }
    &__rows{
        margin-bottom: 20px;
        @include sp{
            margin-bottom: 10px;
        }
        dt{
            font-size: 2.2rem;
            font-weight: 700;
            color:#91763D ;
            letter-spacing: 1px;
            line-height: 34px;
            margin-bottom: 10px;
            @include sp{
                font-size: 2rem;
                line-height: 1;
            }
        }
        dd{
            padding-left: 20px;
            line-height: 46px;
            @include sp{
                padding-left: 0;
            }
            a{
                font-size: 1.8rem;
                letter-spacing: 1px;
                color: $text;
                line-height: 27px;
                display: inline-block;
                margin-left: 23px;
                position: relative;
                margin-right: 18px;
                padding-bottom: 5px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 18px;
                    margin-bottom: 15px;
                }
                &:hover{
                    border-bottom: 0;
                }
                &::before{
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: url('images/common/icon_orange.png') no-repeat center;
                    background-size: contain;
                    position: absolute;
                    left: -23px;
                    top: 50%;
                    @include tranY(-50%);
                    @include sp{
                        width: 15px;
                        height: 15px;
                    }
                }
                
            }
        }
    }
}
.c_nav_drop__content .title_head.js_dropdown_pc{
    cursor: pointer;
    position: relative;
    &::before{
        content: '';
        width: 166px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        left: 15px;
        bottom: 5px;
        @include sp{
            width: 133px;
        }
    }
}

.c_nav_drop__content .title_head.active::before{
    display: none;
}
 .c_nav_drop__gr.none{
    display: none;
}
  
.c_nav_drop__gr.open{
    display: block;
    margin-bottom: 40px;
    @include sp{
        margin-bottom: 20px;
    }
}

.c_nav_drop__rows dd a[href*="area"]{
    padding-bottom: 2px;
    border-bottom: 1px solid #222222;
}

.c_js-accordion {
    &__list {
        padding: 18px 4px;
        border-bottom: 1px solid $brand-color-green;
        @include mq(lg) {
            padding: 28px;
        }
        &:hover {
            cursor: pointer;
        }
    }
    &__title {
        position: relative;
        font-size: 17px;
        @include mq(lg) {
            font-size: 24px;
        }
        &::after {
            content: '+';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 17px;
            height: 17px;
            font-weight: bold;
            @include mq(lg) {
                width: 24px;
                height: 24px;
            }
        }
    }
    &__content {
        margin-top: 10px;
        @include mq(lg) {
            margin-top: 18px;
        }
    }
}
