.sitemap{
    &__content{
        padding-top: 60px;
        border-bottom: 1px solid #C7C7C7;
        padding-bottom: 50px;
        @include sp{
            padding-bottom: 10px;
            padding-top: 15px;
            border-bottom: 0;
            position: relative;
            &::before{
                content: '';
                width: calc(100% - 20px);
                height: 1px;
                background-color: #C7C7C7;
                position: absolute;
                left: 10px;
                bottom: 0;
            }
        }
        .c_link_top{
            margin-bottom: 35px;
            @include sp{
                margin-bottom: 30px;
            }
        }
        &__dl{
            margin-bottom: 50px;
            @include sp{
                margin-bottom: 15px;
            }
            dd{
                padding-left: 20px;
                @include sp{
                    padding-left: 0;
                    .c_link_01.u_mt20{
                        margin-top: 0;
                    }
                }
                
            }
        }
        .c_text01--st01{
            text-align: left;
            margin-bottom: 80px;
            @include sp{
                padding: 0 7px;
                margin-bottom: 35px;
            }
        }
    }
}
