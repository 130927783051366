.urgency{
    .s_sec01{
        padding-top: 70px;
        margin-bottom: 70px;
        @include sp{
            padding-top: 15px;
            margin-bottom: 30px;
        }
        &__text{
            font-size: 2.4rem;
            font-weight: bold;
            font-family: $go;
            text-align: center;
            line-height: 52px;
            letter-spacing: 1px;
            margin-bottom: 60px;
            @include sp{
                font-size: 1.6rem;
                line-height: 28px;
                text-align: left;
            }
            span{
                color:#FF0000 ;
                text-decoration: underline;
            }
        }
    }
    .s_sec02{
        padding-top: 85px;
        background-color: #FFFBF2;
        @include sp{
            padding-top: 25px;
        }
        .list_01{
            background-image: url('images/urgency/s2_bg.png');
            background-color: transparent;
            border: 0;
            padding-top: 70px;
            padding-bottom: 40px;
            background-size: 100% 100%;
            @include sp{
                background-image: url('images/urgency/s2_bg_sp@2x.png');
                background-size: 100% 100%;
                padding-top: 33px;
                padding-bottom: 117px;
            }
            &::before{
                border-left: 46px solid transparent;
                border-right: 46px solid transparent;
                border-top: 37px solid #818181;
                @include sp{
                    border-top: 20px solid #818181;
                    border-left: 23px solid transparent;
                    border-right: 23px solid transparent;
                    bottom: -20px;
                }
            }
            &:after{
                content: '';
                width: 352px;
                height: 302px;
                background: url('images/urgency/s2_icon.png') no-repeat center;
                background-size: contain;
                position: absolute;
                bottom: 0;
                right: -115px;
                @include sp{
                    width: 130px;
                    height: 113px;
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            li{
                font-size: 2.2rem;
                font-family: $yu;
                color: #022222;
                @include sp{
                    font-size: 1.6rem;
                    margin-bottom: 10px;
                    padding-left: 20px;
                }
            }
        }
        &__flex{
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            @include sp{
                display: block;
                margin-top: 35px;
            }
        }
        &__img{
            text-align: center;
            @include sp{
                width: 186.5px;
                margin: 0 auto;
                margin-top: 20px;
            }
            img{
                max-width: 100%;
            }
        }
        &__text{
            font-size: 3rem;
            line-height: 68px;
            letter-spacing: 0.05rem;
            font-weight: 700;
            font-family: $go;
            margin-left: 50px;
            text-align: center;
            padding-top: 10px;
            @include sp{
                display: table;
                margin: 0 auto;
                font-size: 1.6rem;
                line-height: 27px;
            }
            span{
                font-size: 3.6rem;
                background-color: #fff9c2;
                @include sp{
                    font-size: 1.8rem;
                }
            }
        }
    }
    .s_sec03{
        padding-top: 95px;
        margin-bottom: 65px;
        @include sp{
            padding-top: 30px;
            margin-bottom: 30px;
        }
        .c-tt01{
            @include sp{
                position: relative;
                padding-left: 0;
            }
            img{
                margin-right: 15px;
                @include sp{
                    width: 33px;
                    position: absolute;
                    left:-40px;
                    top: 0px;
                }
            }
        }
        &__top{
            text-align: center;
            color: #fff;
            background-color:#FF6C00 ;
            border-radius: 22px;
            font-size: 3rem;
            font-family: $go;
            padding: 10px 38px;
            font-weight: bold;
            letter-spacing: 2px;
            display: table;
            margin: 0 auto;
            position: relative;
            margin-bottom: 30px;
            @include sp{
                font-size: 1.5rem;
                padding: 5px 15px;
                margin-bottom: 15px;
            }
            &::before{
                content: '';
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 16px solid #FF6C00;
                position: absolute;
                bottom: -16px;
                left: 50%;
                @include tranX(-50%);
                @include sp{
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-top: 6px solid #FF6C00;
                    bottom: -6px;
                }
            }
            &:after{
                content: '';
                width: 92px;
                height: 41px;
                // background: url('images/urgency/icon_01.png') no-repeat center;
                background-size: contain;
                position: absolute;
                right: -83px;
                top: 11px;
                @include sp{
                    width: 46px;
                    height: 20px;
                    right: -38px;
                    top: 7px;
                }
            }
        }
        &__nav{
            display: flex;
            margin-bottom: 120px;
            @include sp{
                flex-direction: column;
                margin-bottom: 20px;
            }
            li{
                position: relative;
                background-color: #F6FFFD;
                @include bor(1px, #138777);
                border-radius: 10px;
                width: 176px;
                padding-bottom: 30px;
                margin-right: 50px;
                @include sp{
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 70px;
                    padding-bottom: 20px;
                    border-radius: 5px;
                }
                &:last-child{
                    margin-right: 0;
                    @include sp{
                        margin-bottom: 0;
                    }
                }
            }
            li+li::before{
                content: '';
                width: 38px;
                height: 24px;
                background: url('images/urgency/icon_right.png') no-repeat center;
                background-size: contain;
                position: absolute;
                top: 50%;
                left:-45px;
                @include sp{
                    width: 31px;
                    height: 20px;
                    top: -55px;
                    left: 50%;
                    transform: translateX(-50%) rotate(90deg);
                }
            }
            &__num{
                border-style: solid;
                border-width: 2px;
                border-color: rgb(6, 131, 127);
                border-radius: 50%;
                background-image: linear-gradient( to top, rgb(19,126,113) 0%, rgb(16,182,151) 100%);
                position: relative;
                width: 103px;
                height: 103px;
                display: block;
                z-index: 300;
                font-size: 6rem;
                color: #fff;
                text-align: center;
                font-family: $ga;
                line-height: 103px;
                margin: 0 auto;
                margin-top: -38px;
                margin-bottom: 30px;
                @include sp{
                    width: 51px;
                    height: 51px;
                    line-height: 51px;
                    font-size: 3rem;
                    margin-top: -20px;
                    margin-bottom: 15px;
                    border-width: 0;
                    z-index: 2;
                }
                &::before{
                    content: '';
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    border: 1px solid #fff;
                    position: absolute;
                    left: 1px;
                    top: 1px;
                    border-radius: 50%;
                }
            }
            &__cont{
                text-align: center;
                @include sp{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 38px;
                        // display: table;
                        height: auto;
                    }
                }
            }
            &__text{
                font-size: 2.4rem;
                font-weight: bold;
                line-height: 32px;
                letter-spacing: 0.05rem;
                color: $text;
                margin-top: 30px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 1;
                    margin-top: 0;
                    height: auto;
                    margin-left: 12px;
                    br{
                        display: none;
                    }
                }
            }
        }
    }
    .s_sec04{
        padding-top: 85px;
        background: url(images/urgency/s4_background.png) center;
        background-repeat: repeat;
        background-size: contain;
        padding-bottom: 15px;
        @include sp{
            padding-top: 25px;
            padding-bottom: 5px;
        }
        &__dropdown{
            display:  flex;
            justify-content: space-between;
            margin-bottom: 80px;
            @include sp{
                flex-direction: column;
                margin-bottom: 25px;
            }
            li{
                border-radius: 10px;
                box-shadow: 0 5px 0 #004b49;
                background-image: linear-gradient( to top, rgb(19,126,113) 0%, rgb(16,182,151) 100%);
                width: 525px;
                position: relative;
                @include sp{
                    width: 100%;
                    box-shadow: 0 3px 0 #004b49;
                    margin-bottom: 10px;
                }
                &::before{
                    content: '';
                    width: calc(100% - 8px);
                    height: calc(100% - 8px);
                    border-radius: 10px;
                    @include bor(2px,#fff);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50% , -50%);
                    position: absolute;
                    z-index: 0;
                    @include sp{
                        @include bor(1px,#fff);
                        width: calc(100% - 4px);
                        height: calc(100% - 4px);
                    }
                }
                &::after{
                    content: '';
                    border-left: 17px solid transparent;
                    border-bottom: 17px solid #fff;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    @include sp{
                        border-left: 8px solid transparent;
                        border-bottom: 8px solid #fff;
                    }
                }
            }
            a{
                display: flex;
                flex-direction: column;
                height: 162px;
                justify-content: center;
                padding: 40px 30px;
                padding-right: 100px;
                z-index: 2;
                position: relative;
                @include sp{
                    padding: 20px 10px;
                    height: auto;
                }
            }
            &__text{
                font-size: 1.8rem;
                color: #fff;
                line-height: 20px;
                font-weight: bold;
                margin-bottom: 5px;
                @include sp{
                    font-size: 1.2rem;
                    line-height: 15px;
                    text-align: center;
                }
            }
            &__text02{
                font-size: 2.8rem;
                letter-spacing: 0.05rem;
                line-height: 46px;
                font-weight: bold;
                color: #fff;
                line-height: 37px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 23px;
                    text-align: center;
                }
                span{
                    color: #FCFF00;
                    font-size: 3.2rem;
                    @include sp{
                        font-size: 1.8rem;
                    }
                }
            }
            &__icon{
                position: absolute;
                right: 20px;
                top: 50%;
                @include tranY(-50%);
                @include sp{
                    display: none;
                }
            }
        }
        &__content{
            margin-bottom: 70px;
            @include sp{
                margin-bottom: 25px;
            }
        }
        &__text{
            font-size: 2.8rem;
            line-height: 46px;
            letter-spacing: 0.05rem;
            text-align: center;
            color: $text;
            margin-top: 45px;
            margin-bottom: 45px;
            font-weight: bold;
            font-family: $go;
            @include sp{
                font-size: 1.4rem;
                line-height: 21px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        &__nav{
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            @include sp{
                margin-top: 20px;
            }
            li{
                width: 350px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;
                @include bor(1px ,#CFA944);
                background-color: #fff;
                margin-bottom: 15px;
                margin-right: 15px;
                @include sp{
                    width: 48.5%;
                    margin-right: 3%;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    
                }
                &:nth-child(3n){
                    margin-right: 0;
                    @include sp{
                        margin-right: 3%;
                    }
                }
                &:nth-child(2n){
                    @include sp{
                        margin-right: 0;
                    }
                }
                p{
                    font-size: 1.8rem;
                    color:#BC9327 ;
                    font-weight: bold;
                    font-family: $go;
                    display: table;
                    margin: 0 auto;
                    @include sp{
                        font-size: 1.4rem;
                        line-height: 20px;
                        padding: 0 5px;
                    }
                }
                img{
                    max-width:100% ;
                    @include sp{
                        max-width: 52px;
                    }
                }
            }
        }
        &__box{
            background-color: #fff;
            box-shadow: 0 0 15px rgba(#000000, 0.06);
            padding: 50px;
            margin-top: 30px;
            border-radius: 10px;
            @include sp{
               padding: 15px;
               border-radius: 5px;
               margin-top: 15px;
            }
            &__nav{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include sp{
                    flex-direction: column-reverse;
                }
                &__img{
                    width: 475px;
                    @include sp{
                        width: 100%;
                        margin-bottom: 15px;
                    }
                    img{ 
                        max-width: 100%;
                        @include sp{
                            width: 100%;
                        }
                    }
                }
            }
            &__text01{
                font-size: 2.8rem;
                font-weight: bold;
                line-height:45px ;
                background-color: #004240;
                border-radius: 22px;
                display: table;
                color: #fff;
                width: 194px;
                text-align: center;
                position: relative;
                font-family: $go;
                @include sp{
                    font-size: 1.4rem;
                    width: 97px;
                    line-height: 28px;
                    border-radius: 11px;
                    margin: 0 auto;
                }
                &::before{
                    content: '';
                    border-left: 11px solid transparent;
                    border-right: 11px solid transparent;
                    border-top: 9px solid #004240;
                    position: absolute;
                    bottom: -9px;
                    left: 50%;
                    transform: translateX(-50%);
                    @include sp{
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-top: 5px solid #004240;
                        bottom: -5px;
                    }
                }
            }
            &__text02{
                font-size: 3.2rem;
                font-weight: bold;
                line-height: 38px;
                color: #004240;
                margin-top: 25px;
                margin-bottom: 10px;
                font-family: $go;
                @include sp{
                    font-size: 2rem;
                    line-height:24px;
                    text-align: center;
                    margin-bottom: 5px;
                    margin-top: 12px;
                }
            }
            &__text03{
                font-size: 1.8rem;
                font-weight: bold;
                letter-spacing: 0.05rem;
                line-height: 28px;
                color: $text;
                font-family: $go;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 15px;
                    text-align: center;
                    
                }
            }
            &__text04{
                font-size: 2.2rem;
                font-weight: bold;
                letter-spacing: 0.05rem;
                line-height: 38px;
                color: #FF2727;
                font-family: $go;
                margin-top: 20px;
                @include sp{
                    font-size: 1.2rem;
                    font-style: italic;
                    text-align: center;
                    text-shadow: 1.5px 0 0 #FEE564, -1.5px 0 0 #FEE564, 0 1.5px 0 #FEE564, 0 -1.5px 0 #FEE564, 1.5px 1.5px #FEE564, -1.5px -1.5px 0 #FEE564, 1.5px -1.5px 0 #FEE564, -1.5px 1.5px 0 #FEE564;
                    letter-spacing: 1px;
                    line-height: 1;
                    margin-top: 8px;
                    position: relative;
                    
                    
                }
                
            }
            &__text05{
                font-size: 2.8rem;
                font-weight: bold;
                letter-spacing: 0.05rem;
                line-height: 50px;
                color: #FF2727;
                font-family: $go;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 1;
                    text-align: center;
                    text-shadow: 1.5px 0 0 #FEE564, -1.5px 0 0 #FEE564, 0 1.5px 0 #FEE564, 0 -1.5px 0 #FEE564, 1.5px 1.5px #FEE564, -1.5px -1.5px 0 #FEE564, 1.5px -1.5px 0 #FEE564, -1.5px 1.5px 0 #FEE564;
                    background: linear-gradient(transparent 60%, #FEE564 40%);
                    background-position: bottom;
                    margin: 0 auto;
                    padding: 0 7px;
                    display: table;
                    position: relative;
                    font-style: italic;
                    margin-top: 5px;
                }
                &::before{
                    @include sp{
                        content: '';
                        width: 24px;
                        height: 25px;
                        background-image: url('images/urgency/icon_l.png');
                        background-size: contain;
                        position: absolute;
                        left: -13px;
                        top: -12px;
                    }
                }
                &::after{
                    @include sp{
                        content: '';
                        width: 24px;
                        height: 25px;
                        background-image: url('images/urgency/icon_r.png');
                        background-size: contain;
                        position: absolute;
                        right: -13px;
                        top: -12px;
                    }
                }
                .ff_g{
                    font-family: $ar;
                    font-size: 7rem;
                    @include sp{
                        font-size: 3.5rem;
                    }
                }
                .fs_02{
                    font-size: 4rem;
                    @include sp{
                        font-size: 2rem;
                    }
                }
            }
            &__text06{
                font-size: 2.2rem;
                font-weight: bold;
                color: #06837F;
                letter-spacing: 0.05rem;
                line-height: 1;
                margin-top: 20px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 19px;
                    text-align: center;
                    margin-top: 15px;
                }
                img{
                    margin-right: 7px;
                    @include sp{
                        width: 14px;
                        margin-right: 3px;
                    }
                }
            }
            .list_01{
                background: none;
                padding: 0;
                border: 0;
                margin-top: 20px;
                @include sp{
                    margin-top: 10px;
                }
                &::before{
                    display: none;
                }
                li{
                    margin-bottom: 5px;
                    font-weight: 400;
                    @include sp{
                        margin-bottom: 10px;
                        padding-right: 20px;
                        padding-left: 20px;
                        line-height: 23px;
                    }
                }
            }
        }
        &__tt{
            font-size: 2rem;
            font-weight: bold;
            line-height: 1;
            font-family: $go;
            text-align: center;
            color:#FF2727 ;
            margin-top: 35px;
            margin-bottom: 15px;
            @include sp{
                font-size: 1.4rem;
            }
            img{
                margin-right: 5px;
                @include sp{
                    width: 12px;
                }
            }
        }
        &__tt02{
            font-size: 3.2rem;
            line-height: 42px;
            font-weight: 700;
            letter-spacing: 0.05rem;
            color: #004240;
            text-align: center;
            margin-top: 35px;
            position: relative;
            padding-bottom: 55px;
            @include sp{
                font-size: 1.6rem;
                line-height: 21px;
                padding-bottom: 25px;
                margin-top: 10px;
            }
            &::before{
                content: '';
                border-left: 45px solid transparent;
                border-right: 45px solid transparent;
                border-top: 31px solid #004240;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                @include sp{
                    border-left: 22px solid transparent;
                    border-right: 22px solid transparent;
                    border-top: 15px solid #004240;
                }
            }
        }
        &__phone{
            display: flex;
            justify-content: center;
            margin-top: 20px;
            @include sp{
                flex-direction: column;
                align-items: center;
                background-image: linear-gradient( to top, rgb(223,187,91) 0%, rgb(238,209,133) 100%);
                border-radius: 5px;
                @include bor(1px,rgb(223, 187, 91));
                padding: 10px;
                box-shadow: 0 3px 0 #a27f23;
                position: relative;
                max-width: 305px;
                margin: 15px auto 0;
            }
            img{
                max-width: 100%;
                margin-right: 10px;
            }
            &__top{
                font-size: 1.4rem;
                text-align: center;
                line-height: 1;
                color: $text;
                font-weight: bold;
                margin-bottom: 8px;
                display: none;
                @include sp{
                    display: block;
                }
                span{
                    color: #FF0024;
                }
            }
            &__num{
                font-size: 5.8rem;
                font-family: $ba;
                font-weight: bold;
                display: block;
                color: #FF2727;
                @include sp{
                    font-size: 3.4rem;
                    color: $text;
                    position: relative;
                    padding-left: 35px;
                    background-image: url('images/common/icon_phone01.png');
                    background-repeat: no-repeat;
                    background-size: 31px 31px;
                    background-position: left top;
                    line-height: 1;
                }
            }
            &__text{
                font-size: 2.2rem;
                letter-spacing: 0.03rem;
                font-style: italic;
                color: #FF2727;
                font-weight: 700;
                @include sp{
                    font-size: 1.4rem;
                    color: $text;
                    margin-top: 5px;
                    font-style: normal;
                    text-align: center;
                    display: block;
                }
            }
            &__href{
                display: none;
                @include sp{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &__bot{
            max-width: 880px;
            margin: 0 auto;
            margin-top: 45px;
            @include sp{
                margin-top: 17px;
            }
            .icon{
                text-align: center;
                margin-bottom: 45px;
                @include sp{
                    margin-bottom: 15px;
                    img{
                        width: 4px;
                    }
                }
            }
            &__tt{
                font-size: 2.4rem;
                line-height: 46px;
                color: $text;
                letter-spacing: 0.05rem;
                text-align: center;
                font-weight: 700;
                font-family: $go;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 20px;
                }
            }
            &__tt02{
                font-size: 3.2rem;
                line-height: 46px;
                color: #FF2727;
                letter-spacing: 0.05rem;
                text-align: center;
                font-weight: 700;
                margin-bottom: 20px;
                font-family: $go;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 23px;
                    margin-bottom: 15px;
                }
            }
            &__img{
                margin-top: 45px;
                margin-bottom: 35px;
                position: relative;
                @include sp{
                    max-width: 450px;
                    margin: 15px auto 25px;
                }
                &__text{
                    color: $text;
                    font-weight: 700;
                    position: absolute;
                    top: 60px;
                    left: 45px;
                    font-size: 2.2rem;
                    line-height: 36px;
                    color:#022222 ;
                    @include sp{
                        font-size: 1.4rem;
                        line-height: 23px;
                        left: 24px;
                        top: 14px;
                    }
                    @media screen and (max-width:420px) {
                        font-size: 3.7vw;
                        line-height: 19px;
                        left: 6px;
                        top: 2vh;
                    }
                }
                img{
                    max-width: 100%;
                }
            }
        }
    }
    .s_sec05{
        padding-top: 85px;
        padding-bottom: 120px;
        @include sp{
            padding-top: 25px;
            padding-bottom: 15px;
        }
        &__dl{
            background-color: #F5EFDC;
            box-shadow: 0 0 15px rgba(#000000, 0.1);
            padding: 50px;
            display: flex;
            margin-bottom: 30px;
            border-radius: 10px;
            @include sp{
                flex-direction: column;
                padding: 20px 17px;
                border-radius: 5px;
                margin-bottom: 15px;
                box-shadow: 0 0 7px rgba(#000000, 0.1);
            }
            dt{
                width: calc(100% - 475px);
                padding-right: 30px;
                @include sp{
                    width: 100%;
                    padding-right: 0;
                }
            }
            dd{
                width: 475px;
                @include sp{
                    width: 100%;
                    margin-top: 15px;
                    height: 141px;
                }
                img{
                    max-width: 100%;
                    @include sp{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            &__text01{
                font-size: 2.2rem;
                letter-spacing: 0.07rem;
                font-weight: bold;
                font-family: $go;
                width: 265px;
                background-color: #A5801D;
                border-radius: 22px;
                text-align: center;
                color: #fff;
                line-height: 45px;
                margin-bottom: 15px;
                @include sp{
                    font-size: 1rem;
                    border-radius: 11px;
                    width: 132px;
                    line-height: 23px;
                    margin-bottom: 8px;
                }
                span{
                    font-size: 2.6rem;
                    @include sp{
                        font-size: 1.3rem
                    }
                }
            }
            &__tt{
                font-size: 3.2rem;
                font-weight: bold;
                color: #A5801D;
                font-family: $go;
                line-height: 42px;
                letter-spacing: 0.05rem;
                margin-bottom: 25px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 21px;
                    margin-bottom: 10px;
                }
            }
            &__text02{
                font-size: 1.8rem;
                color: $text;
                line-height: 36px;
                font-family: $go;
                letter-spacing: 0.05rem;
                font-weight: 500;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 21px;
                }
            }
        }
    }
}
.tb_urgency{
    table{
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #CFA944;
        background-color: #fff;
    }
    th{
        font-size: 2rem;
        line-height: 38px;
        color: $text;
        background-color: #FFF3DD;
        padding: 5px ;
        border-collapse: collapse;
        border: 1px solid #CFA944;
        @include sp{
            font-size: 1.4rem;
            line-height: 19px;
        }
        &:nth-child(2){
            background-color: #F4DA79;
        }
    }
    td{
        font-size: 1.8rem;
        line-height: 38px;
        color: $text;
        text-align: center;
        padding: 5px 20px;
        border-collapse: collapse;
        border: 1px solid #CFA944;
        @include sp{
            font-size: 1.4rem;
            line-height: 19px;
            padding: 5px 10px;
        }
        &:nth-child(2){
            background-color: #FFFDEB;
            color: #E95D00;
            font-weight: bold;
            @include sp{
                font-size: 1.1rem;
            }
        }
        .ff_a{
            font-family: $ar;
            font-size: 2.4rem;
            @include sp{
                font-size: 1.6rem;
            }
        }
        .color_01{
            color: $text;
            font-weight: normal;
            @include sp{
                font-size: 1.2rem;
            }
        }
    }
    &.st01{
        table{
            border: 1px solid #b6b6b6;
        }
        th{
            border: 1px solid #b6b6b6;
            font-size:2.4rem;
            line-height: 34px;
            @include sp{
                font-size: 1.4rem;
                line-height: 19px;
            }
            &:nth-child(2){
                background-color:#FFF3DD ;
            }
        }
        td{
            line-height: 32px;
            border: 1px solid #b6b6b6;
            font-size: 2.4rem;
            padding: 8px 20px;
            @include sp{
                line-height: 23px;
                font-size: 1.4rem;
                padding: 5px;
            }
            &:nth-child(2){
                background-color: #fff;
                color: #FF2727;
            }
            &:first-child{
                text-align: left;
            }
            .ff_b{
                font-size: 2.4rem;
                @include sp{
                    font-size: 1.6rem;
                }
            }
        }
    }
}
