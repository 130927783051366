.trade {
	padding-top: 30px;
	padding-bottom: 40px;
    position: relative;
	background: #f5efdf;
    @include mq(lg) {
        padding-top: 80px;
    }
    .nav_ihin_03 {
        li {
            padding-left: 5px;
            padding-right: 5px;
            background: #fff;
            @include mq(lg) {
                padding-top: 10px;
                padding-bottom: 20px;
            }
        }
        &__img {
            @include mq(lg) {
                margin: 0 auto;
                width: 140px;
            }
        }
        &__text {
            font-size: 12px;
            line-height: 14px;
            @include mq(lg) {
                margin-top: 12px;
                font-size: 20px;
            }
            small {
                font-size: 8px;
                @include mq(lg) {
                    font-size: 14px;
                }
            }
        }
    }
}
