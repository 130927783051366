.c_text01{
    font-size: 1.8rem;
    color: $text;
    line-height: 36px;
    letter-spacing: 0.05rem;
    text-align: center;
    @include sp{
        font-size: 1.6rem;
        line-height: 25px;
        text-align: left;
    }
}
.c_text02 {
    font-size: 1.5rem;
    line-height: 25px;
    @include mq(md) {
        font-size: 1.6rem;
        line-height: 29px;
    }
}
.text_02{
    font-size: 2.2rem;
    color: $text;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.05rem;
    text-align: center;
    @include sp{
        font-size: 1.6rem;
        line-height: 25px;
    }
}

.section {
    .lead {
        font-size: 16px;
        line-height: 28px;
        @include mq(lg) {
            font-size: 18px;
            line-height: 38px;
        }
        .text_color {
            color: #ff4141;
            font-weight: 700;
            background-color: #fff9c2;
        }
    }
}
