.complete {
  &__outline {
    margin-top: 30px;
    @media (max-width: 1024px) {
      margin-top: 10px;
    }
    &__content {
      p {
        padding: 10px;
        font-size: 18px;
        line-height: 32px;
      }
      @media (max-width: 1024px) {
        p {
          font-size: 16px;
        }
      }
    }
    &__message {
      padding: 10px;
      margin-bottom: 30px;
      p:nth-child(1) {
        margin-bottom: 10px;
      }
    }
    &__mail-trouble {
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 25px;
      background: #fcf5e0;
      strong {
        display: block;
        margin-bottom: 15px;
        font-weight: bold;
        color: $orange;
      }
    }
    @media (max-width: 1024px) {
      &__mail-trouble {
        margin-right: 10px;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
}
