*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    // font-size: 16px;
    // line-height: 1.7;
    font-family: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo,
        sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}
input {
    margin: 0;
    padding: 0;
}
textarea {
    padding: 0;
    font: inherit;
    color: inherit;
}
button {
    font: inherit;
    padding: 0;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
}
