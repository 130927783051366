@charset "UTF-8";
// レイアウト用css for Layout
// Can use id, If you use class Please adding prefix .l- 
/*------------------------------------------------------------
	Layout
------------------------------------------------------------*/
body {
}
.wrapper{
	margin: 0 auto;
	width: 100%;
	//min-width: 1080px;
	&.fixed{
		position: relative;
		height: 100vh;
		overflow: hidden;
	}
}
.container {
	margin: 0 auto;
	width: 100%;
	max-width: 1120px;
	padding: 0 20px;
	@include sp{
		padding: 0 7.5px;
	}
}

main{
	display: block;
	overflow: hidden;
}
