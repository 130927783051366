.faq{

}
.fa_sec01{
    padding-top: 80px;
    background-color: #f4f3f1;
    padding-bottom: 85px;
    @include sp{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &__tt{
        font-size: 2.6rem;
        color: $text;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 25px;
        @include sp{
            font-size: 2rem;
            line-height: 1;
            margin-bottom: 10px;
        }
    }
    &__search{
        display: flex;
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 20px;
        }
        input{
            appearance: none;
            font-size: 2rem;
            padding: 0 20px;
            width: calc(100% - 200px);
            height: 62px;
            @include bor(1px,#022222);
            border-radius: 5px 0 0 5px;
            @include sp{
                font-size: 1.4rem;
                padding: 0 7px;
                height: 31px;
                width: calc(100% - 74px);
                border-radius: 3px 0 0 3px;
            }
        }
        button{
            width: 200px;
            display: block;
            cursor: pointer;
            background-color: #111111;
            border-radius:  0 5px 5px 0;
            color: #fff;
            font-size: 2rem;
            background-image: url('images/faq/icon_magnifier.png');
            background-repeat: no-repeat;
            background-position: left 55px center;
            background-size: 24px 24px;
            padding-left: 20px;
            font-weight: 700;
            @include sp{
                font-size: 1.2rem;
                background-size: 13px 13px;
                width: 72px;
                height: 31px;
                background-position: left 12px center;
                padding-left: 10px;
                border-radius:  0 3px 3px 0;
            }
        }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a9a9a9;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #a9a9a9;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #a9a9a9;
      }
}
.fa_sec02{
    padding: 80px 0 60px;
    @include sp{
        padding: 30px 0;
    }
    &__content{
        margin-bottom: 60px;
        @include sp{
            margin-bottom: 30px;
        }
        .f_sec04__tt{
            @include sp{
                font-size: 2rem;
            }
        }
    }
    .flow_qa dd{
        background-color: #f4f3f1;
    }
}