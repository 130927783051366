/* text-align - utility
--------------------------------------------------------- */
.u_taL {
    text-align: left !important;
}

.u_taC {
    text-align: center !important;
}

.u_taR {
    text-align: right !important;
}

/* text-decoration - utility
--------------------------------------------------------- */
.u_fwN {
    font-weight: normal !important;
}

.u_fwB {
    font-weight: bold !important;
}

.u_tdU {
    text-decoration: underline !important;
}

/* text-truncate - utility
--------------------------------------------------------- */
.u_tt {
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}

/* color - utility
--------------------------------------------------------- */
.u_cRed {
    // color: $brand-color-red !important;
}

.u_cBlue {
    // color: $brand-color !important;
}

.u_cBluePrimary {
    // color: $brand-color-primary !important;
}

.u_cGreen {
    // color: $brand-color-green !important;
}

.u_cDgray {
    // color: $brand-color-darkgray !important;
}

.u_cBlack {
    // color: $font-color-black !important;
}

/* line-height - utility
--------------------------------------------------------- */
$num: 1;

@for $i from 0 through 30 {
    .u_lh#{$i * $num} {
        line-height: #{$i * $num}px !important;
    }
}

/* font-size - utility
--------------------------------------------------------- */
$num: 1;

@for $i from 0 through 100 {
    .u_fz#{$i * $num} {
        font-size: #{$i * $num}px !important;
    }
}
