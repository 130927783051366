.company{
    &__content{
        padding-top: 40px;
        padding-bottom: 70px;
        @include sp{
            padding-bottom: 25px;
        }
    }
    &__table{
        margin-bottom: 85px;
        @include sp{
            margin-bottom: 30px;
        }
        table{
            width: 100%;
            border-collapse: collapse;
        }
        th{
            font-size: 2rem;
            font-weight: 700;
            letter-spacing: 0.05rem;
            line-height: 38px;
            text-align: left;
            padding-bottom: 13px;
            width: 217px;
            border-bottom: 2px solid #CFA944;
            border-collapse: collapse;
            padding-top: 30px;
            @include sp{
                font-size: 1.4rem;
                line-height: 19px;
                border-bottom: 0;
                display: block;
                width: 100%;
                padding-top: 10px;
            }
        }
        td{
            font-size: 2rem;
            font-weight: 400;
            letter-spacing: 0.05rem;
            line-height: 38px;
            padding-bottom: 13px;
            border-bottom: 2px solid #CFA944;
            border-collapse: collapse;
            padding-top: 30px;
            @include sp{
                font-size: 1.4rem;
                line-height: 19px;
                display: block;
                width: 100%;
                padding-top: 0;
                border-bottom: 1px solid #CFA944;
            }
        }
    }
    &__dl{
        padding: 25px 20px;
        @include bor(2px,#CFA944);
        border-radius: 3px;
        margin-bottom: 110px;
        @include sp{
            padding: 12px 10px;
            margin-bottom: 60px;
        }
        dt{
            font-size: 2.6rem;
            line-height: 36px;
            letter-spacing: 0.05rem;
            font-weight: 700;
            color: $text;
            margin-bottom: 25px;
            @include sp{
                font-size: 1.6rem;
                line-height: 20px;
                margin-bottom: 10px;
            }
        }
        dd{
            font-size: 2rem;
            letter-spacing: 0.05rem;
            color: $text;
            @include sp{
                font-size: 1.4rem;
                line-height: 18px;
            }
        }
    }
    &__tt{
        font-size: 3.6rem;
        font-family: $go;
        font-weight: bold;
        color: #BC9327;
        line-height: 46px;
        letter-spacing: 0.05rem;
        text-align: center;
        margin-bottom: 55px;
        @include sp{
            font-size: 1.8rem;
            line-height: 23px;
            margin-bottom: 15px;
        }
    }
    &__map{
        width: 100%;
        height: 465px;
        margin-bottom: 35px;
        @include sp{
            height: 250px;
            margin-bottom: 20px;
        }
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    &__gr{
        width: 1040px;
        margin: 0 auto;
        margin-bottom: 80px;
        @include sp{
            width: 100%;
            margin-bottom: 50px;
            padding: 0 10px;
        }
    }
    &__text{
        font-size: 2rem;
        color: $text;
        line-height: 30px;
        @include sp{
            font-size: 1.4rem;
            line-height: 19px;
        }
    }
}