.top{
    &__kv{
        img{
            width: 100%;
        }
    }
    .sec01{
        padding-top: 25px;
        padding-bottom: 50px;
        background: url('images/top/s1_bg@2x.png') no-repeat;
        background-size: cover;
        background-position: center;
        @include sp{
            padding-top: 20px;
            background: #f3f6fa;
            padding-bottom: 25px;
        }
        &__h1 {
            margin-bottom:70px;
            text-align:center;
            font-size:2.0em;
            font-weight:normal;
            color:#696969;
            @include sp {
                margin-bottom: 40px;
                font-size: 1.6rem;
            }
        }
        &__tt{
            font-size: 4rem;
            line-height: 60px;
            letter-spacing: 0.05rem;
            text-align: center;
            color: $text;
            margin-bottom: 30px;
            @include sp{
                font-size: 2rem;
                line-height: 30px;
                margin-bottom: 20px;
            }
        }
        &__text{
            font-size: 1.8rem;
            line-height: 36px;
            font-weight: 700;
            text-align: center;
            padding-bottom: 50px;
            // border-bottom: 1px solid #BBBBBB;
            margin-bottom: 60px;
            @include sp{
                font-size: 1.6rem;
                line-height: 25px;
                text-align: left;
                font-weight: normal;
                margin-bottom: 15px;
                padding-bottom: 15px;
            }
        }
    }
    // 年末年始バナー
    .sec_between_01_and_02 {
        text-align: center;
        .newyear_information {
            margin-top: 30px;
            @media screen and (min-width: 768px) {
                margin-top: 100px;
            }
            img {
                width: 70%;
                @include sp {
                    width: 100%;
                }
            }
        }
    }
    .sec02{
        padding-top: 50px;
        // margin-bottom: 60px;
        @include sp{
            padding-top: 25px;
        }
        .list_01{
            margin-bottom: 83px;
            @include sp{
                margin-bottom: 30px;
            }
        }
        &__flex{
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            @include sp{
                display: block;
            }
        }
        &__img{
            text-align: center;
            @include sp{
                width: 186.5px;
                margin: 0 auto;
                margin-top: 20px;
            }
            img{
                max-width: 100%;
            }
        }
        &__text{
            font-size: 3rem;
            line-height: 68px;
            letter-spacing: 0.05rem;
            font-weight: 700;
            font-family: $go;
            margin-left: 50px;
            padding-top: 10px;
            @include sp{
                display: table;
                margin: 0 auto;
                font-size: 1.6rem;
                line-height: 27px;
            }
        }
    }
    .sec03{
        background: url('images/top/s3_bg.png') no-repeat center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 60px;
        @include sp{
            padding-top: 25px;
            padding-bottom: 25px;
        }
        .nav_01{
            margin-top: 65px;
            @include sp{
                margin-top: 20px;
            }
        }
    }
    .sec04{
        padding-top: 95px;
        padding-bottom: 100px;
        @include sp{
            padding: 25px 0;
        }
        .nav_02{
            margin-bottom: 30px;
            @include sp{
                margin-bottom: 0;
            }
        }
    }
    .sec05{
        background: url('images/top/s5_bg.png') no-repeat center;
        background-size: cover;
        padding-top: 90px;
        padding-bottom: 100px;
        @include sp{
            padding: 25px 0;
        }
        .nav_03{
            margin-bottom: 50px;
            @include sp{
                margin-bottom: 0;
            }
        }
    }
    .sec06{
        padding-top: 85px;
        padding-bottom: 90px;
        @include sp{
            padding: 25px 0;
        }
        &__box{
            padding:70px 80px 95px;
            background-color: #fff;
            box-shadow: 0 0 25px rgba(#000, 0.05) ;
            @include bor(2px,#E4E4E4);
            background: url('images/top/s6_box.png') no-repeat center;
            background-size: cover;
            margin-bottom: 60px;
            @include sp{
                background: #fff;
                box-shadow: none;
                padding: 0;
                border: 0;
                margin-bottom: 20px;
            }
        }
    }
    .sec07{
        padding-top: 85px;
        padding-bottom: 100px;
        background: url('images/top/s3_bg.png') no-repeat center;
        background-size: cover;
        @include sp{
            padding: 25px 0;
        }
        &__box{
            background-color: #fff;
            padding: 60px 80px;
            box-shadow: 0 0 25px rgba(#000, 0.05) ;
            border-radius: 10px;
            position: relative;
            @include sp{
                padding: 0;
                background: none;
                box-shadow: none;
            }
            &::after{
                // content: '';
                // width: 260px;
                // height: 190px;
                // background: url('images/top/s7_img01.png');
                // background-size: cover;
                // position: absolute;
                // background-repeat: no-repeat;
                // top:-35px ;
                // right: 80px;
                // @include sp{
                //     display: none;
                // }
            }
            &__tt{
                font-size: 3.2rem;
                letter-spacing: 0.05rem;
                line-height: 46px;
                color: $text;
                margin-bottom: 40px;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 28px;
                    text-align: center;
                    margin-bottom: 20px;
                }
                img{
                    vertical-align: sub;
                    @include sp{
                        width: 26px;
                    }
                }
            }
        }
        &__dl{
            margin-bottom: 30px;
            @include sp{
                margin-bottom: 10px;
            }
            dt{
                background-color: #9A8251;
                height: 76px;
                display: flex;
                align-items: center;
                padding-left: 18px;
                @include sp{
                    height: auto;
                    padding: 10px;
                }
            }
            &__num{
                font-size: 2rem;
                font-weight: 700;
                letter-spacing: 1px;
                color: #fff;
                text-align: center;
                padding-right: 18px;
                position: relative;
                @include sp{
                    font-size: 1rem;
                    padding-right: 10px;
                }
                &::after{
                    content: '';
                    width: 2px;
                    height: 53px;
                    background-color: #fff;
                    position: absolute;
                    right: 0;
                    top: 5px;
                    @include sp{
                        display: none;
                    }
                }
                span{
                    font-family: $ba;
                    display: block;
                    font-size: 3.9rem;
                    line-height: 1;
                    @include sp{
                        font-size: 1.9rem;
                        line-height: 1.5;
                    }
                }
            }
            &__tt{
                font-size: 2.2rem;
                color: #fff;
                font-weight: 700;
                padding-left: 20px;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 28px;
                    padding-left: 10px;
                    border-left: 1px solid #fff;
                }
                span{
                    font-size: 2.6rem;
                    color: #FFFBBD;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #FFFBBD;
                    @include sp{
                        font-size: 1.8rem;
                        padding-bottom: 2px;
                    }
                    
                }
            }
            dd{
                @include bor(2px,#9A8251);
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #fff;
            }
            &__flex{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 50px 0;
                @include sp{
                    flex-direction: column;
                    padding: 15px 20px;
                }
            }
            &__img{
                width: 320px;
                text-align: center;
                @include sp{
                    margin-bottom: 10px;
                    width: auto;
                }
                img{
                    max-width: 100%;
                    @include sp{
                        width: 70%;
                    }
                }
            }
            &__text{
                font-size: 1.8rem;
                line-height: 33px;
                color: $text;
                letter-spacing: 0.05rem;
                width: calc(100% - 320px);
                padding-right: 10px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 25px;
                    width: 100%;
                    padding-right: 0;
                }
                span{
                    font-size: 2.0rem;
                    font-weight: 700;
                    background-color: #FFFBBD;
                    padding: 0 3px;
                    @include sp{
                        font-size: 1.6rem;
                        line-height: 25px;
                    }
                }
                a {
                    display: block;
                    display: inline-block;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        top: 0;
                        right: -10px;
                        background: url('images/common/external-link.png') no-repeat;
                        background-size: contain;
                    }
                }
            }
            &__nav{
                display: flex;
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 30px;
                @include sp{
                    flex-direction: column;
                    padding: 15px;
                }
                li{
                    width: 215px;
                    @include sp{
                        display: flex;
                        width: 100%;
                    }
                    &:nth-child(2),
                    &:last-child{
                        margin-left: 30px;
                        @include sp{
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
                &__img{
                    margin-bottom: 15px;
                    @include sp{
                        width: 110px;
                    }
                    img{
                        max-width: 100%;
                        @include sp{
                            width: 100%;
                        }
                    }
                }
                &__text{
                    font-size: 1.6rem;
                    line-height: 24px;
                    letter-spacing: 0.05rem;
                    color: $text;
                    @include sp{
                        width: calc(100% - 110px);
                        padding-left: 10px;
                    }
                    span{
                        font-size: 1.4rem;
                        @include sp{
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
    
}
.sec08{
    padding-top:55px;
    padding-bottom: 125px;
    @include sp{
        padding: 25px 0;
    }
    &.pb0 {
        padding-bottom: 0;
    }
    &.bg{
        background-color: #F4F3F1;
        padding-bottom: 110px;
        @include sp{
            padding-bottom: 25px;
        }
        .nav_04 {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .nav_04{
        margin-top: 55px;
        padding-bottom: 70px;
        border-bottom: 1px solid #C7C7C7;
        margin-bottom: 40px;
        @include sp{
            padding-bottom: 20px;
            margin-top: 25px;
            margin-bottom: 20px;
        }
    }
    .text_02{
        margin-top: 80px;
        @include sp{
            margin-top: 25px;
        }
    }
    .c_nav_drop.auto{
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 60px;
        @include sp{
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .prefecture-lists {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .prefecture-list {
        margin-left: 5px;
        @include mq(lg) {
            margin-left: 20px;
        }
        &:first-child {
            margin-left: 0;
        }
        a {
            padding: 10px;
            font-size: 1.6rem;
            border-radius: 30px;
            letter-spacing: 1px;
            line-height: 39px;
            color: #fff;
            background-color: #9A8251;
            @include mq(lg) {
                padding: 10px 20px;
                font-size: 2.2rem;
            }
        }
    }
}
