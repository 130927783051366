.nav_01{
    display: flex;
    flex-wrap: wrap;
    li{
        width: 520px;
        margin-bottom: 40px;
        margin-right: 40px;
        @include bor(3px,$blue);
        border-radius: 10px;
        box-shadow: 0 0 19px rgba(#000,0.3);
        background-color: #fff;
        padding-bottom: 20px;
        @include sp{
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
            @include bor(1px,$blue);
            padding-bottom: 0;
            border-radius: 5px;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
        a{
            display: block;
            height: 100%;
            @include sp{
                padding-bottom: 10px;
            }
            &:hover{
                opacity: 0.85;
            }
        }
    }
    &__top{
        font-size: 3.0rem;
        font-family: $go;
        font-weight: 700;
        text-align: center;
        line-height: 79px;
        background-color: $blue;
        color: #fff;
        background-image: url('images/common/icon_arrow01.png');
        background-position: right 15px center;
        background-repeat: no-repeat;
        @include sp{
            font-size: 1.8rem;
            color: #fff000;
            line-height: 39px;
            background-image: url('images/common/icon_arrow01_sp.png');
            background-size: 16px 16px;
            background-position: right 5px center;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    &__img{
        width: 100%;
        width: 410px;
        height: 185px;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 20px;
        @include sp{
            width: 100%;
            height: 140px;
            margin-bottom: 0;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__gr{
        position: relative;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 10px;
        @include sp{
            width: 100%;
            padding: 0 25px;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    }
    &__tt{
        color: #FF1717;
        text-align: center;
        font-size: 2rem;
        @include sp{
            font-size: 1rem;
            position: absolute;
            bottom: 0;
            left: 25px;
            background-color: rgba(246, 246, 246,0.8);
            width: calc(100% - 50px);
            padding: 2px 0 0;
        }
        &__eng{
            font-size: 5.6rem;
            font-family: $ba;
            @include sp{
                font-size: 3.4rem;
            }
        }
        &__fs01{
            font-size: 4rem;
            @include sp{
                font-size: 2rem;
            }
        }
        &__fs02{
            font-size: 3.2rem;
            margin-right: 10px;
            @include sp{
                font-size: 2rem;
                margin-right: 7px;
            }
        }
    }
    &__text{
        font-size: 1.8rem;
        color: $text;
        line-height: 32px;
        padding: 0 25px;
        @include sp{
            font-size: 1.6rem;
            line-height: 25px;
            padding: 0 15px;
        }
    }
}
.nav_02{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include sp{
        flex-direction: column;
    }
    li{
        width: 510px;
        margin-right: 60px;
        margin-bottom: 50px;
        @include sp{
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid #DDDDDD;
            margin-right: 0;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    &__top{
        display: flex;
        margin-bottom: 25px;
        @include sp{
            margin-bottom: 20px;
        }
    }
    &__img{
        width: 150px;
        @include sp{
            width: 110px;
        }
        img{
            max-width: 100%;
        }
    }
    &__right{
        padding-left: 30px;
        width: calc(100% - 150px);
        @include sp{
            padding-left: 15px;
            width: calc(100% - 110px);
        }
    }
    &__text01{
        font-size: 1.6rem;
        color: $text;
        line-height: 24px;
        @include sp{
            font-size: 1.2rem;
            background-color: #F3ECDE;
            border-radius: 3px;
            line-height: 18px;
            display: table;
            margin-bottom: 6px;
            padding: 0 3px;
        }
    }
    &__tt{
        font-size: 1.8rem;
        line-height: 28px;
        color: $text;
        letter-spacing: 0.05rem;
        margin-bottom: 5px;
        font-weight: 700;
        @include sp{
            font-size: 1.6rem;
            line-height: 21px;
        }
    }
    &__text02{
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.05rem;
        color: $text;
    }
    &__text03{
        font-size: 1.8rem;
        line-height: 36px;
        letter-spacing: 0.05rem;
        color: $text;
        @include sp{
            font-size: 1.5rem;
            line-height: 23px;
        }
    }
}
.nav_03{
    display: flex;
    @include sp{
        flex-wrap: wrap;
    }
    li{
        width: 240px;
        margin-right: 40px;
        @include sp{
            width: 48%;
            margin-right: 4%;
            margin-bottom: 20px;
            &:nth-child(2n){
                margin-right: 0;
            }
        }
        &:last-child{
            margin-right: 0;
        }
        a{
            display: block;
            &:hover{
                opacity: 0.85;
            }
        }
    } 
    &__img{
       width: 100%;
       height: 240px;
       box-shadow: 0 0 19px rgba(#000, 0.3);
       border-radius: 10px;
       overflow: hidden;
       margin-bottom: 23px;
       @include sp{
           height: auto;
           min-height: 146px;
           margin-bottom: 10px;
       }
        img{
           width: 100%;
           height: 100%;
           object-fit: cover;
        }
    }
    &__date{
        font-size: 1.6rem;
        color: #797979;
        line-height: 28px;
        font-family: $ar;
        @include sp{
            font-size:1.1rem ;
            line-height: 14px;
        }
    }
    &__tt{
        font-size: 1.6rem;
        font-weight: 700;
        color: $text;
        line-height: 28px;
        margin-top: 10px;
        @include sp{
            line-height: 20px;
        }
    }
}
.nav_04{
    display: flex;
    justify-content: center;
    li{
        width: 248px;
        &:last-child{
            margin-left: 60px;
            @include sp{
                margin-left: 0;
            }
        }
    }
    &__img{
        width: 100%;
        height: 248px;
        border-radius: 50%;
        overflow: hidden;
        // @include bor(4px,#fff);
        // box-shadow: 0 0 5px #F5EFDF;
        margin-bottom: 15px;
        @include sp{
            width: 118px;
            height: 118px;
            // @include bor(2px,#fff);
            margin: 0 auto 10px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__text{
        font-size: 1.6rem;
        letter-spacing: 0.05rem;
        text-align: center;
        color: $text;
        line-height: 24px;
        margin-bottom: 4px;
        @include sp{
            font-size: 1.4rem;
            line-height: 20px;
            margin-bottom: 0;
        }
    }
    &__name{
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        text-align: center;
        color: $text;
        line-height: 26px;
        font-family: $go;
        @include sp{
            font-size: 1.8rem;
            line-height: 23px;
        }
    }
}
.nav_05{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    @include sp{
        flex-direction: column-reverse;
        margin-bottom: 25px;
    }
    >li{
        width: 510px;
        @include sp{
            width: 100%;
        }
        img{
            max-width: 100%;
            @include sp{
                width: 100%;
            }
        }
    }
    &__text{
        font-size: 1.8rem;
        color: $text;
        line-height: 34px;
        letter-spacing: 0.05rem;
        display: table;
        margin: 0 auto;
        margin-bottom: 30px;
        @include sp{
            font-size: 1.6rem;
            line-height: 25px;
            margin-bottom: 15px;
        }
        &__bold{
            font-weight: 700;
        }
        &__bg{
            color: #ff1a1a;
            font-weight: 700;
            background-color: #FFFED4;
        }
    }
}
.nav_06{
    display: flex;
    flex-wrap: wrap;
    li{
        width: 261px;
        background-color: #FFF3DD;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-bottom: 10px;
        margin-right: 12px;
        overflow: hidden;
        @include bor(2px,#FFF3DD);
        @include sp{
            width: 48.5%;
            margin-right: 3%;
            @include bor(1px,#FFF3DD);
            border-radius: 0;
            &:nth-child(2n){
                margin-right: 0;
            }
        }
        &:nth-child(4n){
            margin-right: 0;
        }
    }
    &.col_3{
        li{
            @media(min-width: 768px){
                width: 352px;
                &:nth-child(4n){
                    margin-right: 12px;
                }
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
        }
    }
    &__img{
        width: 100%;
        height: 137px;
        overflow: hidden;
        @include sp{
            height: 102px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 23px;
        text-align: center;
        color: #A48229;
        font-weight: 700;
        padding: 18px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            font-size: 1.4rem;
            line-height: 17px;
            padding: 16px 5px;
            
        }
    }
    &--col_5{
        @media(min-width: 768px){
            li{
                width: 19%;
                margin-right: 1.25%;
                &:nth-child(4n){
                    margin-right: 1.25%;
                }
                &:nth-child(5n){
                    margin-right: 0;
                }
            }
            .nav_06__text{
                font-size: 2rem;
            }
            .nav_06__img{
                height: 108px;
            }
        }
    }
}
.nav_07{
    margin-top: 35px;
    display: flex;
    @include sp{
        flex-wrap: wrap;
        margin-top: 15px;
    }
    li{
        width: 524px;
        margin-bottom: 40px;
        margin-right: 40px;
        @include bor(3px,$blue);
        border-radius: 10px;
        box-shadow: 0 0 19px rgba(#000,0.3);
        background-color: #fff;
        padding-bottom: 20px;
        @include sp{
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            @include bor(1px,$blue);
            padding-bottom: 15px;
            border-radius: 5px;
        }
        &:nth-child(2n){
            margin-right: 0;
        }
        a{
            display: block;
            height: 100%;
            @include sp{
                padding-bottom: 10px;
            }
            &:hover{
                opacity: 0.85;
            }
        }
    }
    &__top{
        font-size: 3rem;
        font-family: $go;
        font-weight: 700;
        text-align: center;
        line-height:68px;
        background-color: $blue;
        color: #FCFF00;
        letter-spacing: 0.18rem;
        @include sp{
            font-size: 1.8rem;
            line-height: 40px;
        }
    }
    &__img{
        width: 410px;
        height: 185px;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: 30px;
        @include sp{
            width: 100%;
            height: 113px;
            margin-bottom: 10px;
            padding: 0 25px;
            margin-top: 15px;

        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }
    &__tt{
        color: #FF1717;
        font-weight: 700;
        text-align: center;
        margin-bottom: 15px;
        &__fs01{
            font-size: 3.2rem;
            @include sp{
                font-size: 1.6rem;
            }
        }
        &__fs02{
            font-size: 4rem;
            @include sp{
                font-size: 1.6rem;
            }
        }
        &__fs03{
            font-size: 4.8rem;
            @include sp{
                font-size: 2.2rem;
            }
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 24px;
        color: $text;
        padding: 0 40px;
        margin-bottom: 6px;
        @include sp{
            padding: 0 20px;
            font-size: 1.6rem;
            line-height: 19px;
        }
    }
}
.nav_08{
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    @include sp{
        flex-wrap: wrap;
        margin-bottom: 18px;
    }
    li{
        width: 23%;
        @include sp{
            width: 48.5%;
            margin-bottom: 12px;
        }
        a{
            display: block;
            border: 2px solid #0B8985;
            background-image: linear-gradient( to top, rgb(6,131,127) 0%, rgb(40,178,174) 100%);
            padding: 20px 10px;
            height: 139px;
            position: relative;
            box-shadow: 0 4px 0 #024442;
            border-radius: 5px;
            @include sp{
                height: 78px;
                padding: 8px;
                box-shadow: 0 3px 0 #024442;
                border: 1px solid #0B8985;
            }
            &::before{
                content: '';
                width: 9px;
                height: 12px;
                background-image: url('images/common/icon_arrow.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 50%;
                bottom: 5px;
                transform: translateX(-50%) rotate(90deg);
                @include sp{
                    width: 5px;
                    height: 9px;
                    bottom: 2px;
                }
            }
        }
    }
    &__text{
        font-size: 1.8rem;
        letter-spacing: 0.05rem;
        color: #fff;
        font-weight: 500;
        text-align: center;
        margin-bottom: 6px;
        @include sp{
            font-size: 1rem;
            margin-bottom: 5px;
        }
    }
    &__text02{
        font-size: 2.4rem;
        font-weight: 700;
        text-align: center;
        line-height: 32px;
        color: #FCFF00;
        min-height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sp{
            font-size: 1.4rem;
            line-height: 18px;
            min-height: 36px;
        }
    }
}
.nav_09{
    display: flex;
    justify-content: space-between;
    @include sp{
        flex-direction: column-reverse;
    }
    li{
        width: 525px;
        @include sp{
            width: 100%;
        }
        img{
            max-width: 100%;
            @include sp{
                width: 100%;
                padding: 0 20px;
            }
        }
    }
    &__tt{
        font-size: 2.6rem;
        line-height: 40px;
        font-weight: 700;
        color: $text;
        margin-bottom: 30px;
        @include sp{
            font-size: 1.8rem;
            line-height: 24px;
            margin-top: 10px;
            margin-bottom: 8px;
        }
        span{
            background-color: #FFFCAD;
            color: #FF1313;
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 38px;
        letter-spacing: 0.05rem;
        color: $text;
        @include sp{
            font-size: 1.6rem;
            line-height: 28px;
        }
        span{
            color:#FF1515 ;
            text-decoration: underline;
        }
        span.u_bold{
            font-weight: 700;
            color: $text;
            text-decoration: none;
        }
    }
    &--style01{
        @include sp{
            flex-direction: column;
        }
        li{
            position: relative;
            @include sp{
                border-radius: 5px;
                margin-bottom: 10px;
                overflow: hidden;
            }
            img{
                width: 100%;
                @include sp{
                    padding: 0;
                }
            }
            p{
                font-size: 2.2rem;
                font-weight: 700;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background-color: rgba(#CFA944, 0.8);
                padding: 10px;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 20px;
                    padding: 5px;
                }
                &::after{
                    content: '';
                    width: 24px;
                    height: 24px;
                    background: url('images/common/icon_arrow01.png') no-repeat center;
                    background-size: contain;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    @include tranY(-50%);
                    @include sp{
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
    &__top{
        font-size: 1.8rem;
        display: table;
        padding: 0 25px;
        line-height: 36px;
        text-align: center;
        color: #fff;
        font-weight: 700;
        border-radius: 18px;
        background-color:#EC871D ;
        margin-bottom: 15px;
        @include sp{
            font-size: 1.2rem;
            border-radius: 9px;
            line-height: 18px;
            padding: 0 8px;
            margin-top: 15px;
            margin-bottom: 0;
        }
    }
    &--box{
        padding: 60px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba(#000,0.1);
        margin-top: 55px;
        flex-wrap: wrap;
        @include sp{
            padding: 20px 15px;
            flex-direction: column;
            margin-top: 20px;
        }
        li{
            width: 100%;
            margin-bottom: 55px;
            border-bottom: 1px dashed #C9C9C9;
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
            margin-bottom: 40px;
            @include sp{
                flex-direction: column-reverse;
                margin-bottom: 20px;
                padding-bottom: 20px;
            }
            &:last-child{
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
            >div{
                width: 465px;
                @include sp{
                    width: 100%;
                }
            }
        }
        .nav_09__tt{
            @include sp{
                font-size: 1.6rem;
                margin-top: 7px;
                line-height: 21px;
            }
        }
    }
    &--boxborder{
        @include bor(5px,#EC871D);
        background-color: #fff;
        padding: 60px 60px 30px;
        border-radius: 5px;
        @include sp{
            padding: 20px 15px;
            border: 3px solid #EC871D;
            border-radius: 3px;
        }
        li{
            width: 465px;
            @include sp{
                width: 100%;
                .nav_09__top{
                    margin: 0 auto;
                    margin-top: 15px;
                }
            }
        }
        .nav_09__tt{
            margin-bottom: 20px;
            @include sp{
                margin-bottom: 8px;
                font-size: 1.6rem;
                line-height: 24px;
            }
        }
    }
}
