.estimate_01 {
  padding: 20px;
  &__inner {
    text-align: center;
  }
  &__copy {
    margin-top: -10px !important;
    span {
      font-weight: bold;
      letter-spacing: -0.01em;
    }
  }
  &__btn {
    margin-top: -5px !important;
    a {
      display: block;
      padding-top: 16px;
      padding-bottom: 16px;
      color: #fff;
      background: #ff6c00;
      border-bottom: 3px solid #b24c00;
      border-radius: 35.5px;
      font-size: 21px;
      font-weight: 500;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}

// Pardotのフォームデザイン
.pardot_form {
  width: 100%;
  height: 2000px;
}
