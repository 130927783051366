.f_sec01{
    padding-top: 75px;
    margin-bottom: 80px;
    @include sp{
        padding-top: 30px;
        margin-bottom: 30px;
    }
    &__tt{
        font-size: 2.4rem;
        color: $text;
        line-height: 52px;
        letter-spacing: 1px;
        font-weight: 700;
        text-align: center;
        @include sp{
            font-size: 1.8rem;
            line-height: 30px;
            letter-spacing: 0.05rem;
        }
        span{
            color: #FF0000;
            background-color: #FFFEC0;
        }
    }
    .nav_flow{
        margin-top: 50px;
        @include sp{
            margin-top: 15px;
        }
    }
    &__box{
        background-color: #fff;
        padding: 60px 90px;
        @include bor(1px,#138576);
        margin-top: 60px;
        @include sp{
            margin-top: 20px;
            padding: 15px;
        }
        &__head{
            font-size: 2.4rem;
            line-height: 46px;
            color: #fff;
            display: table;
            margin: 0 auto;
            border-radius: 23px;
            background-color: #06837F;
            padding: 0 38px;
            margin-bottom: 30px;
            @include sp{
                font-size: 1.6rem;
                line-height: 23px;
                border-radius: 12px;
                padding: 0 17px;
                margin-bottom: 15px;
            }
        }
        &__text{
            font-size: 2rem;
            line-height: 42px;
            color: #333333;
            letter-spacing: 0.05rem;
            @include sp{
                font-size: 1.6rem;
                line-height: 26px;
            }
            span{
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
    table, input, textarea, select, option, button, h1, h2, h3, h4, h5, h6{
        font-family: $go!important;
    }
}
.f_sec02{
    background-color: #F5EFDF;
    padding: 40px 0;
    @include sp{
        padding: 20px 0;
    }
}
.f_sec03{
    padding: 100px 0 0;
    @include sp{
        padding-top: 20px;
    }
    .cta_02{
        margin-top: 85px;
        @include sp{
            margin-top: 20px;
        }
    }
    .u_arrow{
        border-left: 93px solid transparent;
        border-right: 93px solid transparent;
        border-top: 63px solid #CFA944;
        margin-top: 40px;
        margin-bottom: 0;
        @include sp{
            margin: 20px auto 0;
            border-left: 47px solid transparent;
            border-right: 47px solid transparent;
            border-top: 32px solid #CFA944;
        }
    }
    &__content{
        margin-bottom: 80px;
        @include sp{
            margin-bottom: 30px;
        }
        .c-tt07{
            margin-top: 60px;
            margin-bottom: 35px;
            @include sp{
                margin-top: 20px;
                margin-bottom: 15px;
                font-size: 2rem;
            }
        }
        .nav_09__text{
            span.color_black{
                text-decoration: none;
                font-weight: 700;
            }
        }
    }
    &__box{
        background-color: #F5EFDF;
        @include bor(1px,#138576);
        padding: 50px 90px;
        margin-top: 80px;
        @include sp{
            padding: 20px 10px;
            margin-top: 15px;
        }
        &__tt{
            font-size: 2.6rem;
            line-height: 38px;
            letter-spacing: 0.05rem;
            font-weight: 700;
            border-radius: 46px;
            color: #fff;
            padding: 8px 60px;
            display: table;
            margin: 0 auto;
            background-color: #06837F;
            text-align: center;
            margin-bottom: 35px;
            @include sp{
                font-size: 1.8rem;
                line-height: 23px;
                background-color: transparent;
                color: #06837f;
                padding: 0;
                margin-bottom: 10px;
            }
        }
        &__text{
            font-size: 2rem;
            line-height: 42px;
            letter-spacing: 0.05rem;
            font-weight: 500;
            color: $text ;
            margin-bottom: 20px;
            @include sp{
                font-size: 1.4rem;
                line-height: 21px;
                color: #333333;
                margin-bottom: 15px;
            }
        }
        &__img{
            text-align: center;
            img{
                max-width: 100%;
            }
        }
    }
    table, input, textarea, select, option, button, h1, h2, h3, h4, h5, h6{
        font-family: $go!important;
    }
    .nav_09__tt{
        color: #333;
        margin-bottom: 20px;
        @include sp{
            margin-bottom: 10px;
        }
    }
}
.f_sec04{
    padding: 80px 0;
    background-color: #F4F3F1;
    @include sp{
        padding: 30px 0;
    }
    &__tt{
        font-size: 3.6rem;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 38px;
        color: #0C8B87;
        margin-bottom: 30px;
        @include sp{
            font-size: 1.8rem;
            line-height: 21px;
            margin-bottom: 15px;
        }
    }
    &__block{
        margin-bottom: 75px;
        @include sp{
            margin-bottom: 30px;
        }
    }
    table, input, textarea, select, option, button, h1, h2, h3, h4, h5, h6{
        font-family: $go!important;
    }
}
.f_sec05{
    margin-top: 100px;
    margin-bottom: 50px;
    @include sp{
        margin-top: 30px;
        margin-bottom: 25px;
    }
    .s_sec03__top{
        @include sp{
            font-size: 1.4rem;
        }
        &::after{
            display: none;
        }
    }
    &__tt{
        font-size: 3.6rem;
        text-align: center;
        line-height: 54px;
        letter-spacing: 0.05rem;
        font-weight: 700;
        color: $text;
        font-family: $go;
        margin-bottom: 65px;
        @include sp{
            font-size: 1.6rem;
            line-height: 24px;
            margin-bottom: 25px;
        }
        span{
            color: #FF2323;
            background-color:#FFFEC0 ;
        }
    }
    .nav_02{
        li{
            &:last-child{
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
.nav_flow{
    display: flex;
    justify-content: space-between;
    li{
        width: 255px;
        @include bor(2px,#138576);
        background-color: #F6FFFE;
        border-radius: 5px;
        padding: 30px 20px;
        position: relative;
        padding-bottom: 62px;
        @include sp{
            width: 23.5%;
            padding: 10px 0;
            padding-bottom: 23px;
            @include bor(1px,#138576);
        }
        &::before{
            content: '';
            background-color:#138777 ;
            width: 100%;
            height: 42px;
            position: absolute;
            bottom: 0;
            left: 0;
            @include sp{
                height: 16px;
            }
        }
        &::after{
            content: '';
            width: 13px;
            height: 21px;
            transform: rotate(90deg);
            background-image: url('images/common/icon_arrow02.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            left: 50%;
            bottom: 10px;
            @include sp{
                width: 7px;
                height: 11px;
                bottom: 3px;
            }
        }
        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        span{
            font-family: $ar;
            font-size: 2rem;
            font-weight: 700;
            color: #138777;
            display: block;
            text-align: center;
            @include sp{
                font-size: 1rem;
                width: 50px;
                line-height: 13px;
                background-color: #138777;
                border-radius: 3px;
                color: #fff;
                margin: 0 auto;
            }
        }
        figure{
            text-align: center;
            margin: 20px 30px;
            height: 77px;
            line-height: 77px;
            @include sp{
                margin: 10px 0;
                line-height: 38px;
                height: 38px;
            }
            img{
                max-width: 100%;
                @include sp{
                    max-height: 38px;
                }
            }
        }
        p{
            font-size: 2.4rem;
            font-weight: 700;
            letter-spacing: 0.05rem;
            line-height: 32px;
            color: #138777;
            text-align: center;
            @include sp{
                font-size: 1.2rem;
                line-height: 16px;
                letter-spacing: -1px;
            }
        }
    }
}
.list_flow{
    display: flex;
    padding: 50px;
    background: #fff;
    border: 2px solid #CFA944;
    border-radius: 3px;
    margin-bottom: 20px;
    @include sp{
        flex-direction: column-reverse;
        padding: 20px;
        border: 1px solid #CFA944;
        margin-bottom: 10px;
    }
    dt{
        width: calc(100% - 480px);
        padding-right:50px;
        @include sp{
            width: 100%;
            padding-right: 0;
            margin-top: 15px;
        }
    }
    dd{
        width: 480px;
        @include sp{
            width: 100%;
            padding: 0 10px;
        }
        img{
            width: 100%;
        }
    }
    &__tt{
        font-size: 2.6rem;
        line-height: 38px;
        font-weight: 700;
        color: $text;
        margin-bottom: 25px;
        position: relative;
        padding-left: 40px;
        @include sp{
            font-size: 1.8rem;
            line-height: 24px;
            padding-left: 20px;
            margin-bottom: 8px;
        }
        span{
            color: #FF1111;
            background-color: #FFFEC0;
        }
        img{
            position: absolute;
            left: 0;
            top: 5px;
            @include sp{
                width: 16px;
                top: 4px;
            }
        }
    }
    &__text{
        font-size: 1.8rem;
        line-height: 34px;
        letter-spacing: 0.05rem;
        color: $text;
        @include sp{
            font-size: 1.6rem;
            line-height: 23px;
        }
        span{
            font-weight: 700;
        }
    }
}
.flow_qa{
    margin-bottom: 10px;
    @include sp{
        margin-bottom: 5px;
    }
    dt{
        background-color: #0C8B87;
        font-size: 2.4rem;
        line-height: 38px;
        color: #fff;
        padding: 8px 15px;
        font-weight: 700;
        cursor: pointer;
        position: relative;
        font-family: $go;
        padding-right: 30px;
        @include sp{
            font-size: 1.4rem;
            line-height: 23px;
            padding: 10px 7px;
            padding-right: 20px;
            padding-left: 22px;
            position: relative;
        }
        &::after{
            content: '';
            font-family: $ar;
            width: 21px;
            height: 21px;
            background: url('images/flow/normal.png') no-repeat center;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            @include sp{
                width: 11px;
                height: 11px;
                background-size: contain;
                right: 5px;
            }
        }
        span{
            font-family: $ar;
            font-size: 2.6rem;
            font-weight: bold;
            color: #fff;
            @include sp{
                font-size: 1.4rem;
                line-height: 23px;
                position: absolute;
                left: 7px;
                top: 10px;
            }
        }
        &.active{
            &::after{
                content: '';
                background-image: url('images/flow/open.png');
            }
        }
    }
    dd{
        background-color: #fff;
        padding: 15px 20px;
        @include bor(1px,#0C8B87);
        display: flex;
        display: none;
        &.open{
            display: flex;
            padding: 10px;
        }
        p{
            font-size: 1.8rem;
            line-height: 32px;
            color: $text;
            font-family: "Noto Sans JP", Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
            font-weight: 400;
            @include sp{
                font-size: 1.4rem;
                line-height:23px;
            }
        }
        

    }
    &__a{
        font-size: 2.6rem;
        font-family: $ar;
        line-height: 38px;
        letter-spacing: 0.05rem;
        color: #0C8B87;
        font-weight: bold;
        margin-right: 10px;
        @include sp{
            font-size: 1.3rem;
            line-height: 19px;
            margin-right: 5px;
        }
    }
    &__link{
        font-size: 2rem;
        font-weight: 700;
        line-height: 29px;
        color: $text;
        padding-left: 31px;
        background-image: url('images/flow/icon_01.png');
        background-size: 24px 24px;
        background-position: left center;
        background-repeat: no-repeat;
        margin-top: 11px;
        display: table;
        font-family: "Noto Sans JP", Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
        @include sp{
            font-size: 1.1rem;
            line-height: 16px;
            background-size: 11px 11px;
            padding-left: 15px;
        }
    }
}
