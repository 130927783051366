.card-list {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    @include mq(lg) {
        gap: 16px;
    }
}
  
.card {
    flex: 1 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    @include mq(lg) {
        flex: 1 1 calc(25% - 16px);
        max-width: calc(25% - 16px);
    }
  
    img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        @include mq(lg) {
            height: 160px;
        }
    }
  
    .card-content {
        padding: 16px;
  
        .card-title {
            font-size: 1.5rem;
            margin: 0 0 12px;
            text-align: center;
            font-weight: bold;
            @include mq(lg) {
                font-size: 1.6rem;
            }
        }
  
        .card-text {
            font-size: 1.3rem;
            margin-bottom: 18px;
            line-height: 1.6;
        }
  
        .card-button {
            display: block;
            width: 100%;
            text-align: center;
            padding: 10px 0;
            font-size: 1.3rem;
            color: #fff;
            background-color: $brand-color-purple;
            border: none;
            border-radius: 30px;
        }
    }
}
  