/* padding - utility
--------------------------------------------------------- */

$num: 5;

@for $i from 0 through 20 {
    .u_pt#{$i * $num} {
        padding-top: #{$i * $num}px !important;
    }
    .u_pr#{$i * $num} {
        padding-right: #{$i * $num}px !important;
    }
    .u_pb#{$i * $num} {
        padding-bottom: #{$i * $num}px !important;
    }
    .u_pl#{$i * $num} {
        padding-left: #{$i * $num}px !important;
    }
}

@media screen and (max-width: 991px) {
    @for $i from 0 through 20 {
        .spt#{$i * $num} {
            padding-top: #{$i * $num}px !important;
        }
        .spr#{$i * $num} {
            padding-right: #{$i * $num}px !important;
        }
        .spb#{$i * $num} {
            padding-bottom: #{$i * $num}px !important;
        }
        .spl#{$i * $num} {
            padding-left: #{$i * $num}px !important;
        }
    }
}
