.btn_01{
    font-size: 2rem;
    color: #fff;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1px;
    display: block;
    max-width: 440px;
    line-height: 75px;
    background-image: linear-gradient(to top,  rgb(19,126,113) 0%, rgb(16,182,151) 100%);
    border-radius: 10px;
    box-shadow: 0 6px 0 #034135;
    margin: 0 auto;
    position: relative;
    @include sp{
        // max-width: 260px;
        font-size: 1.6rem;
        line-height: 46px;
        box-shadow: 0 2px 0 #034135;
    }
    &:hover{
        opacity: 0.85;
    }
    &:after{
        content: '';
        width: 11px;
        height: 15px;
        background: url('images/common/icon_arrow02.png') no-repeat center;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 15px;
        @include tranY(-50%);
        @include sp{
            width: 8px;
            height: 12px;
            right: 5px;
        }
    }
}
.btn_02{
    @extend .btn_01;
    
    font-size: 2.6rem;
    line-height: 95px;
    max-width: 100%;
    background-image: linear-gradient( to top, rgb(6,131,127) 0%, rgb(40,178,174) 100%);
    border: 2px solid rgb(11, 137, 133);
    span{
        color: #FCFF00;
    }
    &::after{
        width: 29px;
        height: 29px;
        background-image: url('images/common/icon_arrow01.png');
        @include sp{
            width: 16px;
            height: 16px;
            right: 10px;
        }
    }
}

  