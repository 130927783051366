.area_top{
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #F4F3F1;
    @include sp{
        padding-top: 30px;
        padding-bottom: 0;
    }
    &__flex{
        margin-bottom: 0;
        @include sp{
            flex-direction: column;
        }
        >li{
            @include sp{
                display: flex;
                flex-direction: column;
                margin-bottom: 35px;
            }
        }
    }
    .nav_05__text{
        font-size: 2.4rem;
        margin-bottom: 5px;
        text-align: center;
        font-weight: bold;
        @include sp{
            order: 2;
            font-size: 1.4rem;
            line-height: 21px;
        }
    }
    &__img{
        margin-top: 30px;
        @include sp{
            order: 1;
            margin-top: 0;
            margin-bottom: 15px;
        }
        img{
            max-width: 100%;
        }
    }
    &__text{
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        color: $text;
        margin-bottom: 30px;
        @include sp{
            order: 3;
            font-size: 1.4rem;
            line-height: 21px;
            margin-bottom: 10px;
        }
    }
    .c_nav_drop{
        @include sp{
            order: 4;
        }
    }
    .c_nav_drop.auto{
        justify-content: center;
        margin-top: 21px;
        @include sp{
            margin-top: 10px;
        }
    }
}
.a_sec_acf{
    margin-top: 50px;
    margin-bottom: 150px;
    @include sp{
        margin-bottom: 60px;
    }
}
.list_area{
    li{
        border-bottom: 2px solid #B5B5B5;
        padding-bottom: 60px;
        margin-bottom: 60px;
        @include sp{
            padding-bottom: 20px;
            margin-bottom: 30px;
        }
        &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    &__top{
        font-size: 1.8rem;
        font-weight: bold;
        color: $text;
        line-height: 38px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        @include sp{
            font-size: 1.4rem;
            line-height: 19px;
            margin-bottom: 7px;
            font-weight: normal;
        }
    }
    &__label{
        width: 186px;
        line-height: 40px;
        background-color: #CFA944;
        display: inline-block;
        font-size: 2.2rem;
        text-align: center;
        color: $text;
        margin-right: 15px;
        @include sp{
            font-size: 1.4rem;
            width: auto;
            line-height: 20px;
            padding: 2px 9px;

        }
        &.bg_blue{
            background-color: #72B7EC;
        }
        &.bg_yellow {
            background-color: #CA9E2A;
        }
        &.list_area__label.bg_black {
            background-color: #000000;
            color: #fff;
        }
        &.list_area__label.bg_green {
            background-color: #688F0B;
        }
    }
    &__title{
        font-size: 2.6rem;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 38px;
        color: $text;
        margin-bottom: 35px;
        @include sp{
            font-size: 1.8rem;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }
    &__img{
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        @include sp{
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }
        img{
            max-width: 100%;
        }
        &__item{
            width: 510px;
            position: relative;
            @include sp{
                width: 255px;
            }
            &:nth-child(2){
                float: right;
                position: relative;
                margin-left: 60px;
                @include sp{
                    margin-left: 0;
                    margin-top: 25px;
                }
                &::before{
                    content: '';
                    border-top: 52px solid transparent;
                    border-bottom: 52px solid transparent;
                    border-left: 30px solid #CFA944;
                    position: absolute;
                    left: -45px;
                    top: 50%;
                    @include tranY(-50%);
                    @include sp{
                        border-left: 26px solid transparent;
                        border-right: 26px solid transparent;
                        border-top: 15px solid #CFA944;
                        top: -19px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                }
            }
            &__text{
                font-size: 1.8rem;
                width: 170px;
                text-align: center;
                font-weight: 700;
                background-color: rgba(207, 169, 68, 0.8);
                line-height: 36px;
                color: #0c0c0c;
                letter-spacing: 1px;
                position: absolute;
                bottom: 0;
                right: 0;
                @include sp{
                    font-size: 1.1rem;
                    line-height: 18px;
                    width: 85px;
                }
            }
        }
    }
    &__cont{
        @include clear;
        @include sp{
            display: flex;
            flex-direction: column;
        }
    }
    &__dl{
        max-width: 515px;
        float: left;
        @include sp{
            width: 100%;
            float: none;
            order: 2;
        }
        dt{
            font-size: 2.4rem;
            line-height: 28px;
            letter-spacing: 0.05rem;
            color: #B28E2E;
            font-weight: 700;
            margin-bottom: 10px;
            @include sp{
                font-size: 1.8rem;
                line-height: 19px;
            }
            &::before{
                content: '';
                width: 28px;
                height: 28px;
                background-image: url('images/area/icon_checkbox.png');
                background-size: contain;
                display: inline-block;
                margin-right: 7px;
                vertical-align: middle;
                @include sp{
                    width: 14px;
                    height: 14px;
                }
            }
        }
        dd{
            font-size: 2rem;
            line-height: 34px;
            color: $text;
            margin-bottom: 20px;
            @include sp{
                font-size: 1.6rem;
                line-height:28px;
            }
        }
    }
    &__text{
        width: 510px;
        text-align: left;
        float: right;
        margin-bottom: 35px;
        @include sp{
            width: 100%;
            float: none;
            order: 1;
            margin-bottom: 15px;
        }
        span{
            font-size: 2.4rem;
            color: #fff;
            letter-spacing: 0.05rem;
            font-weight: bold;
            padding: 5px 10px;
            background-color: #CFA944;
            display: table;
            @include sp{
                font-size: 1.4rem;
            }
        }
    }
    &__bot{
        float: right;
        width: 510px;
        text-align: left;
        @include sp{
            width: 100%;
            float: none;
            order: 3;
        }
        dt{
            font-size: 2.8rem;
            font-weight: bold;
            line-height: 38px;
            letter-spacing: 0.05rem;
            color: $text;
            @include sp{
                font-size: 1.8rem;
                line-height: 19px;
            }
        }
        dd{
            font-size: 2.4rem;
            line-height: 38px;
            font-weight: 700;
            color: #FF2727;
            margin-top: 10px;
            @include sp{
                font-size: 1.5rem;
                line-height: 24px;
            }
            span{
                font-size: 5.4rem;
                font-family: $ar;
                @include sp{
                    font-size: 3.5rem;
                }
            }
        }
    }
    &__gr{
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        background-color: #F7F2E4;
        padding: 30px 0 10px;
        margin-top: 10px;
        @include sp{
            order: 4;
            flex-direction: column;
            align-items: center;
            padding: 20px 20px 10px;
        }
        &__left{
            display: flex;
            @include sp{
                margin-bottom: 10px;
            }
        }
        &__dl{
            @include sp{
                // width: 40%;
            }
            dt{
                font-size: 2.4rem;
                letter-spacing: 0.05rem;
                line-height: 40px;
                font-weight: 700;
                color: $text;
                text-align: center;
                margin-bottom: 10px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 19px;
                    text-align: left;
                }
            }
            dd{
                font-size: 2.4rem;
                font-weight: 700;
                color: #B28E2E;
                text-align: center;
                line-height: 70px;
                @include sp{
                    font-size: 1.4rem;
                    line-height: 19px;
                    text-align: left;
                    white-space: nowrap;
                }
                span{
                    font-size: 4rem;
                    font-family: $ar;
                    @include sp{
                        font-size: 2rem;
                    }
                }
            }
            &.st01{
                dd{
                    color: #FF2727;
                }
            }
            &.st02{
                @include sp{
                    width: 80%;
                }
                dd{
                    color: #FF2727;
                    span{
                        font-size: 6rem;
                        @include sp{
                            font-size: 3rem;
                        }
                    }
                }
            }
        }
        &__fs01{
            font-size: 3rem;
            line-height: 40px;
            font-weight: 700;
            color: $text;
            margin: 0 20px;
            @include sp{
                font-size: 1.4rem;
                line-height: 19px;
            }
        }
        &__fs02{
            font-size:4rem;
            line-height: 40px;
            font-weight: 700;
            color: $text;
            font-family: $ar;
            margin: 0 50px 0 25px;
            @include sp{
                font-size: 3rem;
                line-height: 22px;
                margin: 0 15px 0 0;
            }
        }
    }
}
.c_l_blue {
    background-color: #59A3DB;
}
  
.c_l_black {
    background-color: #000;
    color: #fff;
}
  
.c_l_green {
    background-color: #688F0B;
}


.c-btn01 {
    width: 100%;
    height: 50px;
    max-width: 280px;
    margin: 0 auto;
    &__link {
        padding: 10px 30px 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #fff;
        border-radius: 30px;
        line-height: 1.1;
        font-size: 1.6rem;
        @include mq(lg) {
            font-size: 1.8rem;
        }
        &::after {
            content: '';
            padding: 4px;
            position: absolute;
            display: inline-block;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid #fff;
            border-width: 0 2px 2px 0;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

.area_main-service {
    padding-bottom: 50px;
    @include mq(lg) {
        padding-bottom: 100px;
    }
    &__lists {
        @include mq(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    &__list {
        position: relative;
        padding: 94px 10px 50px;
        margin-top: 125px;
        border-radius: 10px;
        &:first-child {
            margin-top: 100px;
        }
        @include mq(lg) {
            margin-top: 170px;
            padding: 116px 18px 50px;
            width: 48%;
            &:first-child, &:nth-child(2) {
                margin-top: 100px;
            }
        }
        img {
            width: 140px;
            position: absolute;
            border-radius: 50%;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
            @include mq(lg) {
                width: 180px;
                top: -90px;
            }
        }
        &.ihinseiri {
            background: #EAEEFC;
            img {
                border: 2px solid #5D74B7;
            }
            .area_main-service__title {
                color: #5D74B7;
            }
            .c-btn01__link {
                background: #5D74B7;
            }
        }
        &.tokushuseiso {
            background: #DCEFF4;
            img {
                border: 2px solid #30A1BF;
            }
            .area_main-service__title {
                color: #30A1BF;
            }
            .c-btn01__link {
                background: #30A1BF;
            }
        }
        &.seizenseiri {
            background: #E5F7F3;
            img {
                border: 2px solid #12B697;
            }
            .area_main-service__title {
                color: #12B697;
            }
            .c-btn01__link {
                background: #12B697;
            }
        }
        &.gomiyashikikataduke {
            background: #F0F7DA;
            img {
                border: 2px solid #8FAD1D;
            }
            .area_main-service__title {
                color: #8FAD1D;
            }
            .c-btn01__link {
                background: #8FAD1D;
            }
        }
    }
    &__title {
        margin-bottom: 16px;
        font-size: 2.5rem;
        text-align: center;
    }
    &__description {
        padding: 20px;
        font-size: 1.5rem;
        line-height: 25px;
        background: #fff;
        border-radius: 10px;
        @include mq(md) {
            font-size: 1.6rem;
            line-height: 29px;
        }
    }
    &__btn {
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
    }
}


.area_service {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            margin-bottom: 20px;
            width: 48%;
            @include mq(lg) {
                margin-bottom: 45px;
                width: 23%;
            }
        }
    }
    &__img {
        img {
            border-radius: 10px;
        }
    }
    &__title {
        margin-top: 15px;
        margin-bottom: 8px;
        font-size: 1.5rem;
        color: #FF6C00;
        @include mq(lg) {
            margin-top: 18px;
            font-size: 1.8rem;
        }
    }
    &__description {
        padding: 0 3px;
        font-size: 1.2rem;
        line-height: 20px;
        @include mq(lg) {
            font-size: 1.4rem;
        }
    }
    p span {
        color: #FF3E32;
        background-color: #FFF9C2;
    }
}

.area_flow {
    padding: 25px 0;
    @include mq(lg) {
        padding-top: 90px;
        padding-bottom: 100px;
    }
    &__list {
        @include mq(lg) {
            display: flex;
            justify-content: space-between;
        }
        li {
            padding: 20px;
            margin-bottom: 40px;
            text-align: center;
            position: relative;
            width: 100%;
            border: 2px solid #FF6C00;
            border-radius: 10px;
            @include mq(lg) {
                padding: 10px;
                margin-bottom: 0;
                width: 22%;
            }
            &:last-child {
                margin-bottom: 0;
                // 最後の矢印は非表示
                &::after {
                    content: none;
                }
            }
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -32px;
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                border-top: 20px solid #FF6C00;
                @include mq(lg) {
                    bottom: 50%;
                    left: calc(100% + 15px);
                    transform: translateY(50%);
                    border-left: 20px solid #FF6C00;
                    border-right: none;
                    border-top: 30px solid transparent;
                    border-bottom: 30px solid transparent;
                }
            }
        }
    }
    &__number {
        padding: 5px 20px;
        display: inline-block;
        font-size: 1.4rem;
        color: #fff;
        background: #FF6C00;
        border-radius: 20px;
        font-weight: bold;
    }
    img {
        display: block;
        margin: 20px auto;
        width: 20%;
        @include mq(lg) {
            width: 40%;
        }
    }
    &__title {
        margin-bottom: 14px;
        font-size: 1.8rem;
        @include mq(lg) {
            font-size: 1.6rem;
        }
    }
    &__tel {
        a {
            padding-left: 30px;
            margin-bottom: 6px;
            display: inline-block;
            position: relative;
            font-size: 2.5rem;
            color: #FF6C00;
            font-weight: bold;
            @include mq(lg) {
                padding-left: 24px;
                font-size: 2rem;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 26px;
                height: 26px;
                background-image: url('images/ihinseiri/flow-step1_tel.png');
                background-size: contain;
                @include mq(lg) {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        span {
            display: block;
        }
    }
}

.area_price {
    padding: 25px 0;
    @include mq(lg) {
        padding-top: 90px;
        padding-bottom: 100px;
    }
}

.area_tv {
    padding: 25px 0;
    @include mq(lg) {
        padding-top: 90px;
        padding-bottom: 100px;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        li {
            width: 48%;
            @include mq(lg) {
                width: 44%;
            }
        }
    }
    &__img {
        img {
            border-radius: 10px;
            @include mq(lg) {
                width: 100%;
            }
        }
    }
    &__text {
        margin-top: 10px;
        font-size: 1.2rem;
        line-height: 20px;
        @include mq(lg) {
            margin-top: 18px;
            font-size: 1.8rem;
            line-height: 28px;
        }
    }
}

.area_map {
    @include mq(lg) {
        display: flex;
        align-items: center;
    }
    iframe {
        width: 100%;
        height: 300px;
        margin-bottom: 10px;
    }
    &__text {
        font-size: 1.6rem;
        line-height: 28px;
        @include mq(lg) {
            font-size: 1.8rem;
            line-height: 36px;
            padding: 30px;
        }
    }
}
