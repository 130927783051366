.c-footer{
    background-color: #F4F4F4;
    @include sp{
        padding-bottom: 94px;
        background-color: #EDEBEB;
    }
    &__top{
        padding-top: 60px;
        padding-bottom: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include sp{
            display: none;
            padding-top: 0;
        }
    }
    
    .container{
        @include sp{
            padding: 0;
        }
    }
    &__logo{
        width: 280px;
        @include sp{
            display: none;
        }
        img {
            max-width: 100%;
        }
        dl {
            font-size: 1.5rem;
            dt {
                margin-top: 18px;
                font-weight: bold;
            }
            dd {
                margin-top: 3px;
            }
        }
        a:last-child {
            img {
                margin-top: 18px;
            }
        }
    }
    &__right{
        display: flex;
        justify-content: end;
        width: calc(100% - 280px);
        @include sp{
            width: 100%;
            display: block;
        }
    }
    &__nav{
        margin-right: 40px;
        >li{
            >a{
                font-size: 1.5rem;
                line-height: 25px;
                color: $text;
                font-weight: 700;
                letter-spacing: 0.05rem;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;
                display: block;
                &:hover{
                    opacity: 0.85;
                }
                &::before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: url(images/common/icon_orange.png) no-repeat center;
                    background-size: contain;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    @include sp{
                        left: 7px;
                    }
                }
            }
        }
        &__list{
            a{
                font-size: 1.4rem;
                display: block;
                line-height: 24px;
                letter-spacing: 0.05rem;
                color: $text;
                padding-left: 25px;
            }
        }
    }
    &__text{
        font-size: 1.4rem;
        font-family: $go;
        line-height: 24px;
        padding:20px ;
        background-color: #EDEBEB;
        font-weight: 700;
        text-align: center;
        @include sp{
            font-size: 1.1rem;
            line-height: 12px;
            padding: 10px;
        }
    }
    &__law{
        background-color: #fff;
        padding-top: 15px;
        padding-bottom: 15px;

        & > a {
            display: block;
            text-align: center;
            img {
                width: 60%;
            }
        }

        dl {
            padding: 0 7.5px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 1.4rem;
            dt {
                margin-top: 24px;
                width: 32%;
                font-weight: bold;
            }
            dd {
                margin-top: 24px;
                width: 64%;
                a {
                    color: #0000ee;
                }
            }
        }

        &__nav{
            display: table;
            margin: 30px auto 0;
            max-width: 320px;
            text-align: center;
            li{
                display: inline-block;
                &:nth-child(2n){
                    border-left: 1px solid $text;
                }
                a{
                    font-size: 1.4rem;
                    line-height: 23px;
                    color: $text;
                    padding: 0 10px;

                }
            }
        }
        &__text{
            text-align: center;
            font-size: 1.1rem;
            line-height: 16px;
            margin-top: 10px;
        }
    }
    .c_topsp__bottom{
        background-image: url('images/common/icon_plus_sp.png');
        &.active{
            background-image: url('images/common/icon_minus.png');
        }
    }
}

.footer_banner{
    z-index: 1;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    // background: rgba(0,0,0,0.3);
    display: none;
    // スクロールでバナーが表示されないようにする(PC用)
    @media screen and(min-width: 768px) {
        display: none!important;
    }
    a{
        display: block;
        color: #fff;
        text-align: center;
        padding: 8px;
        max-width: 380px;
        margin: 0 auto;
    }
    img{
        max-width: 100%;
    }
    // コラム用追従バナー
    .estimate_01 {
        padding: 20px 20px 10px;
        background: rgba(244,244,244,0.9);
        &__copy {
            display: block;
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: bold;
        }
    }          
}
