@charset "UTF-8";

// サポートブラウザの設定
$support-ie6: false;
$support-ie7: false;
$support-ie8: false;
$support-ie9: false;
$support-mozilla: true;
$support-webkit : true;
$support-opera  : false;

// breakpoint
$breakpoint1:767px;

// color
$text : #222222;
$orange: #FF6C00;
$blue: #06837F;

// fonsize
$fs_base: 1.4rem;
$ba: 'Bahnschrift', sans-serif;
$go: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
$ar : 'Arial', sans-serif;
$ga: adobe-garamond-pro, serif;
$yu: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", serif;
