.case {
    .kv_main__title01{
        top: 58%;
        @media (min-width: 768px) and (max-width: 1380px){
            font-size: 4.2vw;
        }
    }
    // 写真付き作業実績例
    .sec01 {
        padding-top: 80px;
        padding-bottom: 20px;
        @include sp{
            padding-top: 30px;
            padding-bottom: 0;
        }
        &__tt{
            font-size: 2.4rem;
            line-height: 52px;
            color: $text;
            text-align: center;
            padding-bottom: 60px;
            border-bottom:1px solid #DBDBDB ;
            margin-bottom: 80px;
            font-family: $go;
            @include sp{
                font-size: 1.8rem;
                line-height: 30px;
                text-align: left;
                padding-bottom: 15px;
                margin-bottom: 30px;
            }
        }
    }
    // お客様の声
    .sec03 {
        padding-top: 80px;
        padding-bottom: 80px;
        @include sp{
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .nav_02{
            margin-bottom: 30px;
            @include sp{
                margin-bottom: 10px;
            }
        }
    }
}
