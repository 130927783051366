/* margin - utility
--------------------------------------------------------- */
/* auto margin */
.u_mC {
    margin-right: auto !important;
    margin-left: auto !important;
}

$num: 5;

@for $i from 0 through 20 {
    .u_mt#{$i * $num} {
        margin-top: #{$i * $num}px !important;
    }
    .u_mr#{$i * $num} {
        margin-right: #{$i * $num}px !important;
    }
    .u_mb#{$i * $num} {
        margin-bottom: #{$i * $num}px !important;
    }
    .u_ml#{$i * $num} {
        margin-left: #{$i * $num}px !important;
    }
}

@media screen and (max-width: 991px) {
    @for $i from 0 through 20 {
        .u_smt#{$i * $num} {
            margin-top: #{$i * $num}px !important;
        }
        .u_smr#{$i * $num} {
            margin-right: #{$i * $num}px !important;
        }
        .u_smb#{$i * $num} {
            margin-bottom: #{$i * $num}px !important;
        }
        .u_sml#{$i * $num} {
            margin-left: #{$i * $num}px !important;
        }
    }
}
