.reason{
    
    .r_sec01{
        padding-top:60px;
        margin-bottom: 90px;
        @include sp{
            padding-top: 10px;
            margin-bottom: 25px;
        }
        &__tt{
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 52px;
            color: $text;
            margin-bottom: 85px;
            @include sp{
                font-size: 1.6rem;
                line-height: 28px;
                margin-bottom: 30px;
            }

            span{
                color: #ff0000;
                text-decoration: underline;
            }
        }
        &__box{
            background-color: #f5efdf;
            padding: 60px 100px;
            @include sp{
                padding: 20px 10px;
            }
            &__top{
                font-size: 2.4rem;
                color: #fff;
                display: table;
                margin: 0 auto;
                letter-spacing: 1px;
                font-weight: 700;
                border-radius: 23px;
                background-color: #06837F;
                padding: 10px 36px;
                @include sp{
                    font-size: 2rem;
                    line-height: 26px;
                    padding: 6px 17px;
                }
            }
            &__tt{
                font-size: 2.4rem;
                line-height: 42px;
                text-align: center;
                color: $text;
                font-weight: 700;
                margin-bottom: 40px;
                margin-top: 17px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 26px;
                    text-align: left;
                    letter-spacing: 0.05rem;
                    margin-bottom: 10px;
                    margin-top: 10px;
                }
                p{
                    background-color: #FFFEC0;
                    display: table;
                    margin: 0 auto;
                    line-height: 1;margin-top: 10px;
                    @include sp{
                        display: none;
                    }
                }
                span{
                    color: #FF2626;
                    @include sp{
                        background-color: #FFFEC0;
                        &.color_normal{
                            color: $text;
                            background: none;
                        }
                    }
                }
            }
            &__text{
                font-size: 2rem;
                line-height: 42px;
                letter-spacing: 0.05rem;
                color: $text;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 28px;
                }
            }
        }
    }
    .r_sec02{
        padding-top: 105px;
        background: url(images/urgency/s4_background.png) center;
        background-repeat: repeat;
        background-size: contain;
        padding-bottom: 40px;
        @include sp{
            padding-bottom: 20px;
            padding-top: 30px;
        }
        .s_sec03__top{
            &::after{
                display: none;
            }
        }
        .nav_09{
            margin-bottom: 80px;
            @include sp{
                margin-bottom: 25px;
            }
            &--style01{
                margin-bottom: 0;
                margin-top: 63px;
                @include sp{
                    margin-top: 20px;
                }
                li{
                    a{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            &--box{
                margin-bottom: 40px;
            }
        }
        .nav_06{
            margin-top: 40px;
            @include sp{
                margin-top: 15px;
            }
            
        }
        &__box{
            margin-top: 40px;
            position: relative;
            height: 195px;
            @include sp{
                height: 95px;
                width: 305px;
                margin: 20px auto;
            }
            a {
                padding-top: 50px;
                @include sp {
                    padding-top: 15px;
                }
            }
            &__img{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top:0%;
                z-index: 0;
                img{
                    width: 100%;
                    @include sp{
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            &__text{
                font-size: 2.2rem;
                text-align: center;
                letter-spacing: 0.05rem;
                font-weight: 700;
                line-height: 29px;
                color: $text;
                position: relative;
                z-index: 2;
                margin-bottom: 15px;
                @include sp{
                    font-size: 1.2rem;
                    line-height: 19px;
                    margin-bottom: 5px;
                    display: table;
                    margin-left: auto;
                    margin-right: 20px;
                }
            }
            &__tt{
                font-size: 4rem;
                letter-spacing: 0.05rem;
                line-height: 1;
                color: $text;
                font-family: $yu;
                font-weight: 700;
                text-align: center;
                position: relative;
                z-index: 2;
                margin-bottom: 10px;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 19px;
                    display: table;
                    margin-left: auto;
                    margin-right: 17px;
                    margin-bottom: 5px;
                }
                span{
                    color: #A50000;
                }
            }
            &__link{
                font-size: 2rem;
                font-weight: 700;
                color: #fff;
                border-radius: 16px;
                line-height: 31px;
                width: 196px;
                text-align: center;
                display: block;
                background: url('images/common/icon_arrow01.png') no-repeat center;
                background-size: 16px 16px;
                background-position: left 25px center;
                background-color: #A50000;
                padding-left: 10px;
                position: relative;
                z-index: 2;
                margin-left: auto;
                margin-right: 10px;
                @include sp{
                    font-size: 1.2rem;
                    line-height: 18px;
                    width: 109px;
                    background-size: 10px 10px;
                    background-position: left 15px center;
                    margin-right: 45px;
                }
            }
            a{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
        }
        &__content{
            margin-bottom: 80px;
            @include sp{
                margin-bottom: 30px;
            }
        }
        &__dl{
            display: flex;
            flex-wrap: wrap;
            padding: 60px 50px 45px;
            border-radius: 5px;
            box-shadow: 0 0 20px rgba(#000,0.1);
            background-color: #fff;
            margin-top: 50px;
            @include sp{
                padding: 25px 0;
                margin-top: 15px;
            }
            dt{
                width: calc(100% - 465px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 40px;
                margin-bottom: 40px;
                border-bottom: 1px dashed $text;
                @media(min-width: 768px){
                    &:nth-last-child(-n+2){
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }
                @include sp{
                    width: 100%;
                    margin-bottom: 15px;
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }   
            dd{
                width: 465px;
                padding-bottom: 40px;
                margin-bottom: 40px;
                border-bottom: 1px dashed $text;
                &:last-child{
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: 0;
                }
                @include sp{
                    width: 100%;
                    text-align: center;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px dashed #C9C9C9;
                    &:last-child{
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
                }
                img{
                    max-width: 100%;
                    @include sp{
                        max-width: 238px;
                    }
                }
            }
            &__point{
                font-size: 1.8rem;
                font-weight: 700;
                display: table;
                letter-spacing: 0.05rem;
                text-align: center;
                color: $text;
                background: url('images/reason/bg_text.png') center no-repeat;
                background-size: contain;
                line-height: 1;
                width: 75px;
                margin: 0 auto;
                margin-bottom: 25px;
                @include sp{
                    font-size: 0.9rem;
                    margin-bottom: 15px;
                }
                span{
                    display: block;
                    font-size: 4rem;
                    text-align: center;
                    line-height: 1;
                    padding-bottom: 7px;
                    @include sp{
                        font-size: 2rem;
                    }
                }
            }
            &__text{
                font-size: 2.6rem;
                font-weight: 700;
                text-align: center;
                color: $text   ;
                line-height: 42px;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 26px;
                }
            }
        }
        &__nav{
            padding: 40px 38px 45px;
            background-color: #F5EFDF;
            border-radius: 5px;
            @include bor(2px,#DFBB5B);
            display: flex;
            margin-bottom: 80px;
            @include sp{
                flex-direction: column;
                padding: 20px;
                @include bor(1px,#DFBB5B);
                margin-bottom: 30px;
            }
            li{
                width: 50%;
                @include sp{
                    width: 100%;
                }
                
            }
            &__top{
                display: table;
                border-radius: 22px;
                background-color: #EC871D;
                font-size: 2.6rem;
                color: #fff;
                letter-spacing: 1px;
                font-weight: 700;
                width: 368px;
                line-height: 45px;
                text-align: center;
                @include sp{
                    font-size: 1.2rem;
                    width: 189px;
                    line-height: 23px;
                    margin: 0 auto;
                }
            }
            &__tt{
                font-size: 3.6rem;
                line-height: 60px;
                color: $text;
                font-weight: normal;
                margin-bottom: 15px;
                margin-top: 15px;
                @include sp{
                    font-size: 1.8rem;
                    line-height: 30px;
                    text-align: center;
                    margin: 8px 0;
                }
                span{
                    font-weight: 700;
                    background-color: #FFEAB2;
                    color:#FF0707 ;
                }
            }
            &__text{
                font-size: 1.8rem;
                line-height: 32px;
                letter-spacing: 1px;
                font-weight: 700;
                color: $text;
                @include sp{
                    font-size: 1.2rem;
                    line-height: 18px;
                    text-align: center;
                }
            }
            &__img{
                margin-bottom: 10px;
                @include sp{
                    margin-top: 10px;
                }
                img{
                    width: 100%;
                }
            }
        }
        .lst_reason{
            margin-top: 60px;
            margin-bottom: 40px;
            @include sp{
                margin-bottom: 20px;
                margin-top: 15px;
            }
        }
        .btn_01{
            max-width: 495px;
            line-height: 85px;
            font-size: 2.4rem;
            @include sp{
                font-size: 1.6rem;
                line-height: 54px;
                max-width:260px ;
            }
        }
        .c-tt07{
            @include sp{
                font-size: 2rem;
            }
        }
        .btn_02{
            @include sp{
                max-width: 310px;
            }
        }
    }
    .r_sec03{
        padding-top: 80px;
        padding-bottom: 80px;
        @include sp{
            padding: 25px 0;
        }
        .nav_02{
            margin-bottom: 30px;
            @include sp{
                margin-bottom: 0;
            }
        }
    }
    .r_sec04{
        padding-top: 100px;
        background: url(images/reason/bg.png) center;
        background-repeat: repeat;
        background-size: contain;
        padding-bottom: 100px;
        @include sp{
            padding-top: 30px;
            padding-bottom: 20px;
        }
        &__top{
            text-align: center;
            img{
                max-width: 100%;
            }
        }
        &__tt{
            font-size: 2.8rem;
            line-height: 46px;
            font-weight: 700;
            text-align: center;
            letter-spacing: 0.05rem;
            margin-top: 55px;
            margin-bottom: 55px;
            font-family: $yu;
            @include sp{
                font-size: 1.8rem;
                line-height: 26px;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            span{
                color: #FF3B00;
                background-color: #FFEFB8;
            }
        }
    }
    .r_sec05{
        padding-top: 80px;
        padding-bottom: 0;
        padding-bottom: 100px;
        @include sp{
            padding-top: 35px;
            padding-bottom: 30px;
        }
        .c-tt01 img{
            margin-right: 15px;
            @include sp{
                width: 33px;
                position: absolute;
                left:-40px;
                top: 0px;
            }
        }
        .c-tt01{
            margin-bottom: 110px;
            @include sp{
                margin-bottom: 50px;
            }
        }
        .s_sec03__nav{
            margin-bottom: 60px;
            @include sp{
                margin-bottom: 20px;
            }
        }
        
    }
    .sec08.bg{
        padding-top: 85px;
        @include sp{
            padding-top: 30px;
        }
    }
}
.lst_reason{
    padding-bottom: 30px;
    border-bottom: 1px solid #B8B8B8;
    @include sp{
        padding-bottom: 18px;
        border-bottom: 0;
    }
    &__top{
        font-size: 2.4rem;
        color: $text;
        line-height: 38px;
        font-weight: 700;
        @include sp{
            font-size: 1.8rem;
            line-height: 26px;
            padding-left: 95px;
            position: relative;
        }
        span{
            color: #fff;
            font-size: 2rem;
            width: 124px;
            line-height: 36px;
            margin-right: 15px;
            background-color: #CFA944;
            display: inline-block;
            text-align: center;
            @include sp{
                font-size: 1.4rem;
                width: 86px;
                line-height: 18px;
                padding: 4px 9px;
                margin-right: 9px;
                font-weight: normal;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    &__tt{
        font-size: 2rem;
        line-height: 38px;
        color: $text;
        margin-top: 20px;
        margin-bottom: 10px;
        @include sp{
            margin-top: 8px;
            font-size: 1.4rem;
            line-height: 19px;
            margin-bottom: 7px;
        }
    }
    &__img{
        img{
            max-width: 100%;
        }
    }
    &__content{
        margin-top: 20px;
        dl{
            margin-bottom: 4px;
            display: flex;

            dt{
                font-size: 2rem;
                color: $text;
                font-weight: 700;
                line-height: 38px;
                padding-left: 30px;
                background: url('images/reason/icon_checkbox.png') no-repeat center;
                background-size: 24px 24px;
                background-position: left center;
                width: 170px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 19px;
                    background-size: 16px 16px;
                    width: 140px;
                    padding-left: 22px;
                }
            }
            dd{
                font-size: 2rem;
                color: $text;
                font-weight: 700;
                line-height: 38px;
                @include sp{
                    font-size: 1.6rem;
                    line-height: 19px;
                }
                &.color_01{
                    color: #CFA944;
                    @include sp{
                        font-size: 1.4rem;
                    }
                    span{
                        font-size: 3rem;
                        @include sp{
                            font-size: 1.8rem;
                        }
                    }
                }
                &.color_02{
                    color: #FF081F;
                    padding-left: 19px;
                    @include sp{
                        font-size: 1.4rem;
                        padding-left: 10px;
                    }
                    span{
                        font-size: 3rem;
                        @include sp{
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}
.list_re_dl{
    margin-bottom: 20px;
    @include sp{
        margin-bottom: 10px;
    }
    dt{
        font-size: 2.8rem;
        color: #fff;
        font-weight: 700;
        letter-spacing: 0.05rem;
        line-height: 36px;
        padding: 20px 35px;
        background-color: #138576;
        @include sp{
            font-size: 1.8rem;
            line-height: 23px;
            padding: 7px 15px;
        }
    }
    dd{
        @include bor(5px,#138576);
        padding: 40px 35px 30px;
        background-color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        @include sp{
            padding: 10px 15px;
            @include bor(2px,#138576)
        }
        h4{
            font-size: 2.6rem;
            line-height: 34px;
            color:#138576 ;
            font-weight: 700;
            margin-bottom: 10px;
            @include sp{
                font-size: 1.8rem;
                line-height: 21px;
                margin-bottom: 5px;
            }
        }
        p{
            font-size: 1.8rem;
            line-height: 32px;
            color: $text;
            letter-spacing: 0.05rem;
            @include sp{
                font-size: 1.6rem;
                line-height:  28px;
            }
        }
    }
}
