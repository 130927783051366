// 基本色を定義
$brand-color-blue: #2fa1bf;
$brand-color-light-blue: #dceff5;
$brand-color-green: #2fc1a5;
$brand-color-light-green: #e6f7f3;
$brand-color-yellow-green: #90ad1d;
$brand-color-light-yellow-green: #f0f7da;
$brand-color-orange: #ff6c01;
$brand-color-light-orange: #fe8d3b;
$brand-color-beige: #fff9f0;
$brand-color-purple: #5d74b7;
$brand-color-light-purple: #eaeefc;
$brand-color-ocher: #cfa944;
$brand-color-light-ocher: #fbf1b5;
$brand-color-light-gray: #F4F4F4;
$brand-color-black: #383838;

// カラーマップの定義
$colors: (
  blue: $brand-color-blue,
  light-blue: $brand-color-light-blue,
  green: $brand-color-green,
  light-green: $brand-color-light-green,
  yellow-green: $brand-color-yellow-green,
  light-yellow-green: $brand-color-light-yellow-green,
  orange: $brand-color-orange,
  light-orange: $brand-color-light-orange,
  beige: $brand-color-beige,
  purple: $brand-color-purple,
  light-purple: $brand-color-light-purple,
  light-gray: $brand-color-light-gray,
  black: $brand-color-black
);

// ループを使って背景色クラスを自動生成
@each $name, $color in $colors {
  .bg_#{$name} {
    background-color: $color;
  }
}
