// 作業実績　単体ページ
// ページ上部〜中部（ご依頼情報の概略部）
.e_sec01 {
    padding: 40px 100px;
    @include sp {
        padding: 20px 0px;
    }
    &__title {
        margin-bottom: 100px;
        @include sp {
            margin-bottom: 45px;
        }
        &--top {
            display: inline-block;
            padding: 10px 24px;
            font-size: 28px;
            background-color: #F5EFDF;
            @include sp {
                padding: 0;
                font-size: 16px;
                background: none;
            }
        }
        &--bottom {
            margin-top: 40px;
            line-height: 44px;
            font-size: 34px;
            @include sp {
                margin-top: 18px;
                line-height: 24px;
                font-size: 18px;
            }
        }
    }
    // 実績の項目
    &__label {
        text-align: center;
        line-height: 34px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        background-color: #CFA944;
    }
    // 各項目の値
    &__value {
        font-size: 18px;
        font-weight: bold;
    }
    &__label,
    &__value {
        @include sp {
            font-size: 16px;
        }
    }
    // ご依頼エリア、作業内容、建物のセクション
    &__table {
        margin-bottom: 80px;
        border: 1px solid #CFA944;
        @include sp {
            margin-top: 45px;
            margin-bottom: 35px;
            border: 1px solid #F5EFDF;;
        }
        table {
            // table内に発生する余白を削除
            border-collapse: collapse;
            border-spacing: 0;
        }
        // pc版のtableデザイン
        &--pc {
            width: 100%;
            th, td {
                text-align: center;
                width:calc(100% / 3);
            }
            th:not(:last-child) {
                border-right: 1px solid #fff;
            }
            td:not(:last-child) {
                padding: 15px;
                border-right: 1px solid #CFA944;
            }
        }
        // sp版のtableデザイン
        &--sp {
            width: 100%;
            th {
                width: 40%;
            }
            th,
            td {
                padding-left: 16px;
                text-align-last: left;
            }
            tr:not(:last-child) {
                th {
                    border-bottom: 1px solid #fff;
                }
                td {
                    border-bottom: 1px solid #F5EFDF;
                }
            }
        }
    }
    // 作業人数、作業時間、トラック台数のセクション
    &__lists {
        margin-bottom: 50px;
        ul {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include sp {
                display: block;
            }
            li {
                text-align: center;
                width: 28%;
                // スマホ版のトラックのデザイン
                @include sp {
                    margin: 0 auto;
                    width: 50%;
                }
                .e_sec01__value {
                    padding-bottom: 8px;
                    margin-bottom: 10px;
                    border-bottom: 2px solid #efefef;
                }
            }
            figure {
                padding: 20px;
                @include sp {
                    padding: 10px;
                }
                img {
                    width: 135px;
                    height: 135px;
                    border-radius: 50%;
                    // スマホ版のトラックのデザイン
                    @include sp {
                        width: 120px;
                        height: 120px;
                    }
                }
            }
        }
        // スマホ版の作業人数と作業時間、トラック台数の配置
        .member_and_time {
            display: none;
            @include sp {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                li {
                    width: 45%;
                    figure {
                        padding: 10px;
                        img {
                            width: 120px;
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
    // 作業費用、買取費用、回収した品物、買取した品物のセクション
    &__box {
        // 買取がなかった場合
        .has_no_purchase {
            border: 1px solid #CFA944;
            @include sp {
                border: none;
            }
            .box {
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include sp {
                    padding: 10px;
                    display: block;
                }
                &__cost {
                    width: 100%;
                    font-size: 28px;
                    font-weight: bold;
                    border-right: 1px solid #CFA944;
                    @include sp {
                        text-align: center;
                        font-size: 18px;
                        border: none;
                    }
                    .final_bill {
                        text-align: center;
                        span {
                            margin-right: 10px;
                            font-size: 55px;
                            color: #FF2727;
                            @include sp {
                                margin-right: 5px;
                                font-size: 30px;
                            }
                        }
                    }
                }
                &__items {
                    padding: 10px 0px 10px 40px;
                    width: 100%;
                    @include sp {
                        padding: 30px 20px 20px;
                    }
                    &-title {
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #CFA944;
                        font-size: 18px;
                        color: #CFA944;
                        @include sp {
                            font-size: 16px;
                        }
                    }
                    &-description {
                        font-size: 18px;
                        @include sp {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
        // 買取があった場合
        .has_purchase {
            // 作業費用や買取費用のセクション
            .price_box {
                display: flex;
                justify-content: space-between;
                border: none;
                text-align: center;
                // 作業費用と買取金額
                .operation_cost,
                .purchase_price {
                    width: 25%;
                    .e_sec01__value {
                        font-size: 16px;
                        span {
                            font-size: 34px;
                        }
                    }
                }
                // ご請求金額（お渡し金額）
                .final_bill {
                    width: 40%;
                    .e_sec01__value {
                        font-size: 20px;
                        span {
                            font-size: 50px;
                            color: #FF2727;
                        }
                    }
                }
                .operation_cost,
                .purchase_price,
                .final_bill {
                    .e_sec01__label {
                       margin-bottom: 40px;
                   }
                }
                .operation_cost {
                    span {
                        color: #CFA944;
                    }
                }
                .purchase_price {
                    position: relative;
                    .e_sec01__value {
                        span {
                            color: #FF2727;
                        }
                    }
                }
                // 作業費用と買取金額の間のマイナス
                .purchase_price::before {
                    content: '';
                    display: block;
                    width: 2rem;
                    position: absolute;
                    bottom: 2.8rem;
                    left: -3.7rem;
                    border-top: 3px solid #000;
                }
                // 買取金額と請求金額の間の矢印
                .final_bill {
                    position: relative;
                }
                .final_bill::before {
                    content: '';
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    bottom: 0;
                    left: -5.0rem;
                    background: url('images/experience/right_arrow_for_final_bill.png') no-repeat center;
                    background-size: contain;
                }
            }
            // 回収や買取したお品物のセクション
            .item_box {
                margin-top: 80px;
                padding: 0 20px;
                &__lists {
                    display: flex;
                    justify-content: space-between;
                }
                &__list {
                    width: 43%;
                }
                &__title {
                    padding-bottom: 10px;
                    margin-bottom: 4px;
                    border-bottom: 1px solid #CFA944;
                    font-size: 18px;
                    color: #CFA944;
                }
                &__description {
                    font-size: 18px;
                }
            }
            // sp用デザイン
            @include sp {
                // 作業費用や買取費用のセクション
                .price_box {
                    display: block;
                    .operation_cost_and_purchase_price {
                        margin-bottom: 60px;
                        display: flex;
                        justify-content: space-between;
                        li {
                            width: 44%;
                            position: relative;
                            .e_sec01__value {
                                margin-top: 10px;
                                text-align-last: left;
                                font-size: 12px;
                                span {
                                    margin-right: 6px;
                                    font-size: 21px;
                                }
                            }
                            &:first-child {
                                .e_sec01__value {
                                    span {
                                        color: #CFA944;
                                    }
                                }
                            }
                            &:last-child {
                                .e_sec01__value {
                                    span {
                                        color: #FF2727;
                                    }
                                }
                            }
                            // 作業費用と買取金額の間のマイナス
                            &:last-child::before {
                                content: '';
                                display: block;
                                width: 2rem;
                                position: absolute;
                                top: 18px;
                                left: -20%;
                                border-top: 3px solid #CFA944;
                            }
                        }
                    }
                    .final_bill {
                        width: 100%;
                        .e_sec01__label {
                            margin-bottom: 10px;
                        }
                        .e_sec01__value {
                            font-size: 20px;
                            span {
                                margin-right: 8px;
                                font-size: 30px;
                            }
                        }
                        &::before {
                            content: '';
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            bottom: 88px;
                            left: 44%;
                            background: url('images/experience/down_arrow_for_final_bill.png') no-repeat center;
                            background-size: contain;
                        }
                    }
                }
                .item_box {
                    margin-top: 30px;
                    &__lists {
                        display: block;
                    }
                    &__list {
                        margin-bottom: 40px;
                        width: 100%;
                    }
                    &__title,
                    &__description {
                        font-size: 16px;
                    }
                    &__description {
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
// ページ下部（ご依頼情報の詳細部）
.e_sec02 {
    padding: 40px 100px;
    @include sp {
        padding: 12px 0px;
        background-color: #F5EFDF;
    }
    // 実績の項目
    &__label {
        padding-left: 15px;
        border-left: 2px solid #CFA944;
        font-size: 18px;
        @include sp {
            margin-bottom: 15px;
            padding-left: 0;
            padding-bottom: 12px;
            text-align: center;
            border-left: none;
            position: relative;
            &::after {
                content: '';
                display: block;
                width: 9.2rem;
                position: absolute;
                bottom: 0;
                left: 35%;
                border-bottom: 2px solid #CFA944;
            }
        }
    }
    // 各項目の値
    &__value {
        padding: 15px;
        line-height: 28px;
        font-size: 16px;
        @include sp {
            padding: 0;
        }
    }
    &__lists {
        background-color: #fff;
    }
    &__list {
        margin-bottom: 20px;
        @include sp {
            margin-bottom: 0;
            padding: 20px;
        }
    }
    // スタッフのセクション
    &__box {
        display: flex;
        @include sp {
            display: block;
            text-align: center;
        }
    }
    // スタッフの画像
    &__img {
        padding-top: 20px;
        @include sp {
            padding-top: 0;
        }
        figure {
            margin-bottom: 10px;
            img {
                width: 135px;
                height: 135px;
                max-width: inherit;
                border-radius: 50%;
                border: 1px solid #CFA944;
            }
        }
    }
    // スタッフのコメント
    &__text {
        padding: 20px 60px;
        @include sp {
            padding: 0;
        }
        .e_sec02__value {
            padding: 30px 40px;
            border-radius: 12px;
            background-color: #F5EFDF;
            @include sp {
                padding: 0;
                text-align-last: left;
                background-color: #fff;
            }
        }
    }
}
// ページネーションと作業実績一覧のリンク
.e_sec03 {
    padding: 40px 40px 80px;
    @include sp {
        padding: 40px 20px;
    }
    &__pagination {
        display: flex;
        justify-content: center;
        // 前のページと次のページ共通
        &--prev,
        &--next {
            position: relative;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            width: 155px;
            background-color: #F5EFDF;
            font-size: 16px;
        }
        // 前のページ
        &--prev {
            padding-left: 15px;
            margin-right: 15px;
        }
        // 次のページ
        &--next {
            padding-right: 15px;
            margin-left: 15px;
        }
        &--prev::before,
        &--next::before
        {
            content: '';
            width: 11px;
            height: 15px;
            background: url(images/common/icon_arrow04.png) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 50%;
        }
        &--prev::before {
            left: 10px;
            transform: scale(-1, 1) translateY(-50%);
        }
        &--next::before {
            right: 10px;
            transform: translateY(-50%);
        }
    }
    // 作業実績一覧リンク
    &__button {
        margin: 70px auto 0;
        max-width: 340px;
        font-size: 2rem;
        line-height: 50px;
        border-radius: 5px;
        @include sp {
            margin-top: 50px;
        }
    }
}

// 作業実績　一覧ページ
.e_archive {
    padding: 80px 100px;
    @include sp {
        padding: 60px 0;
    }
    .list_experience {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @include sp {
            justify-content: space-between;
        }
        li {
            margin-bottom: 20px;
            width: 100%;
            @include mq(lg) {
                width: 32%;
            }
            &:not(:nth-child(3n)) {
                @include mq(lg) {
                    margin-right: 17px;
                }
            }
        }
        // リスト内のコンテンツ
        &__content {
            padding: 20px;
            border: 1.5px solid #efefef;
            // カテゴリー、依頼者名、依頼内容部分
            &--top {
                border-bottom: 1px solid #efefef;
            }
            // 間取り、作業、回収量部分
            &--middle {
                padding: 20px 0px;
                border-bottom: 1px solid #efefef;
            }
            // 費用部分
            &--bottom {
                padding: 10px 0px;
            }
        }
        &__category {
            padding-top: 8px;
            padding-bottom: 8px;
            text-align: center;
            font-size: 1.8rem;
            font-weight: bold;
            color: #fff;
            background: #CFA944;
            border-radius: 5px;
            @include mq(lg) {
                font-size: 2.0rem;
            }
            // 生前整理
            &.bg_green {
                background-color: #688F0B;
            }
            // 特殊清掃
            &.bg_blue {
                background-color: #72B7EC;
            }
            // ゴミ屋敷清掃
            &.bg_black {
                background-color: #000000;
            }
        }
        &__name,
        &__request_reason {
            margin-top: 20px;
            font-size: 16px;
            @include sp {
                font-size: 14px;
            }
        }
        &__name {
            span {
                margin-right: 10px;
            }
        }
        &__request_reason {
            margin-bottom: 20px;
            color: #CFA944;
            font-weight: bold;
            line-height: 25px;
            @include sp {
                line-height: 20px;
            }
        }
        &__consultation {
            margin-bottom: 7px;
            line-height: 1.5;
            dt {
                text-align: center;
                font-weight: bold;
                font-size: 1.4rem;
                margin-bottom: 4px;
                &:hover {
                    cursor: pointer;
                }
            }
            dd {
                display: none;
                font-size: 1.3rem;
            }
        }
        &__building, 
        &__task {
            font-size: 1.3rem;
            margin-bottom: 16px;
            span {
                padding: 4px 12px;
                margin-right: 5px;
                border-radius: 8px;
                color: #fff;
                background: $brand-color-green;
            }
        }
        &__truck {
            display: flex;
            align-items: center;
            font-size: 1.3rem;
            &--left {
                padding: 4px 12px;
                margin-right: 5px;
                border-radius: 8px;
                color: #fff;
                background: $brand-color-green;
            }
            &--right {
                .trucknum {
                    line-height: 1.5;
                }
            }
        }
        &__cost {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 26px;
            @include mq(lg) {
                margin-top: 20px;
            }
            &--label {
                font-size: 1.6rem;
                width: 28%;
                transform: translateX(-6px) scale(0.8);
                span {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
            }
            &--value {
                font-size: 2.2rem;
                font-weight: bold;
                span {
                    margin-left: 2px;
                    font-size: 1.2rem;
                    font-weight: normal;
                    display: inline-block;
                }
            }
            // 買取金額部分
            &:nth-child(3) {
                .list_experience__cost--label {
                    color: #FF2727;
                }
            }
        }
        // 詳しく見るボタン
        &__button {
            margin: 20px auto 0;
            max-width: 340px;
            font-size: 1.6rem;
            line-height: 44px;
            border-radius: 5px;
            box-shadow: 0 3px 0 #034135;
            @include sp {
                margin-top: 20px;
                line-height: 32px;
                font-size: 13px;
                box-shadow: 0 2px 0 #034135;
            }
        }
    }
}
