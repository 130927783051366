/* misc - utility
--------------------------------------------------------- */
.u_wsNowrap {
    white-space: nowrap;
}

.u_txInside {
    margin-left: 1em;
    text-indent: -1em;
}

.u_curPointer {
    cursor: pointer;
}

.u_opHover {
    transition: all 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
        opacity: 0.7;
    }
}
